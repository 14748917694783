import React, { memo, useState, useEffect } from "react";

import hiImg from "assets/images/images_new/hi-icon.png";
// import calendarImg from "assets/images/images_new/calendar-icon.svg";
import BookNowModal from "components/Dashboard/New/BookNowModal";

const NotificationArea = memo(function NotificationArea({
	loggedInUser: {
		name, email, active_subscription
	}
}) {

	const [bookNow, setBookNow] = useState(false);
	const [freeUser, setFreeUser] = useState(true);

	const toggle = () => {
		setBookNow(prevState => !prevState);
	}

	const salutation = () => {
		const today = new Date();
		const currentHours = today.getHours();

		let salute = "";
		if (currentHours < 12) {
			salute = "Good Morning";
		} else if (currentHours < 18) {
			salute = "Good afternoon";
		} else {
			salute = "Good Evening";
		}

		return salute;
	}

	const buttonVisibility = () => {
		return freeUser ? "visible" : "hidden";
	}
	
	useEffect(() => {
		if (active_subscription && active_subscription.length > 0) {
			setFreeUser(false);
		}
	}, [active_subscription]);

	return (
		<div className="col-md-5 pr-md-0">
			<div className="booknow-card">
				<h2>
					<img src={hiImg} alt="Hello" /> {salutation()}, {name}
				</h2>
				<div className="suggestion-text">
					<span>Suggestion</span>
					More than 107 students got their desired score with LA in <br className="d-none d-md-block" />
					the last month. Book your FREE Trial Class NOW.
				</div>			
				<a
					href="javascript:void(0)"
					className="booknow-btn"
					onClick={toggle}
					style={{
						visibility: buttonVisibility()
					}}
				>
					{/*<img src={calendarImg} alt="Book Now" /> Book Now*/}
				<i className="fa fa-calendar-o" aria-hidden="true"></i> Book Now
				</a>
			</div>

			{freeUser && bookNow &&
				<BookNowModal
					isOpen={bookNow}
					toggle={toggle}
				/>
			}
		</div>
	);
});
export default NotificationArea;