import React,{Component} from "react";

// reactstrap components
import {
  Col,
  ListGroupItem,
  ListGroup
} from "reactstrap";
import "components/custom.css";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import Board from '@lourenci/react-kanban'

const SortableItem = SortableElement(({value}) =>
 
    <ListGroupItem>{value}</ListGroupItem>

);

const SortableList = SortableContainer(({items}) => {
  return (
    <ListGroup>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value.options} />
      ))}
    </ListGroup>
  );
});
class Reorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      question:"",
      secondCount:1200,
      questionId:"",
      reset:false,
      is_answer:false,
      items:[],
      sortedItems:[],
      board:{},
      arr:[],
      resultObj:{},
      correctFlag:true
    }
  }  

  componentDidMount() {
  }

  showAnswer = () => {

    if (this.props.answer != this.state.is_answer) {
      if (!this.props.resultMode) {
        if(document.querySelector('div[data-rbd-draggable-id="lane-draggable-1"] .sc-Axmtr')) {
          document.querySelector('div[data-rbd-draggable-id="lane-draggable-1"] .sc-Axmtr').innerHTML ='<span>Correct Order</span>';  
        } else {
          document.querySelector('div[data-rbd-draggable-id="lane-draggable-1"] .sc-kPTPQs').innerHTML = '<span>Correct Order</span>';
        }
      }
      
      this.setState({is_answer:true},()=>{
        if (!this.props.resultMode) {
        let elemArr = document.querySelectorAll('.list-group-item1');
        for (var i = 0; i < elemArr.length; i++) {
          elemArr[i].classList.add('cross');
        }
        if (this.state.resultObj.answer) {
          let correct = this.state.resultObj.correct;
          let answer = this.state.resultObj.answer;
          for (var i = 0; i < answer.length-1; i++) {
            for (var j = 0; j < correct.length-1; j++) {
              if (correct.length > i+1) {
                if (answer[i]+'-'+answer[i+1] == correct[j]+'-'+correct[j+1] ) {
                  elemArr[i].classList.remove('cross');
                }
              }
            }
          }
        }
    }
      });

    }
  }


  static getDerivedStateFromProps(props, state) {
    let temp =[];
    if (props.data) {
      if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
        clearInterval(state.intervalId);
        clearInterval(state.recordId);
        temp = props.data.option.slice();
        let temp1 = props.data.option.sort(function(a, b){return b.id-a.id});
        let board = {
                      lanes: [
                        {
                          id: 1,
                          title: 'Source',
                          cards: temp1.map((item) => { return {id:item.id,title:'',description:item.options} }),
                        },
                        {
                          id: 2,
                          title: 'Target',
                          cards: [
                          ]
                        }
                      ]
                    };
                  
        if (document.querySelectorAll('div[data-rbd-draggable-id="lane-draggable-1"] .sc-Axmtr').length > 0) {
          document.querySelector('div[data-rbd-draggable-id="lane-draggable-1"] .sc-Axmtr').innerHTML ='<span>Source</span>';  
        }
        
        return {correctFlag:true,secondCount:1200,questionId: props.data.id,reset:true,items:props.data.option,board:board,sortedItems:temp.sort(function(a, b){return a.index - b.index})};
      }else{
        if (!state.questionId) {
          return {correctFlag:true,questionId: props.data.id};
        }
      }
      }    
  }

  timer = () => {
    if (this.state.secondCount === 1) {
      clearInterval(this.state.intervalId);
    }
    this.setState({ secondCount: this.state.secondCount -1 });
  }

  componentWillUnmount = () => {
    clearInterval(this.state.intervalId);
  }
  
  magicFunction = (arr, old_index, new_index) => { 

    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;

  }

  cardDragEnd = (card,source,destination) => {
    if (this.state.is_answer) {
      return false;
    }
    this.props.showResult();
    let board = {};
    let temp = this.state.board;
    if (source.fromLaneId !== destination.toLaneId) {
      temp.lanes[--source.fromLaneId].cards.splice(source.fromPosition, 1);
      temp.lanes[--destination.toLaneId].cards.splice(destination.toPosition, 0, card);
         board ={
                lanes: [
                  {
                    id: 1,
                    title: 'Source',
                    cards: temp.lanes[0].cards,
                  },
                  {
                    id: 2,
                    title: 'Target',
                    cards: temp.lanes[1].cards
                  }
                ]
              };
    }else{
      temp.lanes[(source.fromLaneId-1)].cards = this.magicFunction(temp.lanes[(source.fromLaneId-1)].cards,source.fromPosition, destination.toPosition);
         board ={
                lanes: [
                  {
                    id: 1,
                    title: 'Source',
                    cards: temp.lanes[0].cards,
                  },
                  {
                    id: 2,
                    title: 'Target',
                    cards: temp.lanes[1].cards
                  }
                ]
              };
    }
    
    this.props.submit({'answer':board.lanes[1].cards.map((val)=>{ return val.id;}),'correct':this.state.sortedItems.map((val)=>{ return val.id;})},true);
    this.setState({board:board,resultObj:{'answer':board.lanes[1].cards.map((val)=>{ return val.id;}),'correct':this.state.sortedItems.map((val)=>{ return val.id;})}});
  };
  updateCorrect = () =>{
    if (this.state.sortedItems.length !==0) {
      this.props.submit({'answer':[],'correct':this.state.sortedItems.sort(function(a, b){return a.index - b.index}).map((val)=>{ return val.id;})});
      this.setState({correctFlag:false,resultObj:{'answer':[],'correct':this.state.sortedItems.sort(function(a, b){return a.index - b.index}).map((val)=>{ return val.id;})}})  
    }
    
  } 

  stopTest = () =>{
    this.props.nextQuestion(this.state.resultObj);
  }

  render() {
    
    if (this.props.restart) {
      this.props.restartQue();
    }

    if (this.props.stopRecording) {
      this.stopTest();      
    }

    let board ={
                lanes: [
                  {
                    id: 1,
                    title: 'Source',
                    cards: [],
                  },
                  {
                    id: 2,
                    title: 'Target',
                    cards: [
                    ]
                  }
                ]
              };
    let question ="";
    let temp =[];
    if (this.props.data) {
      if (this.state.correctFlag) {
        this.updateCorrect();  
      }
      question = this.props.data.question;
      board = {
                lanes: [
                  {
                    id: 1,
                    title: 'Source',
                    cards: this.props.data.option.map((item) => { return {id:item.id,title:'',description:item.options} }),
                  },
                  {
                    id: 2,
                    title: 'Target',
                    cards: [
                    ]
                  }
                ]
              };
        if (JSON.stringify(this.state.board) === '{}' ) {
            this.setState({board:board});  
        }else{
          board = this.state.board;
        }
      if (this.state.items.length === 0) {
        temp = this.props.data.option.slice();
        this.setState({items:this.props.data.option,sortedItems:temp.sort(function(a, b){return a.index - b.index})});
      }
      
    }
    if (this.state.reset) {
      this.setState({reset:false,is_answer:false});
    }

    if (this.props.answer) {
      let temp = this.props.data.option;
      let lane0 = [...temp.sort(function(a, b){return a.index - b.index}).map((item)=>{ return {id:item.id,title:'',description:item.options};})];
      board.lanes[0] = {
                    id: 1,
                    title: 'Correct Order',
                    cards: lane0,
                  } 
      this.showAnswer();
    }
    return (
      <>
        <Col xs={12}>
          <h4 className="infoText"></h4>
        </Col>
        <Col xs={12}>
          <h5>{question}</h5>
        </Col>
        <Col xs={12}>
          <h3 className="question">
            {this.props.data && this.props.data.question_mcq}
          </h3>
        </Col>
        <Col lg={12}>&nbsp;</Col>
        <Col lg={12}>
          {this.props.data && !this.props.resultMode && (
            <div className="reorder-board">
            <Board disableLaneDrag onCardDragEnd={this.cardDragEnd}>
              {board}
            </Board>
            </div>
          )}
        </Col>
        <Col lg={12}>&nbsp;</Col>
        <Col lg={12}>
          {this.state.is_answer && !this.state.resultMode && <h3>Result: </h3>}
          {this.state.is_answer && (
            <div className="col-lg-6">
              <ul className="connectList">
                {this.state.board.lanes &&
                  this.state.board.lanes[1].cards.map((v, k) => {
                    return <li className="list-group-item1">{v.description}</li>;
                  })}
              </ul>
            </div>
          )}
          {this.props.resultMode && (
            <SortableList items={this.state.sortedItems} />
          )}
        </Col>
      </>
    );
  }
}
export default Reorder;