import React, { useState, useEffect, memo } from "react";
import API from "api.js";
import { store } from "store/index";
import { useDispatch } from "react-redux";
import { setUserType } from "action";
import { Link } from "react-router-dom";
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import NotificationArea from "components/Dashboard/New/NotificationArea";

// import CarRegistrationChart from "../components/Dashboard/DashboardChart";

import StatCategories from "components/Dashboard/New/StatCategories";
import Stats from "components/Dashboard/New/Stats";
import TuteVideos from "components/Dashboard/TuteVideos";
import EstimatedExamScore from "components/Dashboard/New/EstimatedExamScore";
import UserSubscriptions from "components/Dashboard/UserSubscriptions.jsx";
import UpcomingClasses from "components/Dashboard/UpcomingClasses.jsx";
import TimezoneForm from "components/Dashboard/TimezoneForm";
import Modal from "components/PopUps/Modal";

const Dashboard = memo(function Dashboard(props) {
  const [loading, setLoading] = useState(true);
  const [practiceData, setPracticeData] = useState(null);
  const [mockData, setMockData] = useState(null);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [portalVideos, setPortalVideos] = useState(null);
  const [showAverageScore, setShowAverageScore] = useState(false);
  const [estimatedScore, setEstimatedScore] = useState(0);
  const [subscription, setSubscription] = useState([]);
  const [classes, setClasses] = useState([]);
  const [isTimezonePopup, setIsTimezonePopup] = useState(false);

  const dispatch = useDispatch();

  const loadDashboardData = () => {
    API({
      method: 'GET',
      url: 'dashboard_data'
    }).then(res => {
      const { data, user, isClassified, branches, userType } = res.data;

      const { 
        show_average_score, 
        estimated_score, 
        practice_data, 
        mock_data, 
        videos, 
        classes, 
        subscription,
        popup_video
      } = data;

      setPracticeData(practice_data);
      setMockData(mock_data);
      setPortalVideos(videos);
      setShowAverageScore(show_average_score);
      setEstimatedScore(estimated_score);
      setSubscription(subscription);
      setClasses(classes);

      const isTimezoneSaved = !user.user_timezone ? true : false;
      setIsTimezonePopup(isTimezoneSaved);
    }).catch(err => console.log(err));
  }

  useEffect(() => {

    const { user: { first_name, last_name, email, active_subscription } } = store.getState();
    
    let name = first_name ? first_name : email;
    setLoggedInUser({
      name, email, active_subscription
    });

    loadDashboardData();
  }, []);

  useEffect(() => {
    // Mock loading data from an API
    setTimeout(() => setLoading(false), 300);

    // Dispatching user type for example purposes
    dispatch(setUserType("free"));

    // Clean up body class on unmount
    return () => {
      document.body.classList.remove("dashboardPage");
    };
  }, [dispatch]);


  return (
    <>
      {!loading && (
        <div className="siteContent p-0">
          <DashboardHeader title="Dashboard" />
          <div className="dashboard-wrapper">
            <div className="row">
              <NotificationArea
                loggedInUser={loggedInUser}
              />
              <StatCategories
                practiceData={practiceData}
                showAverageScore={parseInt(showAverageScore)}
              />
            </div>
            <div className="row">
              <Stats
                mockData={mockData}
                practiceData={practiceData}
              />
              <EstimatedExamScore
                estimatedScore={estimatedScore}
              />
              {/* <div className="col-md-6">
                <div className="dashboard-card section-2">
                  <div className="plan-classes-section">
                    <UserSubscriptions subscription={subscription} />

                    {subscription.length > 0 &&
                      <UpcomingClasses classes={classes} />
                    }
                  </div>
                </div>
              </div> */}
            </div>
            <div className="dashboard-bottom-row">
              <div className="row">
                <div className="col-xl-6 pr-xl-0">
                  <UpcomingClasses
                    classes={classes}
                    showInPopup={false}
                  />
                </div>                
                
                <div className="col-xl-6">
                  <TuteVideos
                    videos={portalVideos}
                  />
                </div>                
              </div>
            </div>
          </div>

          <Modal 
            status={isTimezonePopup} 
            toggle={() => setIsTimezonePopup(false)} 
            showCloseBtn={false}
            render={(toggle) => <TimezoneForm toggle={toggle} />}
          />
        </div>
      )}
    </>
  );
});
export default Dashboard;