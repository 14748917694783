import React from 'react';
import SearchIcon from "assets/images/images_new/zoom-lens.png";

export default function SearchFilter({
	search, handleSearchChange, clickSearch
}) {

	return (
		<div className="searchkeywords searchkeywords-wrapper">
			<input
				className="searchkeywordsinput"
				type="text"
				name="search"
				placeholder="Search keywords"
				autoComplete="off"
				value={search}
				onChange={handleSearchChange}
			/> 
			<button className="searchkeywords-btn">
				<img
					src={SearchIcon}
					alt="Search Image"
					onClick={clickSearch}
				/>
			</button>
		</div>
	);
}