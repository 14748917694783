import React,{Component} from "react";

// reactstrap components
import {
  Col,
  Progress,
  Label,
  Button,
  Input
} from "reactstrap";
import { Radio } from '@material-ui/core';
import Sound from 'react-sound';
// core components
import "components/custom.css";
import {base,s3base} from "api.js";
import PlaybackSpeedBtns from "components/PracticeMaterial/PlaybackSpeedBtns";

class CorrectSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount:10,
      recordCount:0,
      recordId:1,
      intervalId:0,
      questionId:"",
      reset:false,
      soundStatus:true,
      selectedValue:'',
      soundPer:0,
      volume:90,
      correctFlag:true,
      resultObj:{},
      playbackSpeed: 1,
      soundKey: 0,
    };
  }
  
  componentDidMount() {
    var intervalId = setInterval(this.timer, 1000);
    this.setState({
      intervalId
    }, () => {
      this.props.updateQuestionAudioUrlInPractice(`${s3base}${this.props.data.media_link}`);
    });
  }

  stopTest = () =>{
    this.props.nextQuestion(this.state.resultObj);
  }

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart ) {
  			clearInterval(state.intervalId);
  			clearInterval(state.recordId);
  			//var intervalId = setInterval(ReadAloud.timer1, 1000);
  			
  			return {correctFlag:true,recordCount:0,soundPer:0,isRecording:false,secondCount:10,questionId: props.data.id,blobURL:'',reset:true,soundStatus:true};
  		}else{
  			if (!state.questionId) {
  				return {correctFlag:true,questionId: props.data.id};
  			}
  		}
  		
  		}
  }
  

	timer = () => {
		if (this.state.secondCount === 1) {
			clearInterval(this.state.intervalId);
		}
		this.setState({ secondCount: this.state.secondCount -1 });
	}

  handleSongFinishedPlaying = () => {
    this.setState({'soundStatus':false});
    this.props.soundChecked();
    this.props.showResult();
  } 

  changeRange = (e) => {
      this.setState({volume: e.target.value});
  }

  handleSongPlaying = (obj) => {
    this.setState({soundPer:obj.position*100/obj.duration});
  }

	timerRecord = () => {
		if (this.state.recordCount === 40) {
			clearInterval(this.state.recordId);
			this.stop();
		}
		this.setState({ recordCount: this.state.recordCount + 1 });	
	}

  handleChange = (event) => {
    let correct = this.props.data.option.filter(function (item) {
          return item.correct == 1;
        })[0].id;
    this.props.submit({'answer':event.target.value,'correct':correct},true);
    this.setState({selectedValue: event.target.value, resultObj: {'answer':event.target.value,'correct':correct} });
  };

  updateCorrect = () =>{
    let correct = this.props.data.option.filter(function (item) {
          return item.correct == 1;
        })[0].id;
    this.props.submit({'answer':'','correct':correct});
    this.setState({correctFlag:false,resultObj:{'answer':'','correct':correct}});
  } 
	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}
  skip = () => {
    clearInterval(this.state.intervalId);
    this.setState({secondCount:0});
  };

  changePlaybackSpeed = (speed) => {
    this.setState((prevState) => ({
      soundKey: prevState.soundKey + 1
    }));
    this.setState({playbackSpeed: speed});
  }

  playingQuestionSectionLabel = () => {
    const { isRecording, secondCount, soundStatus, blobURL } = this.state;
    let status = '';

    if (!isRecording) {
      if (secondCount !== 0 && soundStatus === true) {
        status = `Beginning in ${this.state.secondCount} seconds`;
      } else if (secondCount === 0 && soundStatus) {
        status = 'Playing question audio...';
      } else {
        status = 'Question audio finished.'; // just any case.
      }
    } else {
      status = 'Question audio finished.';
    }
    return status;
  }

  showSkipAudioWaitButton = () => {
    const { secondCount, blobURL, isRecording } = this.state;
    const { testMode } = this.props;

    let button = false;
    if (!testMode && secondCount !== 0 && blobURL === '' && !isRecording) {
      button = true;
    }

    return button;
  }

  playAudio = () => {
    const { data, resultMode } = this.props;
    const { soundStatus, secondCount } = this.state;

    let audio = false;
    if (data && !resultMode && soundStatus && secondCount === 0) {
      audio = true;
    }

    return audio;
  }

  optionResponseClass = (item) => {
    let class_name = "";

    const { answer } = this.props;
    const selectedValue = parseInt(this.state.selectedValue);

    return answer && (item.correct === 1 ? (selectedValue === item.id ? "success-wrapper" : "normal-wrapper") : (selectedValue === item.id) ? "error-wrapper" : "");
  }
  
  render() {
  	let question ="";
    if (this.props.restart) {
      this.props.restartQue();
    }
    if (this.props.stopRecording) {
      this.stopTest();      
    }
  	if (this.props.data) {
  		question = this.props.data.question;
      if (this.state.correctFlag) {
        this.updateCorrect();  
      }
  	}
  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		  this.setState({intervalId: intervalId,reset:false});
  	}

    const { data, resultMode, answer } = this.props;
    const { volume, playbackSpeed, soundKey, selectedValue } = this.state;

    return (
      <>
        {this.playAudio() &&
          <Sound
            url={`${s3base}${data.media_link}`}
            playStatus={answer ? Sound.status.STOPPED : Sound.status.PLAYING}
            onFinishedPlaying={this.handleSongFinishedPlaying}
            onPlaying={this.handleSongPlaying}
            volume={volume}
            playbackRate={playbackSpeed}  
            key={soundKey}
          />
        }

        <div className="pmw-tabs-section d-flex align-items-start pmw-outer-wrapper recorded-wrapper challenge-content-wrapper visibility-visible">
          <div className="recorded-section h-100 width-25"></div>
          <div className="recorded-section h-100 width-50">
            <div className="recorded-content">
              <div className="row">
                <div className="col-8">
                  <ul className="recorded-list">
                    <li>Status:</li>
                    <li>{this.playingQuestionSectionLabel()}</li>
                  </ul>
                </div>

                <div className="col-4">
                  {/*<span className="volume-label">Volume</span>*/}
                </div>
              </div>
              
              <div className="recorded-audio-wrapper">
                <div className="mt-3 clearfix">

                  <div className="row">
                    <div className="col-8">
                      <Progress value={(this.state.soundPer)} striped />
                    </div>
                    <div className="col-4">
                      <div className="volume-control-wrapper">
                        <span className="volume-icon">
                          <i class="fa fa-volume-up" aria-hidden="true"></i>
                        </span>
                        <div className="re-volume-wrapper">
                          <input
                            type="range"
                            placeholder="Volume"
                            className="volume-control"
                            min="0"
                            max="100"
                            step="1"
                            defaultValue={this.state.volume}
                            onInput={this.changeRange}
                            style={{
                              "--progress": `${(this.state.volume / 100) * 100}%`,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-8">
                      <div className="mt-2 pull-left visibility-visible">
                        <PlaybackSpeedBtns
                          changePlaybackSpeed={this.changePlaybackSpeed}
                          playbackSpeed={this.state.playbackSpeed}
                        />
                      </div>

                      {this.showSkipAudioWaitButton() &&
                        <div className="btn-outer pull-right visibility-visible">
                          <a
                            href="#"
                            className="outline-btn skip-btn-new text-center"
                            onClick={this.skip}
                          >Start Audio</a>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="radiolist-content custom-box visibility-visible">
          <h4>{question}</h4>
          <div className="radiolist-group">
            {data && !resultMode && data.option.map(function(item, i) {
              return (
                <label className={`radio-wrapper ${this.optionResponseClass(item)}`} key={i}>
                  <p>{item.options}</p>
                  <input
                    type="radio"
                    name="answer"
                    value={item.id}
                    checked={selectedValue == item.id}
                    onChange={this.handleChange}
                    disabled={answer ? true : false}
                  />
                  <span className="checkmark"></span>
                </label>
              )
            }, this)}
          </div>
        </div>
      </>
    );


    // return (
    //   <>
    //   <Col xs={12}>
    //   	<h4 className="infoText"></h4>
    //   </Col>
    //   <div className="container-fluid">
    //     <div className="row">
    //      { !this.props.resultMode && <Col xs={12}>
    //       	<div className="col-lg-12 text-center">
    //     		  <div className="currentStatus">
    //             <p>Current Status:{this.state.soundStatus == true && this.state.secondCount !== 0 && !this.state.isRecording && ' Begining in '+this.state.secondCount+ ' seconds' }
    //             {this.state.secondCount == 0 && this.state.soundStatus && ' Playing' }
                
    //             <br></br>
    //             Volume
    //              <Input placeholder="Volume" type="range" min="0" defaultValue={this.state.volume} step="1" onInput={(e) => this.changeRange(e)} max="100"  />
    //              <br></br>
    //             <Progress color="info" value={(this.state.soundPer)} />
    //             {!this.props.testMode && (
    //               <div className="d-flex justify-content-between">
    //                 <div className='stop-btn'>
    //                   {this.state.secondCount!==0 && this.state.blobURL === '' && !this.state.isRecording && <Button color="success" onClick={this.skip} size="sm">Skip</Button> }
    //                 </div>
    //                 <div className="stop-btn">
    //                   <PlaybackSpeedBtns changePlaybackSpeed={this.changePlaybackSpeed} playbackSpeed={this.state.playbackSpeed} />
    //                 </div>
    //               </div>
    //             )}
    //             </p>
                
    //           </div>
    //           <Col xs={12}>
    //             {this.props.data && !this.props.resultMode && this.state.soundStatus && this.state.secondCount === 0 && <Sound
    //               url={s3base + this.props.data.media_link}
    //               playStatus={Sound.status.PLAYING}
    //               onFinishedPlaying={this.handleSongFinishedPlaying}
    //               onPlaying={this.handleSongPlaying}
    //               volume={this.state.volume}
    //               playbackRate={this.state.playbackSpeed}
    //               key={this.state.soundKey}
    //             />}
    //           </Col>
    //     		  <Col xs={12}>
    //     		  	{ this.state.blobURL && <audio src={this.state.blobURL} controls="controls" />}
    //     		  </Col>
    // 		    </div>
    //       </Col> }    
    //       <Col xs={12}><h3 className="question">{question}</h3>
    //         <div className="answers">
    //         {this.props.data && this.props.resultMode && this.props.data.option.map(function(item){
    //                 return <React.Fragment>
    //                       <Label className={ this.props.answer && item.correct === 1 && 'correct-line' }>
    //                           <Radio
    //                               checked={this.state.selectedValue == item.id}
    //                               value={item.id}
    //                               color="default"
    //                               onChange={this.handleChange}
    //                               inputProps={{ 'aria-label': 'secondary checkbox' }}
    //                               name="answer"
    //                             />{' '}
    //                           {item.options}
    //                         </Label>
                            
    //                       </React.Fragment>;
    //               },this) }
    //         {this.props.data && !this.props.resultMode && this.props.data.option.map(function(item){
    //             return <React.Fragment>
    //                     <Label className={ this.props.answer && (item.correct === 1?(this.state.selectedValue == item.id?'correct-line':'blue-line'):(this.state.selectedValue == item.id)?'red-line':'') }>
    //                       <Radio
    //                         checked={this.state.selectedValue == item.id}
    //                         value={item.id}
    //                         color="default"
    //                         onChange={this.handleChange}
    //                         inputProps={{ 'aria-label': 'secondary checkbox' }}
    //                         name="answer"
    //                       />{' '}
    //                       {item.options}
    //                     </Label>
    //                   </React.Fragment>;
    //           },this) }
    //             </div>
    //           </Col>
    //       </div>
    //   </div>
    //   </>
    // );
  }
}
export default CorrectSummary;