/*!

=========================================================
* Paper Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Sub from "layouts/Sub.jsx";
import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";
import cookie from 'react-cookies';
import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/paper-dashboard.scss?v=1.1.0";
import "assets/scss/tabs.scss";
import "assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Provider } from 'react-redux'

import ReactRouteAll from './ReactRouteAll';

import {store,persistor} from '../src/store/index';

import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import "assets/css/designer.css";
import "assets/css/new-custom-style.css";
import "assets/css/r-new-custom-style.css";
import "assets/css/developer-new-custom-style.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

ReactDOM.render(
	<GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
		<Provider store = {store}>
			<PersistGate loading={null} persistor={persistor}>
				<ReactRouteAll />
			</PersistGate>
		</Provider>
	</GoogleOAuthProvider>,
  document.getElementById("root")
);
