import React, { memo } from "react";
import QuestionStrategyVideoThumbnail from "assets/images/images_new/question-strategy-video.png"

const QuestionStrategyVideo = memo(function QuestionStrategyVideo({
	subcategoryId, strategyVideo
}) {

	return (
		<div className="dash-col-2">
			<div className="strategy-video" onClick={() => strategyVideo(true)}>
				<div className="strategy-video-wrapper">
					<img
						src={QuestionStrategyVideoThumbnail}
					/>
					{/*<iframe
						className="str-video"
						src="https://www.youtube.com/embed/6WOv-BkFiYg?si=b3eODrl2vANY3-X3"
						title="YouTube video player"
						frameBorder="0"
						allowFullScreen
					></iframe>*/}
				</div>
				<span>Question Strategy Video</span>
			</div>
		</div>
	);
});
export default QuestionStrategyVideo;