import React, { useState, memo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { store } from "store/index";
import API, { base } from "api.js";
import useLogOut from "hooks/useLogOut";
import AvatarImg from "assets/images/images_new/avatar.png";
import Bell from "components/Notification/Bell";
import CollapseIcon from "assets/images/images_new/dropdown-icon.svg";
import CursorIcon from "assets/images/images_new/cursor-icon.png";
import SubscriptionIcon from "assets/images/images_new/subscription-icon.svg";
import GoldIcon from "assets/images/images_new/diamond-icon.svg";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { encode as base64_encode } from 'base-64';

const DashboardHeader = memo(function DashboardHeader({ title, sutitle }) {

    const history = useHistory();
    const { logout } = useLogOut();

    const { user } = store.getState();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);

    const toggle = () => setDropdownOpen((prevState) => !prevState);
    
    const openCloseSidebarMobile = () => {
  		if (document.body.classList.contains('leftSidebarClosed')) {
  			document.body.classList.remove('openSidebar');
  		} else {
  			document.body.classList.add('openSidebar');
  		}
  	}

    const fullName = () => {
      const { first_name, last_name, email } = user;
      let full_name = "";

      if (first_name !== "" && first_name !== null && first_name !== undefined) {
        full_name = `${first_name}`;
      }

      if (last_name !== "" && last_name !== null && last_name !== undefined) {
        full_name = `${full_name} ${last_name}`;
      }

      if (full_name === "") {
        full_name = email;
      }
      
      return full_name;
    }

    const profilePic = () => {
      let img_url = AvatarImg;
      if (user.image) {
        img_url = `${base}${user.image}`;
      }

      return img_url;
    }

    const naviagteTo = (url) => {
      history.push('/admin/profile');
    }


    const handleOperation = (e, page) => {
      e.preventDefault();

      switch(page) {
        case "profile":
          naviagteTo('/admin/profile');
          break;
        case "logout":
          logout();
          break;
        default:
          break;
      }
    }


    /**
     * Redirect to pte core.
     */
    const redirectNow = (token_id) => {
      const PTE_CORE_URL = process.env.REACT_APP_PTE_CORE_URL;
      const encoded_token_id = base64_encode(token_id);
      const pte_core = `${PTE_CORE_URL}default/check/post?cust=${encoded_token_id}`;
      window.location.href = pte_core; // redirect to pte core website.
    }


    /**
     * Process data to redirect to pte core.
     */
    const redirectToPteCore = () => {
      const { user: { id, email, token: access_token }, token } = store.getState();
      
      if (access_token !== undefined && access_token !== null && access_token.length > 0) {
        redirectNow(access_token[0].id);
        
      } else {
        // get id.
        API({
          url: "token/id",
          method: "GET"
        }).then((res) => {
          redirectNow(res.data.data);
        }).catch(err => console.log(err));
      }
    }

    useEffect(() => {
      const { user: { active_subscription } } = store.getState();
      setSubscriptions(active_subscription);
    }, []);

    return (
      <>
        <div className="dasboard-head">
          <div className="d-flex align-items-center">
            <span className="toggleIcon openSidebarMobile align-self-center pr-3" onClick={() => openCloseSidebarMobile()}>
    				  <img src="/images/new_dash/hamburger.png" alt="collapse icon" />
    			  </span>
            <h2 className="title">{title} {sutitle}</h2>
          </div>          
          <div className="user-info-content">
            <div>
              <a
                href="javascript:void(0)"
                onClick={redirectToPteCore}
                className="pte-core-btn cursor-pointer"
              >
                <img src={CursorIcon} alt="Go to PTE CORE" /> Go to PTE CORE
              </a>
            </div>
            
            <div className="subscription-icon" data-bs-toggle="modal" data-bs-target="#exampleModal"> 
              <img src={SubscriptionIcon} alt="Image" />
            </div>     
            
            <div className="user-notification">
              <Bell />
            </div>            
            
            <div className="user-info">
              <div className="user-img">
                <img src={profilePic()} alt="image" />
              </div>              
              <div className="user-dropdown-wrapper">
                <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
                  <DropdownToggle tag="span" className="badge-dropdown badge-profile cursor-pointer">{fullName()}</DropdownToggle>
                  <DropdownMenu>
                    {/*<DropdownItem>{fullName()}</DropdownItem>*/}
                    <DropdownItem
                      onClick={(e) => handleOperation(e, "profile")}
                    >My Profile</DropdownItem>
                    <DropdownItem divider />                    
                    <DropdownItem
                      onClick={(e) => handleOperation(e, "logout")}
                    >Logout</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>


        {/* subscription Modal HTML */}
        <div className="modal fade subscription-popup" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1
                  className="modal-title adjustment fs-5"
                  id="exampleModalLabel"
                >My Subscription(s)</h1>
                
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              
              <div className="modal-body p-0">
                <div className="sub-popup-card">
                  <h2 className="sub-popup-title">Active Subscription Plan</h2>
                  <ul className="sub-popup-content">
                    <li>Plan <span>Expiry</span></li>

                    {subscriptions.length > 0 ? (
                      <>
                        {subscriptions.map((item, i) => {
                          return (
                            <li key={i}>
                              <span className="gold-btn">
                                <img src={GoldIcon} alt="Image" /> {item.plan.title}
                              </span> <span>{item.expiry_date}</span>
                            </li>
                          )
                        })}

                        <li>
                          <a
                            href='/admin/plans'
                            className="btn btn-block"
                          >Upgrade Plan</a>
                        </li>
                      </>
                    ) : (
                      <li>
                        <a
                          to='/admin/plans'
                          className="btn btn-block"
                        >Buy a plan</a>
                      </li>
                    )}
                  </ul>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </>
    );
});
export default DashboardHeader;