import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SpeakingZone from "assets/images/images_new/microphone-icon.svg";
import WritingZone from "assets/images/images_new/writing-icon.svg";
import ReadingZone from "assets/images/images_new/reading-icon.svg";
import ListeningZone from "assets/images/images_new/listening-icon.svg";
import RightArrow from "assets/images/images_new/right-arrow.svg";
import API from "api.js";
import BookNowModal from "components/Dashboard/New/BookNowModal";

export default function UpcomingClasses(props) {
	const { classes, showInPopup } = props;

	return (
		<>
			{showInPopup ? (
				<div className="plan-classes-wrapper">
					<li className="title">Upcoming Classes</li>
					<ul className="upcoming-classes">
						{classes.map((item, i) => {
							return (
								<li className="list-body" key={i}>
									<span className="first-col">
										<a
											href={item.link}
											target="_blank"
											data-toggle="tooltip"
											data-placement="left" title={item.title}
										>{item.title}</a>
									</span><span>{item.time.split(" ")[1]}</span>
								</li>
							)
						})}
						
						<li>
							{classes.length > 0 ? (
								<Link
									to='/admin/session'
									className="sub-upgrade-btn"
								>
									Show more...
								</Link>
							) : (
								<span>No Upcoming Classes</span>
							)}
						</li>
					</ul>
				</div>
			) : (
				<div className="dashobard-upcoming-classes">
					<h2>Upcoming Classes</h2>
					<ul>
						{classes.map((item, i) => {
							return (
								<li className="speaking-list" key={i}>
									<span
										className="left-text adjust-width"
										data-toggle="tooltip"
										data-placement="left"
										title={item.title}
									>
										<span>{item.title}</span>
									</span>
									<span className="right-text">{item.time.split(" ")[1]}</span>
									<a
										href={item.link}
										target="_blank"
										className="right-text"
									>
										Open <img src={RightArrow} alt="Image" />
									</a>
								</li>
							)
						})}

						{classes.length === 0 &&
							<DummyClasses />
						}
					</ul>
				</div>
			)}
		</>
	);
}


const DummyClasses = () => {
	const [dummyClasses, setDummyClasses] = useState([]);
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(prev => !prev);
	}

	useEffect(() => {
		API({
			url: "dummy-classes",
			method: "GET"
		}).then((res) => {
			setDummyClasses(res.data);
		});
	}, []);

	return (
		<>
			{dummyClasses.length > 0 && dummyClasses.map((item, i) => {
				return (
					<li className="speaking-list" key={i}>
						<span
							className="left-text adjust-width"
							data-toggle="tooltip"
							data-placement="left"
							title={item.title}
						>
							<span>{item.title}</span>
						</span>
						<span className="right-text">{item.time.split(" ")[1]}</span>
						<a
							href="javascript:void(0)"
							onClick={toggle}
							className="right-text"
						>
							Buy Now <img src={RightArrow} alt="Image" />
						</a>
					</li>
				)
			})}

			{dummyClasses.length === 0 &&
				<li className="speaking-list">
					<span className="left-text">
						No Upcoming Classes <span className="complexity-badge difficult">No Classes</span>
					</span>
					<span className="right-text"></span>
					<a
						href="javascript:void(0)"
						onClick={toggle}
						className="right-text"
					>
						Buy Now <img src={RightArrow} alt="Image" />
					</a>
				</li>
			}

			<BookNowModal
				isOpen={isOpen}
				toggle={toggle}
			/>
		</>
	);
}