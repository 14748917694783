import React,{Component} from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col  
} from "reactstrap";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
import API from '../api';
import { Redirect } from "react-router-dom";
import {userContext} from 'userContext';
import { Helmet } from "react-helmet";
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";
import NotificationAlert from "react-notification-alert";
import { setUser, expVidPopup } from 'action/index.js';
import { connect } from "react-redux";
import { Grid } from '@material-ui/core';
import AiImage from 'assets/images/images_new/ai.png';
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import "assets/css/material-style.css";

const mapStateToProps = (state) => {
	return {
		user: state.user,
		expVidPopup: state.expVidPopup
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		setUser: (user) => dispatch(setUser(user)),
		setExpVidPopup: (count) => dispatch(expVidPopup(count))
	};
};

class Material extends Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      redirect:false,
      type:0,
      all_ok:true,
      categoryData: []
    };
    this.props.history.push(this.props.match.url);
  }

  componentWillUnmount() {
    document.body.classList.remove("leftSidebarClosed");
  }

  componentDidMount() {

    // Add this on priority
    document.body.classList.add("leftSidebarClosed");

    this.setSubcategories();

    if(this.props.user.active_subscription.length > 0 && this.props.user.active_subscription[0].plan.is_gold === 1) {
      this.props.setExpVidPopup(0);
    }
    const fetch = async () => {
      try {
        let response = await API("/getTokens");
        if(response.status === 200) {
          // console.log(response);
        } else {
          this.notify(2 ,"Something went wrong");
        }
      } catch(e) { }
    }

    fetch();
  }

  setSubcategories = () => {
    const data = [
      {
        "zone": "Speaking",
        "subcategories": [
          {
            "id": 1,
            "name": "Read Aloud",
            "ai": true
          },
          {
            "id": 2,
            "name": "Repeat Sentence",
            "ai": true
          },
          {
            "id": 3,
            "name": "Describe Image",
            "ai": true
          },
          {
            "id": 4,
            "name": "Re-tell Lecture",
            "ai": true
          },
          {
            "id": 5,
            "name": "Answer Short Question",
            "ai": true
          }
        ]
      },
      {
        "zone": "Writing",
        "subcategories": [
          {
            "id": 6,
            "name": "Summarize Written Text",
            "ai": true
          },
          {
            "id": 7,
            "name": "Write Essay",
            "ai": true
          }
        ]
      },
      {
        "zone": "Reading",
        "subcategories": [
          {
            "id": 12,
            "name": "Reading & Writing Fill in the Blanks (Drop-down)",
            "ai": false
          },
          {
            "id": 9,
            "name": "MCQ, Multiple Answer",
            "ai": false
          },
          {
            "id": 10,
            "name": "Reorder Paragraph",
            "ai": false
          },
          {
            "id": 11,
            "name": "Reading Fill in the Blanks (Drag & Drop)",
            "ai": false
          },
          {
            "id": 8,
            "name": "MCQ, Single Answer",
            "ai": false
          }
        ]
      },
      {
        "zone": "Listening",
        "subcategories": [
          {
            "id": 13,
            "name": "Summarize Spoken Text",
            "ai": true
          },
          {
            "id": 14,
            "name": "MCQ Single Answer",
            "ai": false
          },
          {
            "id": 15,
            "name": "MCQ Multiple Answer",
            "ai": false
          },
          {
            "id": 16,
            "name": "Listening Fill In The Blank",
            "ai": false
          },
          {
            "id": 17,
            "name": "Highlight Correct Summary",
            "ai": false
          },
          {
            "id": 18,
            "name": "Select Missing Word",
            "ai": false
          },
          {
            "id": 19,
            "name": "Highlight Incorrect Word",
            "ai": false
          },
          {
            "id": 20,
            "name": "Write From Dictation",
            "ai": false
          }
        ]
      }
    ];

    this.setState({
      categoryData: data
    });
  }

  showQuestion(type) {
    this.setState({'redirect':true,'type':type});
  }

  notify = (type,message) => {
    switch (type) {
      case 1:
        type = "success";
        break;
      case 2:
        type = "danger";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: 'tr',
      message: (
        <div>
          <div>
            {message}
          </div>
        </div>
      ),
      type: type,
      icon: "now-ui-icons ui-1_bell-53 fa fa-bell",
      autoDismiss: 10
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  renderSection = (section) => {
    const { categoryData } = this.state;
    let selected = null;

    switch (section) {
      case "Speaking":
        selected = categoryData[0];
        break;
      case "Writing":
        selected = categoryData[1];
        break;
      case "Reading":
        selected = categoryData[2];
        break;
      case "Listening":
        selected = categoryData[3];
        break;
    }

    let html = "";
    if (selected) {
      html = selected.subcategories.map((item, i) => {
        return (
          <div className="pm-card-outer cursor-pointer" onClick={this.showQuestion.bind(this, item.id)} key={i}>
            <div className="pm-card">
              <div className="pm-card-content">
                <h2 className="pm-card-title">{item.name}</h2>
                {item.ai &&
                  <img className="pm-card-img" src={AiImage} alt={item.name} />
                }
              </div>
              <div className="pm-card-btn">VIEW</div>
            </div>
          </div>
        )
      })
    }

    return html;
  }

  render() {
    if (this.state.redirect) {
       return <Redirect to={'/admin/questionList/0/'+this.state.type} />;
    }
    
    return (
      <>
      <Helmet>
        <title>Top PTE Training Institute</title>
      </Helmet>
      
      <div className="notification-div">
        <NotificationAlert ref="notificationAlert" zIndex={9999}/>
      </div>
      
      {!this.state.all_ok && <ChromeCheck />}
      
      {this.state.all_ok &&
        <div className="siteContent multipleCards">
            
          <DashboardHeader title="Practice Material" />          
            <Row>
              <div className="practice-material-categories dashboard-content-wrapper pm-categories !m-0">            
                  <div className="pm-wrapper-outer speaking">
                    <div className="pm-wrapper">
                      <h2 className="pm-title">Speaking Zone</h2>
                      <div className="pm-row">
                        {this.renderSection("Speaking")}
                      </div>
                    </div>
                  </div>
                  <div className="pm-wrapper-outer writing">
                    <div className="pm-wrapper">
                      <h2 className="pm-title">Writing Zone</h2>
                      <div className="pm-row">
                        {this.renderSection("Writing")}                
                      </div>
                    </div>
                  </div>
                  <div className="pm-wrapper-outer reading">
                    <div className="pm-wrapper">
                      <h2 className="pm-title">Reading Zone</h2>
                      <div className="pm-row">
                        {this.renderSection("Reading")}
                      </div>
                    </div>
                  </div>
                  <div className="pm-wrapper-outer listening">
                    <div className="pm-wrapper">
                      <h2 className="pm-title">Listening Zone</h2>
                      <div className="pm-row">
                        {this.renderSection("Listening")}                      
                      </div>
                    </div>
                  </div>            
              </div>
            </Row>
          </div>}
      </>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Material);
