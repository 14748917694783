import React from "react";
import { useHistory } from "react-router-dom";
import { store } from "store/index";
import API from "api.js";
import { setToken, setUser, setSub, expVidPopup } from "action/index.js";
import useLogOut from "hooks/useLogOut";

export default function Logout(props) {
  const { logout } = useLogOut();

  const logoutHandler = () => {
    logout();
  };

  return (
    <a href="javascript:void(0);" onClick={logoutHandler}>
      {/* <span className={`menuIcon ${props.icon}`}></span> */}
      <img src={`${props.icon}`}></img>
      <span className={`Notifications menuText ${props.premiumIcon ? "crownIcon" : ""}`}>
        {props.label}
      </span>
    </a>
  );
}
