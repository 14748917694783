import React from 'react';
import { Link} from 'react-router-dom';
import { Helmet } from "react-helmet";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Button,
  Table
} from "reactstrap";
import ReactGA from 'react-ga';
import API,{base} from "api.js";
import 'react-daypicker/lib/DayPicker.css';
import DayPicker from 'react-daypicker';
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import CalenderIcon from "../assets/images/images_new/calendar-icon.svg";

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          data: [],
          arr: {1 : [1,2,3,4,5],2 :[6,7],3 :[8,9,10,11,12],4: [13,14,15,16,17,18,19,20]},
          group: [],
          mock: {},
          result: false,
          remarks: [],
          activeTab: 1,
          user: false,
          f: true,
          date: new Date(),
          isDate: false,
          selectedDate: ""
        };
    }

    componentDidMount() {
        API({
            method: 'GET',
            url: 'mock/daily-report'
        }).then(data => {
			const result_data = data.data.data;
			const { date, remarks, user } = data.data;

			let group = result_data.reduce((r, a) => {
				r[a.type] = [...r[a.type] || [], a];
				return r;
			}, {});

			this.setState({
				selectedDate: date,
				group: group,
				result: result_data,
				remarks: remarks,
				user: user
			});
        }).catch(() => { });
    }

	getQuestionType = (type) => {
		const ques_types = [
			"",
			"Read Aloud", // 1
			"Repeat Sentence", // 2
			"Describe Image", // 3
			"Re-tell Lecture", // 4
			"Answer Short Questions", // 5
			"Summarize written text", // 6
			"Write Essay", // 7
			"Multiple Type, Single Answer", // 8
			"Multiple Type, Double Answer", // 9
			"Reorder Paragraph", // 10
			"Reading Fill in the Blanks", // 11
			"Fill in the Blanks Reading & Writing", // 12
			"Summarize Spoken text", // 13
			"MCQ Single answer", // 14
			"MCQ multiple answer", // 15
			"Listening Fill In the Blanks", // 16
			"Highlight correct summary", // 17
			"Select Missing Word", // 18
			"Highlight Incorrect word", // 19
			"Write from Dictation" // 20
		];
    	return ques_types[type];
	}

	calculateScore = (arr) => {
		let score = 0;
		let from = 0;
		arr.map((v)=>{
		    v.score.map((val)=>{
				score += val.score;
				from += val.from;
		    });
		});
		let per = score*100/from;
		return per.toFixed(2);
	}

	calculateRemarks = (arr) => {
	    let content_sc = 0;
	    let content_fr = 0;
	    let overall_sc = 0;
	    let overall_fr = 0;
	    let pro_sc = 0;
	    let pro_fr = 0;
	    let score = 0;
	    let fluency_sc = 0;
	    let fluency_fr = 0;
	    let grammar_sc = 0;
	    let grammar_fr = 0;
	    let vocab_sc = 0;
	    let vocab_fr = 0;
	    let form_sc = 0;
	    let form_fr = 0;
	    let spelling_sc = 0;
	    let spelling_fr = 0;
	    let struct_sc = 0;
	    let struct_fr = 0;
	    let ling_sc = 0;
	    let ling_fr = 0;
	    let from = 0;
	    let type = "";

		arr.map((v) => {
        	type = v.type;
        	v.score.map((val) => {
				if (val.type === 1) {
					fluency_sc += val.score;
					fluency_fr += val.from;
				} else if (val.type === 0) {
					content_sc += val.score;
					content_fr += val.from;
				} else if (val.type === 2) {
					pro_sc += val.score;
					pro_fr += val.from;
				} else if (val.type === 3) {
		            overall_sc += val.score;
		            overall_fr += val.from;
				} else if (val.type === 4) {
		            grammar_sc += val.score;
		            grammar_fr += val.from;
          		} else if (val.type === 5) {
		            form_sc += val.score;
		            form_fr += val.from;
				} else if (val.type === 6) {
		            vocab_sc += val.score;
		            vocab_fr += val.from;
				} else if (val.type === 7) {
		            spelling_sc += val.score;
		            spelling_fr += val.from;
				} else if (val.type === 8) {
		            struct_sc += val.score;
		            struct_fr += val.from;
				} else if (val.type === 9) {
		            ling_sc += val.score;
		            ling_fr += val.from;
				}
				score += val.score;
				from += val.from;
			});
		});

    	let newArr = [
			(content_sc*100)/content_fr,
			(fluency_sc*100)/fluency_fr,
			(pro_sc*100)/pro_fr,
			(overall_sc*100)/overall_fr,
			(grammar_sc*100)/grammar_fr,
			(form_sc*100)/form_fr,
			(vocab_sc*100)/vocab_fr,
			(spelling_sc*100)/spelling_fr,
			(struct_sc*100)/struct_fr,
			(ling_sc*100)/ling_fr
		];

    	let remarkAr = "<b>Remarks</b> -";
    	newArr.map((v,k) => {
      		if (!isNaN(v)) {
        		let mapArr = this.getScoreType(v, type, k);
        		if (![8,9].includes(parseInt(k))) {
          			remarkAr += ' ' + (mapArr.length > 0 ) ? '<strong>' + this.remarks(k,type)+' </strong>'+mapArr[0].remarks+' <br/>':"";
        		}
			}
		});
    	return (remarkAr === '<b>Remarks</b> -<strong> </strong> <br/>') ? "" : remarkAr;
	}

	getScoreType = (per, type, scType) => {
		return this.state.remarks.filter((a) => {
			return (a.min <= per) && (a.max >= per) && a.type == type && a.score_type == scType;
		});
	}

    remarks = (type, qType) => {
		let str = "";
		if (qType === 5) {
			return "";
		}
		switch(type) {
			case 0:
				str = "Content : ";
				break;
			case 1:
				str = "Fluency : ";
				break;
			case 2:
				str = "Pronunciation : ";
				break;
			case 3:
				str = "";
				break;
			case 4:
				str = "Grammar :";
				break;
			case 5:
				str = "Form : ";
				break;
			case 6:
				str = "Vocabulary : ";
				break;
			case 7:
				str = "Spelling : ";
				break;
			case 8:
				str = "Linguistic range : ";
				break;
			case 9:
				str = "Structure : ";
				break;
			default:
				str = "";
		}
		return str;
    }

	getTime = (arr) => {
		let time = 0;
		arr.map((v) => {
		    time += v.q_time;
		});
		return (time > 59) ? `${Math.floor(time/60)} minutes ${Math.floor(time%60)} sec` : `${time} sec`;
	}

	getUnattempted = (arr) => {
		let c = 0;
		arr.map((v)=>{
			if (v.not_attempted == 0) {
				c += 1;
			}
		});
		return c;
	}

	changeDate = (date) =>{
		this.setState({ date:date });
		var fullDate = new Date(date);
		var twoDigitMonth = fullDate.getMonth()+1 + "";

		if (twoDigitMonth.length == 1)
		    twoDigitMonth = "0" + twoDigitMonth;

		var twoDigitDate = fullDate.getDate() + "";
		if (twoDigitDate.length == 1)
		    twoDigitDate = "0" + twoDigitDate;

		var currentDate = fullDate.getFullYear()+'-'+twoDigitMonth+'-'+twoDigitDate ;
		API({
			method: 'GET',
			url: `mock/daily-report?date=${currentDate}`
		}).then(data => {
			const { date, remarks, user} = data.data;
			const result_data = data.data.data;
			let group = data.data.data.reduce((r, a) => {
				r[a.type] = [...r[a.type] || [], a];
				return r;
			}, {});
			this.setState({
				selectedDate: date,
				group: group,
				result: result_data,
				remarks: remarks,
				user: user,
				isDate: false
			});
	    }).catch(()=>{

	    });
	}

	render() {
		const { result, selectedDate, isDate, date, group } = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>Top PTE Training Institute</title>
				</Helmet>
				<div className="content p-0">
					<DashboardHeader title="Daily Feedback" />

					<div className="outer-wrapper pmw-outer-wrapper daily-feedback-outer p-3">
						<div className="w-100 d-flex align-items-center justify-content-between flex-wrap flex-md-nowrap flex-column flex-md-row mb-3">
							<div className="d-flex align-items-center flex-column flex-sm-row">
								<div className="choose-date-wrapper d-flex">
									<div className='attempted-question-btn mr-3'>
										Selected Date: <span className='badge badge-black'>{this.state.selectedDate}</span>
									</div>
									<button
										type="button"
										className="filter-date-btn"
										onClick={() => {
											this.setState({
												isDate: !this.state.isDate
											});
										}}
									>
										<img src={CalenderIcon} alt='Image' /> Filter Date
									</button>
									{this.state.isDate &&
										<DayPicker
											onDayClick={this.changeDate}
											active={this.state.date}
										/>
									}

									<div className="attempted-question-btn">
										<span className="complexity-badge medium mr-3">Total Question Attempted - <b>{result.length}</b></span>
									</div>
								</div>
							</div>
						</div>

						{Object.keys(group).map((val, key) => {
							return (
								<div className="daily-feedback-content">
									<h5>{this.getQuestionType(parseInt(val))}</h5>
									<p><b>Question Attempted</b> - {group[val].length}</p>
									<p><b>Score Percentage</b> - {this.calculateScore(group[val])} %</p>
									<p><p dangerouslySetInnerHTML={{__html: this.calculateRemarks(group[val])}}></p></p>
								</div>
							);
						})}
					</div>
					{/* <div className="subscription-record outer-wrapper daily-feedback">          
						<div className="table-heading">
							<h2>Daily Feedback</h2>
							<div className="d-flex">
								<div className='attempted-question-btn mr-3'>
				                    Selected Date: <span className='badge badge-black'>{this.state.selectedDate}</span>
				                </div>
								<div className="d-flex flex-column">							
									<button onClick={() => {
											this.setState({ isDate: !this.state.isDate });
										}}
										className="filter-date-btn"
									><img src={CalenderIcon} alt="Image"/>Filter Date</button>
									<div className="calendarOuter">
										{this.state.isDate && (
											<DayPicker
												onDayClick={this.changeDate}
												active={this.state.date}
											/>
										)}
									</div>
								</div>
								<div className='attempted-question-btn'>Questions Attempted <span className='question-badge'>7</span></div>
							</div>
						</div>
						<div className="table-wrapper">
							<Table>
								<tbody>
								<tr>
									<td className='table-counting w-10'>01</td>
									<td>{this.state.selectedDate}</td>
									<td></td>
									<td></td>
								</tr>
								</tbody>
							</Table>
						</div>
					</div> */}
				 	{/* <Row>
						<Col xs={12}>
							<Card>
								<CardHeader>
									<CardTitle tag="h4">Daily FeedBack</CardTitle>
								</CardHeader>
								<CardBody>
									<div className="DailyFeedBackouter">
										<h6><b>Total Question Attempted - {result.length}</b></h6>
										<h6><b>Selected Date - {selectedDate}</b></h6>
										<Button onClick={()=>{this.setState({ isDate: !isDate});}}>Choose Date</Button>
										{isDate &&
											<DayPicker onDayClick={this.changeDate} active={date} />
										}

										<div className="row mt-3">
											<div className="col-sm-12 feedbackCntnt">
												{Object.keys(group).map((val, key) => {
													return (
														<div className="remarksBreakdown">
															<h5>{this.getQuestionType(parseInt(val))}</h5>
															<p><b>Question Attempted</b> - {group[val].length}</p>
															<p><b>Score Percentage</b> - {this.calculateScore(group[val])} %</p>
															<p><p dangerouslySetInnerHTML={{__html: this.calculateRemarks(group[val])}}></p></p>
														</div>
													);
												})}
											</div>
										</div>
									</div>
								</CardBody>
							</Card>
						</Col>
		        	</Row> */}					
		        </div>
			</React.Fragment>
		);
	}
}
export default Home;
