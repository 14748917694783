import React from "react";
import { useEffect } from "react";
import { setUser, setAlert, setSub } from "action/index.js";
import { connect } from "react-redux";
import {
  Table,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from "reactstrap";
import { useState } from "react";
import API from "api.js";
import { useCallback } from "react";
import { store } from "store/index";
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
    setSub: (sub) => dispatch(setSub(sub)),
  };
};

const SubscriptionDetail = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [userData, setUserData] = useState(null);
  const activeSubscription = userData && userData.active_subscription;

  const getUser = useCallback(() => {
    API({
      method: "GET",
      url: "user-my-profile",
    })
      .then((data) => {
        if (data.data.user.active_subscription.length > 0) {
          const user = data.data.user;
          if(user.active_subscription[0].is_recurring_plan === 0) {
            props.history.replace("/admin/profile");
          }
          const subscriptionId =
            user.active_subscription[0].stripe_subscription_id;

          if (subscriptionId === "backend_subscription") {
            props.history.replace("/admin/profile");
          } else {
            setSubscriptionId(subscriptionId);
            setUserData(user);
          }
        } else {
          props.history.replace("/admin/profile");
        }
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const cancelSubApi = () => {
    setIsModalOpen(false);
    if (subscriptionId) {
      var bodyFormData = new FormData();
      bodyFormData.set("user_id", userData.id);
      bodyFormData.set("mode", activeSubscription[0].mode);
      bodyFormData.set("stripe_subscription_id", subscriptionId);
      API({
        method: "POST",
        url: "cancel/sub",
        data: bodyFormData,
      })
        .then((data) => {
          console.log(data.data);
          if ("status" in data) {
            props.setUser(data.data.user);
            const sub =
              data.data.user.active_subscription.length === 0 ? false : true;
            props.setSub(sub);
            store.dispatch(
              setAlert({ flag: true, type: 1, message: data.data.message })
            );

            props.history.replace("/admin/dashboard");
            return;
          } else {
            store.dispatch(
              setAlert({ flag: true, type: 2, message: "Something went wrong" })
            );
          }
        })
        .catch((error) => {
          store.dispatch(
            setAlert({ flag: true, type: 2, message: "Something went wrong" })
          );
        });
    }
  };

  const toggle = () => {
    setIsModalOpen(prevState => !prevState);
  }

  const showTable = activeSubscription && activeSubscription.length > 0;

  return (
    <>
      <div className="content p-0">
        <Modal
          isOpen={isModalOpen}
          size="md"
          className="text-translate-modal"
        >
          <ModalHeader className="text-translation" tag="div">
            Cancel Subscription ?

            <Button className="modalCloseBtn" onClick={() => toggle(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
              >
                <path
                  d="M18 6L6 18M6 6l12 12"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Button>
          </ModalHeader>

          <ModalBody>
            <div>
              <div className="text-translation">
                <div className="text-to-translate">
                  <div className="mb-2 ques-text text-center">Do you want to cancel subscription ?</div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              className="outline-btn pl-5 pr-5"
              onClick={cancelSubApi}
            >
              Yes
            </button>
            <Button
              className="close-button"
              onClick={toggle}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>

        <DashboardHeader title="Subscription Details" />
        <div className="subscription-record outer-wrapper">
          <div className="table-wrapper">
            {showTable && (
              <Table>
                <tbody>
                  <tr>
                    <th>Package Name:</th>
                    <td className="left-align">{activeSubscription[0]?.plan?.title}</td>
                  </tr>
                  <tr>
                    <th>Package Expiry:</th>
                    <td className="left-align">{activeSubscription[0]?.expiry_date}</td>
                  </tr>
                  <tr>
                    <th>Amount:</th>
                    <td className="left-align">
                      {activeSubscription[0]?.transaction.details.currency.toUpperCase() + " "}
                      <span className="bg-danger text-light p-1">{activeSubscription[0]?.amount}</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Recurring Plan:</th>
                    <td className="left-align">
                      {activeSubscription[0]?.is_recurring_plan
                        ? <span className="badge badge-success rounded-pill pl-4 pr-4 pt-2 pb-2">YES</span>
                        : <span className="badge badge-success rounded-pill pl-4 pr-4 pt-2 pb-2">NO</span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>Cancel Subscription:</th>
                    <td className="left-align">
                      <button
                        className="outline-btn"
                        onClick={toggle}
                      >
                        Cancel Subscription
                      </button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionDetail);
