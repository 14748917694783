import React, { useState, useEffect } from 'react';
import ScoreIcon from "assets/images/images_new/pie-chart-icon.svg";

export default function SpeakingResult(props) {
	const [result, setResult] = useState(null);
	const NOT_ALLOWED = ['overall'];

	useEffect(() => {
		const { resultData } = props;
		setResult(resultData);
	}, []);

	const scoreClass = (score) => {
		let class_name = "color-danger";

		if (score > 30 && score <= 60) {
			class_name = "color-warning";
		} else if (score > 60) {
			class_name = "color-success";
		}

		return class_name;
	}

	return (
		<>
			{result !== null &&
				<>
					<div className='result-score-row'>
						<div className='score-content'>
							<div className='score-circle-content'>
								<div className='score-circle'></div>
								<div className='score-circle-text'>
									<p>Overall Score</p>
									{props.subcategoryId !== 5 ? (
										<h2>{result.overall.score}/{result.overall.out_of}</h2>
									) : (
										<h2>{result.content.score}/{result.content.out_of}</h2>
									)}
								</div>
							</div>
							<ul className="score-list">
								{Object.keys(result).map((item, i) => {
									if (!NOT_ALLOWED.includes(item)) {
										return (
											<li key={i}>
												{result[item].label}: <span><img src={ScoreIcon} alt="Image" /> {result[item].score}/{result[item].out_of}</span>
											</li>
										)
									}
								})}
							</ul>
						</div>

						<div className='result-submitted-record'>
							<h2>Submitted Record</h2>
							<audio controls>
								<source src={props.recorded_file} />
								Your browser does not support the audio element.
							</audio>
						</div>
					</div>

					<div className='gab-card result-content'>
						<ul className="result-markers result-category">
							<li>
								<span className="good"></span> Good
							</li>
							<li>
								<span className="average"></span>Average
							</li>
							<li>
								<span className="bad"></span>Bad Pronunciation/Missed words
							</li>
						</ul>

						<div className='gab-text'>
							{props.newHtml !== null &&
								<>
									{props.renderResponseHtml(props.newHtml)}
									{props.subcategoryId === 5 &&
										<p>Correct Answer - <span className="color-success">{props.correctAnswer}</span></p>
									}
								</>
							}

							{props.newResult !== null && props.newResult !== undefined && 'html' in props.newResult &&
								props.renderResponseHtmlOldStyle(props.newResult.html)
							}
						</div>

						<div className='result-view-remarks'>
							{props.moreDetails}
						</div>
					</div>
				</>
			}
		</>	
	);

	// return (
	// 	<>
	// 		{result !== null &&
	// 			<div className="pmw-outer-wrapper">
	// 				<ul className="result-list">
	// 					{Object.keys(result).map((item, i) => {
	// 						return (
	// 							<li key={i}>
	// 								<h4 className="result-output">
	// 									{/*<span className={result[item].score === 0 ? 'text-danger' : (result[item].score > 0 && result[item].score < result[item].out_of ? 'text-orange' : 'text-success')}>{result[item].score}/{result[item].out_of}</span>*/}
	// 									<span className={scoreClass(result[item].score)}>{result[item].score}/{result[item].out_of}</span>
	// 								</h4>
	// 								<h3>{result[item].label}</h3>
	// 							</li>
	// 						)
	// 					})}
	// 				</ul>
	// 			</div>
	// 		}
	// 	</>	
	// );

}