import React, { memo } from 'react';
import "assets/css/new-result.css";
import SpeakingResult from "components/Result/SpeakingResult";
import WritingResult from "components/Result/WritingResult";
import ReadingResult from "components/Result/ReadingResult";
import ListeningResult from "components/Result/ListeningResult";
import ShowHistoryData from "views/ShowHistoryData.jsx";
import ReactHtmlParser from 'react-html-parser';
import MoreAiSuggestions from "components/Result/More/MoreAiSuggestions";

const Result = memo(function Result(props) {

	const {
		isAnswer, resultData, speakingResult, writingResult,
		readingResult, listeningResult, fail, newResult, newHtml,
		subcategoryId, correctAnswer, option_explaination,
		historyData, question_id, isPopup,

		recorded_file
	} = props;

	const speking_indicators = [
		1, 2, 3, 4, 5
	];


	const indicators = () => {
		return (
			<ul className="result-category">
				<li>
					<span className="good"></span> Good
				</li>
				<li>
					<span className="average"></span>Average
				</li>
				<li>
					<span className="bad"></span>Bad Pronunciation/Missed words
				</li>
			</ul>
		);
	}

	const renderResponseHtml = (html) => {
		return props.renderResponse(html);

		// return (
		// 	<div className="pmw-outer-wrapper">
		// 		<div className="result-content-wrapper d-flex align-items-center justify-content-between flex-column flex-md-row">
		// 			<div className="result-content h-100">
		// 				<p>{props.renderResponse(html)}</p>
		// 				{subcategoryId === 5 &&
		// 					<p>Correct Answer - <span className="green-text">{correctAnswer}</span></p>
		// 				}
		// 				{/*{speking_indicators.includes(subcategoryId) && indicators()}*/}
		// 			</div>
		// 		</div>
		// 		{writingResult && subcategoryId === 7 &&
		// 			<WritingResult
		// 				resultData={newResult}
		// 				buttonsOnly={true}
		// 			/>
		// 		}
		// 	</div>
		// );
	}

	const renderResponseHtmlOldStyle = (html) => {

		return ReactHtmlParser(newResult.html);

		// return (
		// 	<div className="pmw-outer-wrapper">
		// 		<div className="result-content-wrapper d-flex align-items-center justify-content-between flex-column flex-md-row">
		// 			<div className="result-content h-100">
		// 				<p className="text-dark white-space-fixed">{ReactHtmlParser(newResult.html)}</p>
		// 				{speking_indicators.includes(subcategoryId) && indicators()}
		// 			</div>
		// 		</div>
		// 		{writingResult && subcategoryId === 7 &&
		// 			<WritingResult
		// 				resultData={newResult}
		// 				buttonsOnly={true}
		// 			/>
		// 		}
		// 	</div>
		// );
	}


	const fetchAiSuggestions = () => {
		let html = "";

		if (subcategoryId === 7) {
			html = (
				<div className="more-options">
					<div className="mb-3">Suggestions:</div>

					<MoreAiSuggestions
						question_id={question_id}
					/>
				</div>
			);
		}

		return html;
	}

	return (
		<>
			{isAnswer && resultData !== '' &&
				<>
					{/*<div className={`result-section ${!isPopup ? "result-without-popup" : ""}`}>*/}
					<div className={`result-popup-content ${!isPopup ? "result-without-popup" : ""}`}>
						{speakingResult &&
							<SpeakingResult
								resultData={newResult}
								recorded_file={recorded_file}
								newHtml={newHtml}
								newResult={newResult}
								renderResponseHtml={renderResponseHtml}
								renderResponseHtmlOldStyle={renderResponseHtmlOldStyle}
								subcategoryId={subcategoryId}
								correctAnswer={correctAnswer}
								moreDetails={
									<ShowHistoryData
										data={historyData}
										flag={1}
										id={false}
										openAiRemarks={newResult}
										option_explaination={option_explaination}
										type={subcategoryId}
									/>
								}
							/>
						}

						{writingResult &&
							<WritingResult
								resultData={newResult}
								buttonsOnly={false}
								essay={subcategoryId === 7 ? true : false}
								newHtml={newHtml}
								newResult={newResult}
								renderResponseHtml={renderResponseHtml}
								renderResponseHtmlOldStyle={renderResponseHtmlOldStyle}
								subcategoryId={subcategoryId}
								moreDetails={
									<ShowHistoryData
										data={historyData}
										flag={1}
										id={false}
										openAiRemarks={newResult}
										option_explaination={option_explaination}
										type={subcategoryId}
									/>
								}
								aiSuggestions={fetchAiSuggestions()}
							/>
						}

						{readingResult &&
							<ReadingResult
								resultData={newResult}
								subcategoryId={subcategoryId}
								moreDetails={
									<ShowHistoryData
										data={historyData}
										flag={1}
										id={false}
										openAiRemarks={newResult}
										option_explaination={option_explaination}
										type={subcategoryId}
									/>
								}
							/>
						}

						{listeningResult &&
							<ListeningResult
								resultData={newResult}
								subcategoryId={subcategoryId}
								moreDetails={
									<ShowHistoryData
										data={historyData}
										flag={1}
										id={false}
										openAiRemarks={newResult}
										option_explaination={option_explaination}
										type={subcategoryId}
									/>
								}
							/>
						}

						{/*<ShowHistoryData
							data={historyData}
							flag={1}
							id={false}
							openAiRemarks={newResult}
							option_explaination={option_explaination}
							type={subcategoryId}
						/>*/}

						{/*{newHtml !== null &&
							renderResponseHtml(newHtml)
						}

						{newResult !== null && newResult !== undefined && 'html' in newResult &&
							renderResponseHtmlOldStyle(newResult.html)
						}*/}
					</div>
				</>
			}
		</>
	);
});
export default Result;