import React from "react";
import {
	CardTitle
} from "reactstrap";

export default function PracticeHeader({
	questionData, isAttempted
}) {

	const getComplexity = (req_type, comp) => {
		let complexity = "";
		
		switch (comp) {
			case 0:
				complexity = req_type === "label" ? "Easy" : "easy";
				break;
			case 1:
				complexity = req_type === "label" ? "Medium" : "medium";
				break;
			case 2:
				complexity = req_type === "label" ? "Difficult" : "difficult";
				break;
		}
		return complexity;
	};

	return (
		<div>
			<CardTitle tag="h4">
				{questionData.q_title}
			</CardTitle>

			<span
				className={`complexity-badge ${getComplexity("class", questionData.complexity)}`}
			>
				{getComplexity("label", questionData.complexity)}
			</span>

			{isAttempted &&
				<span className="attemped-badge text-uppercase ms-2">Attempted</span>
			}
		</div>
	);
}