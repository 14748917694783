import React,{Component} from "react";
import {
  Col,
  Progress,
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";
import "components/custom.css";
import {store,persistor} from 'store/index';
import TagIcon1 from "assets/images/images_new/tag1.svg";
import TimeUp from "components/PracticeMaterial/PopUps/TimeUp";
import ClockIcon from "assets/images/images_new/clock_1.png";

const LIMIT = 1200; // 20 minutes.
// const LIMIT = 10; // 10 seconds. testing only.
class Essay extends Component {

  constructor(props) {
    super(props);
    this.state = {
      question:"",
      secondCount: LIMIT,
      questionId:"",
      reset:false,
      content:'',
      lang:store.getState().user.lang,
      copy:'',
      resultObj:{},
      correctFlag:true,
      timeoutStat:false,
      showTimer: true,
      isOpen: false,
      inputRows: 1,
      scrollHeight: 0
    };
  }

  componentDidMount() {
    if (!this.props.answer) {
    	let intervalId = setInterval(this.timer, 1000);
      const { isQuestionTime, questionTime } = this.props;

      let state_obj = {};
      if (isQuestionTime) {
        state_obj.secondCount = (questionTime * 60);
        state_obj.showTimer = isQuestionTime;
      }

      state_obj.intervalId = intervalId;
      this.setState(state_obj);
    }
  }


  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
  			clearInterval(state.intervalId);
  			clearInterval(state.recordId);

        const { isQuestionTime, questionTime } = props;
        let secondState = { secondCount: 1200, showTimer: true };
        if (isQuestionTime) {
          secondState = {
            secondCount: questionTime * 60,
            showTimer: isQuestionTime,
          };
        }

  			//var intervalId = setInterval(ReadAloud.timer1, 1000);
  			return {timeoutStat:false,correctFlag:true,...secondState,content:'',questionId: props.data.id,reset:true};
  		}else{
  			if (!state.questionId) {
  				return {correctFlag:true,questionId: props.data.id,content:""};
  			}
  		}
  	}
  }

  stopTest = () =>{
    this.props.nextQuestion({...this.state.resultObj,time:this.state.secondCount});
  }
  copyToClipboard = () => {
    document.execCommand("copy");
  };

  cutToClipboard = () => {
    document.execCommand("cut");
  };

  pasteToClipboard = async () => {
    alert("Press Ctrl+v for paste text");

  };



	timer = () => {
    const { secondCount, intervalId } = this.state;
    const { answer } = this.props;
    let state_obj = {};

    // keep running timer if question is not submitted.
    if (!answer) {

  		if (secondCount === 1) {
  			clearInterval(intervalId);
        state_obj.timeoutStat = true;
        state_obj.isOpen = true;
  		}
      state_obj.secondCount = (secondCount - 1);
    
    } else {
      // clear interval.
      clearInterval(intervalId);
    }

    this.setState(state_obj);
	}

	cleanString(input) {
	    let output = "";
	    for (let i = 0; i < input.length; i++) {
	        output += input.charCodeAt(i) <= 127 ? input.charAt(i) : ' ';
	    }
	    return output;
	}

  handleTextareaSize = (e) => {

    const { inputRows, scrollHeight } = this.state;

    if ((scrollHeight < e.target.scrollHeight)) {
      if (inputRows < 7) {
        e.target.style.height = "0px";
        e.target.style.height = (25 + e.target.scrollHeight)+"px";

        this.setState({
          scrollHeight: e.target.scrollHeight,
          inputRows: inputRows + 1
        });
      }
    }
    else if (scrollHeight === e.target.scrollHeight) {
      e.target.style.height = scrollHeight+"px";
      e.target.style.height = (25 - e.target.scrollHeight)+"px";
      // this.setState({
      //   scrollHeight: e.target.scrollHeight
      // });
    }
  }

  chaneText = (e) => {
    this.props.showResult();
    let { name, value } = e.target;
  	let clean_value = value.replace('”', '"');
  	clean_value = this.cleanString(clean_value);
  	// let clean_value = value;

    this.props.submit({
  		answer: clean_value,
  		correct: '',
  		length: clean_value.split(' ').filter(function(n) { return n != '' }).length,
  		time: this.state.secondCount,
  		lang: this.state.lang
  	}, true);
    
    this.setState({
  		[name]: clean_value,
  		resultObj: {
  			answer: clean_value,
  			correct: '',
  			length: clean_value.split(' ').filter(function(n) { return n != '' }).length,
  			time: this.state.secondCount,
  			lang:this.state.lang
  		}
  	});
  };

  chaneLang = (e) => {
    this.setState({lang:e.target.value});
  }
  updateCorrect = () => {
    this.props.submit({'answer':'','correct':'','length':0,time:this.state.secondCount,lang:this.state.lang});
    this.setState({correctFlag:false,resultObj:{'answer':'','correct':'','length':0,time:this.state.secondCount,lang:this.state.lang}});
  }
	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}

  timeLeft = () => {
    const { secondCount } = this.state;
    return `${Math.floor(secondCount / 60)}m : ${secondCount % 60}s`;
  }

  wordCount = () => {
    const { content } = this.state;

    return content === "" ? 0 : content.trim().split(/\s+/).length;
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
  	let question ="";
    if (this.props.restart) {
      this.props.restartQue();
    }
    if (this.props.stopRecording) {
      this.stopTest();
    }
  	if (this.props.data) {
  		question = this.props.data.question;
      if (this.state.correctFlag) {
        this.updateCorrect();
      }
  	}
  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		  this.setState({intervalId: intervalId,reset:false});
  	}

    const { resultMode, answer, showResponse } = this.props;
    const { isOpen, timeoutStat } = this.state;

    return (
      <>
        <div className="custom-box">
          <p className="gray-text readable unselectable">{question}</p>
        </div>

        <div className="editor-wrapper-section custom-box">
          {!resultMode && !answer && !timeoutStat ? (
            <>
              <div className="editor-wrapper">
                <div className="editor__toolbar">
                  <button className="toolbar__button"></button>
                </div>
                <div className="editor__input">
                  <Input
                    type="textarea"
                    className="writing-textarea"
                    onCopy={this.copyFunc}
                    autoCorrect="false"
                    spellCheck="false"
                    name="content"
                    onChange={this.chaneText}
                    onKeyUp={this.handleTextareaSize}
                    value={this.state.content}
                    placeholder="Write your response here"
                    // rows={this.state.inputRows}
                  />
                  {/*!timeoutStat ? (
                    <Input
                      type="textarea"
                      onCopy={this.copyFunc}
                      autoCorrect="false"
                      spellCheck="false"
                      name="content"
                      onChange={(e) => { this.chaneText(e)}}
                      value={this.state.content}
                      placeholder="Write your response here..."
                    />
                  ) : (
                    <div style={{
                      whiteSpace: 'pre-line'
                    }}>{this.state.content}</div>
                  )*/}
                </div>
              </div>

              <div className="clearfix mt-3">
                <div className="time-spent pull-left">
                  <img src={ClockIcon} alt="Badge" /><span>Time Left:</span> {this.timeLeft()}
                </div>

                <div className="word-count pull-right">
                  <span>Total word count:</span> {this.wordCount()}
                </div>
              </div>
            </>
          ) : (
            <div className="editor-wrapper">
              <div className="editor__toolbar">
                <button className="toolbar__button">{/*<img src={BoldICon} alt="Bold" />*/}</button>
              </div>
              <div className="editor__input">
                <div style={{
                  whiteSpace: 'pre-line'
                }}>{this.state.content}</div>
              </div>
            </div>
          )}
        </div>

        <TimeUp
          isOpen={isOpen}
          toggle={this.toggle}
          title="Time up !!!"
          message="You have used up all your allotted time for this question."
          buttonLabel="Submit Now"
          showResponse={showResponse}
        />
      </>
    );

    // return (
    //   <>
    //   <Col xs={12} className="text-right"><b><p><i className="fa fa-clock-o"></i> <span>Time Left: {Math.floor(this.state.secondCount / 60) +':'+ (this.state.secondCount % 60)}</span></p> </b></Col>
    //   <Col xs={12}>
    //   	<h4 className="infoText"></h4>
    //   </Col>

    //   <Col xs={12}><h5>{question}</h5></Col>
    //   <Col xs={12}>
    //   	<div className="col-lg-12 text-center">
		//   <Col xs={12}>

		//   </Col>

    //   {!this.props.resultMode && !this.props.answer && <>
    //     <Col xs={2} style={{margin:'10px'}}>

    //     </Col>
    //     <Col xs={12}>
    //           <FormGroup>
    //             {!this.state.timeoutStat && <Input type="textarea" autoCorrect="false" spellCheck="false" name="content" id="textBox" onChange={(e) => { this.chaneText(e)} } value={this.state.content}  />}
    //             {this.state.timeoutStat && <div className="timeout-box">{this.state.content}</div>}
    //           </FormGroup>
    //           <div className="bottomContent">
    //             <p className="wordCount">Total word count: {this.state.content.split(' ').filter(function(n) { return n != '' }).length}</p>
    //             <div className="copyPaste">
    //               <a href="javascript:void(0)" onClick={this.cutToClipboard} className="btn">Cut</a>
    //               <a href="javascript:void(0)" onClick={this.copyToClipboard} className="btn">Copy</a>
    //               <a href="javascript:void(0)" onClick={this.pasteToClipboard} className="btn">Paste</a>
    //             </div>
    //           </div>
    //         </Col>

    //         </>
    //       }
		// </div>
    //   </Col>
    //   </>
    // );
  }
}

export default Essay;
