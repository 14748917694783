import React, { useState, useEffect } from 'react';

export default function ReadingingResult(props) {
	const [result, setResult] = useState(null);

	useEffect(() => {
		const { resultData } = props;
		setResult(resultData);
	}, []);

	const scoreClass = (score) => {
		let class_name = "color-danger";

		if (score > 30 && score <= 60) {
			class_name = "color-warning";
		} else if (score > 60) {
			class_name = "color-success";
		}

		return class_name;
	}

	const resultPercentage = () => {
		const { score, out_of } = result.score;

		return Math.round((score/out_of) * 100);
	}

	return (
		<>
			{result !== null &&
				<div className="visibility-visible">
					<div className='gab-card result-content'>
						<ul className="result-markers result-category reading" style={{ justifyContent: "center" }}>
							<li className="reading-result-good">
								<span></span>Correct {props.subcategoryId === 10 ? "Pair" : "Answer"}
							</li>
							<li className="reading-result-bad">
								<span></span>Wrong {props.subcategoryId === 10 ? "Pair" : "Answer"}
							</li>
							{props.subcategoryId !== 10 &&
								<li className="reading-result-not-selected">
									<span></span>Not selected but correct answer
								</li>
							}
						</ul>

						{props.subcategoryId === 10 &&
							<ul className="result-markers result-category reading" style={{ justifyContent: "center" }}>
								<li className="reading-result-not-selected">
									<span></span>Reorder Paragraph scoring is determined by correctly paired paragraphs rather than their sequential order.
								</li>
							</ul>
						}
					</div>
					
					<div className='result-score-row' style={{ display: "block", justifyItems: "center" }}>
						<div className='score-content'>
							<div className='score-circle-content'>
								{/*<div className='score-circle'></div>*/}
								<div
									className='semi-donut'
									style={{
										'--percentage': resultPercentage(),
										'--fill': '#0183fa',
									}}
								>
									<div className='score-circle-text'>
										<h2>
											{result.score.score}/{result.score.out_of}
										</h2>
										<p>Score</p>
									</div>
								</div>
							</div>

							<ul className="score-list">
								<li>
									{props.moreDetails}
								</li>
							</ul>
						</div>
					</div>
				</div>
			}
		</>
	);
}