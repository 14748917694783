import React, { memo, useState, useEffect } from "react";
import Carousal from "components/Dashboard/Carousal.jsx";
import {
	Modal,
	ModalHeader,
	ModalTitle,
	ModalBody,
	ModalFooter
} from 'reactstrap';

const TuteVideos = memo(function TuteVideos({
	videos
}) {

	return (
		<div className="row m-0">
			<div className="dashboard-videos-section">
				<div className="dashboard-videos-header">
					<h2 className="title">PTE Tutorial Videos</h2>
					<Tabs />
				</div>
				<div className="tab-content" id="myTabContent">
					<Videos 
						videos={videos}
					/>
				</div>
			</div>
		</div>
	);
});
export default TuteVideos;

const Videos = ({
	videos
}) => {

	const [videoModal, setVideoModal] = useState(false);
	const [videoUrl, setVideoUrl] = useState(false);

	const openModal = (video_url) => {
		toggle();
		setVideoUrl(video_url);
	}

	const toggle = () => {
		setVideoModal(prevState => !prevState);
	}

	return (
		<>
			<TabArea
				active="active"
				tab="speaking"
				videos={videos}
				openModal={openModal}
			/>
			
			<TabArea
				active=""
				tab="writing"
				videos={videos}
				openModal={openModal}
			/>

			<TabArea
				active=""
				tab="reading"
				videos={videos}
				openModal={openModal}
			/>

			<TabArea
				active=""
				tab="listening"
				videos={videos}
				openModal={openModal}
			/>

			<TabArea
				active=""
				tab="others"
				videos={videos}
				openModal={openModal}
			/>

			{videoModal &&
				<PlayVideoModal
					isOpen={videoModal}
					toggle={toggle}
					videoUrl={videoUrl}
				/>
			}
		</>
	);
};

const PlayVideoModal = ({
	isOpen, toggle, videoUrl
}) => {

	const [loaded, setLoaded] = useState(false);

	const frameLoad = () => {
		setLoaded(prevState => !prevState);
	}

	return (
		<Modal
			isOpen={isOpen}
			size="md"
			modalClassName="dashboard-video-popup"
			className="modal-dialog-centered"
		>
			<div className="modal-header">
				<h5 className="modal-title">
					New tips and strategies
				</h5>
				<button
					type="button"
					className="btn-close"
					aria-label="Close"
					onClick={toggle}
				></button>
			</div>
			<ModalBody>
				<iframe
					className="dashboard-popup-video"
					src="https://www.youtube.com/embed/3YoE2J085NI"
					title="YouTube Live video player"
					frameBorder="0"
					onLoad={frameLoad}
				></iframe>
				{!loaded &&
					<div className="text-center">Loading...</div>
				}
			</ModalBody>
		</Modal>
	);

	// return (
	// 	<div
	// 		className="modal fade dashboard-video-popup"
	// 		id="video-modal"
	// 		tabIndex="-1"
	// 		aria-labelledby="#exampleModalLabel"
	// 		aria-hidden="true"
	// 	>
	// 		<div className="modal-dialog modal-dialog-centered">
	// 			<div className="modal-content">
	// 				<div className="modal-header">
	// 					<h5 className="modal-title" id="exampleModalLabel">
	// 						New tips and strategies
	// 					</h5>
	// 					<button
	// 						type="button"
	// 						className="btn-close"
	// 						data-bs-dismiss="modal"
	// 						aria-label="Close"
	// 					></button>
	// 				</div>
	// 				<div className="modal-body">
	// 					<iframe
	// 						className="dashboard-popup-video"
	// 						src=""
	// 						title="YouTube Live video player"
	// 						frameBorder="0"
	// 					></iframe>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</div>
	// );
}

const TabArea = ({
	active, tab, videos, openModal
}) => {

	return (
		<div
			className={`tab-pane fade show ${active}`}
			id={tab}
			role="tabpanel"
			aria-labelledby={`${tab}-tab`}
		>
			<div className="dashboard-videos-row">
				{videos && videos[tab].map((video, i) => {
					return (
						<div
							className="video-card"
							onClick={() => {openModal(video.video)}}
							key={i}
						>
							<div className="video-wrapper">
								<iframe
									className="dashboard-video"
									src={video.video}
									title="YouTube Live video player"
									frameBorder="0"
								></iframe>
							</div>
							<div className="dashboard-video-content">
								<p className="video-sub-title">&nbsp;</p>
							</div>
						</div>
					)
				})}
			</div>
		</div>
	);
};


const Tabs = () => {
	const tabs = [
		{
			label: "Speaking",
			active: "active",
			tab: "speaking",
			ariaSelected: "true"
		},
		{
			label: "Writing",
			active: "",
			tab: "writing",
			ariaSelected: "false"
		},
		{
			label: "Reading",
			active: "",
			tab: "reading",
			ariaSelected: "false"
		},
		{
			label: "Listening",
			active: "",
			tab: "listening",
			ariaSelected: "false"
		},
		{
			label: "Others",
			active: "",
			tab: "others",
			ariaSelected: "false"
		}
	];

	return (
		<ul className="nav nav-tabs" id="myTab" role="tablist">
			{tabs.map((tab, i) => {
				return (
					<TabItem
						label={tab.label}
						active={tab.active}
						tab={tab.tab}
						ariaSelected={tab.ariaSelected}
						key={i}
					/>
				)
			})}
		</ul>
	);
};

const TabItem = ({
	active, tab, ariaSelected, label
}) => {
	return (
		<li className="nav-item" role="presentation">
			<button
				className={`nav-link ${active}`}
				id={`${tab}-tab`}
				data-bs-toggle="tab"
				data-bs-target={`#${tab}`}
				type="button"
				role="tab"
				aria-controls={tab}
				aria-selected={ariaSelected}
			>
				{label}
			</button>
		</li>
	);
};