const features = [
	{
		text: "Unlimited Al scoring for all question types.",
		for_all: true
	},
	{
		text: "Full access to the exam questions.",
		for_all: true
	},
	{
		text: "Access to latest templates and prediction files",
		for_all: true
	},
	{
		text: "Unlimited sectional mock tests with scoring.",
		for_all: true
	},
	{
		text: "Unlimited full mock tests with scoring.",
		for_all: true
	},
	{
		text: "Unlimited comprehensive mock tests with scoring.",
		for_all: true
	},
	{
		text: "Access to reading explanation videos.",
		for_all: false
	},
	{
		text: "Full access to premium strategy videos.",
		for_all: false
	},
	{
		text: "Full access to the detailed video course.",
		for_all: false
	}
];
export default features;