import React,{Component} from "react";
import { NavLink } from "react-router-dom";
import VideoImg from "assets/images/images_new/user-img.png"
import ArrowIcon from "assets/images/images_new/dropdown-icon.svg"
import TagIcon1 from "assets/images/images_new/tag1.svg"
import TagIcon2 from "assets/images/images_new/tag2.svg"
import TagIcon3 from "assets/images/images_new/tag3.svg"
import TagIconDefault from "assets/images/images_new/tagDefault.png"
import LoggingSystemImg from "assets/images/images_new/logging-system.png"
import BoldICon from "assets/images/images_new/bold-icon.svg"
import ArrowIcon1 from "assets/images/images_new/arrow-icon.svg"
import SearchIcon from "assets/images/images_new/zoom-lens.png";


// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  NavLink as NavLink1,
  TabContent, TabPane, Nav, NavItem,CardText,
  InputGroupText
} from "reactstrap";
import DataTable from 'react-data-table-component';
import API from "api.js";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { thead, tbody } from "variables/general";
// import SortByExplanationVideo from "components/PracticeMaterial/SortByExplanationVideo";
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";

// New components.
import Filters from "components/PracticeMaterial/New/Filters";
import SearchFilter from "components/PracticeMaterial/New/Filters/SearchFilter";
import TypeFilters from "components/PracticeMaterial/New/Filters/TypeFilters";
import OtherFilters from "components/PracticeMaterial/New/Filters/OtherFilters";
import ListTable from "components/PracticeMaterial/New/ListTable";
import QuestionListPagination from "components/PracticeMaterial/New/QuestionListPagination";

const ALL = 1;
const MONTHLY_PREDICTIONS = 2;
const EXAM_QUESTIONS = 3;

class QuestionList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      total:0,
      page: 1,
      search:'',
      count:10,
      mark:'all',
      attempted:'all',
      activeTab: ALL,
      type:this.props.match.params.id,
      prediction:this.props.match.params.type,
      all_ok: true,
      orderby: 'desc',
      complexity:'all',
      filterByVid: 'all',
      displaying_items: 10
    };
  }

  componentWillUnmount() {
    document.body.classList.remove("leftSidebarClosed");
  }

  componentDidMount() {
    // Add this on priority
    document.body.classList.add("leftSidebarClosed");

    // load data
    this.getListApi(1);
  }

  changePagination = (p) =>{

    let page = this.state.page+p;
    if(page > 0 && (page*10-10) < this.state.total){
      this.setState({page:page});
      this.getListApi(page);
    }

  }

  toggle = tab => {
    if(this.state.activeTab !== tab){
      this.setState({activeTab:tab,page:1},()=>{
        this.getListApi(1);
      });
    }
  }

  getListApi = (page) => {
    let { type, prediction, search, count, activeTab, mark, attempted, complexity, orderby, filterByVid } = this.state;
    API({
        method: 'GET',
        url: `question/${type}?prediction=${prediction}&page=${page}&search=${search}&count=${count}&type=${activeTab}&mark=${mark}&attempted=${attempted}&complexity=${complexity}&orderby=${orderby}&list=1&practice=true&filterByVid=${filterByVid}`,
      }).then(data => {
        this.setState({
          data: data.data.data,
          total: data.data.total
        });
        localStorage.setItem('orderby', this.state.orderby);
    }).catch(()=>{ });
  }

  // changeInput = (e) => {
  //   let { name, value } = e.target;
  //   this.setState({ [name] : value },() => {
  //   	if (name === "count" ) {
  //   		this.setState({ page : 1 },()=>{
  //   			this.getListApi(1);
  //   		});

  //   	}else if(name === "mark"){
  //       this.getListApi(1);
  //     }else if(name === "attempted"){
  //       this.getListApi(1);
  //     }else if(name === "complexity"){
  //       this.getListApi(1);
  //     }else if(name === "orderby" || name === "filterByVid"){
  //       this.getListApi(1);
  //     }
  //   });
  // }

  changeFilter = (key, value) => {
    this.setState({
      [key]: value
    }, () => {
      
      // load respective data.
      this.loadRespectiveData(key);
    });
  }

  loadRespectiveData = (key) => {
    if (
      key === "orderby" || 
      key === "complexity" || 
      key === "attempted" || 
      key === "mark" || 
      key === "filterByVid"
    ) {
      this.getListApi(1);
    }
  }

  clickSearch = () => {
    this.getListApi(1);
  }

  handleSearchChange = (e) => {
    const { value } = e.target;
    this.setState({
      search: value
    });
  }


  /**
   * Update display items per page. in table.
   */
  updateListingItems = (items, page) => {
    this.setState({
      count: items,
      page: page
    }, () => {
      // run api again to get updated listing.
      this.getListApi(page);
    });
  }


  /**
   * Change Pagination.
   */
  paginationChange = (page) => {
    this.setState({
      page
    }, () => {
      this.getListApi(page);
    });
  }


  /**
   * Switch between tabs.
   * All, Monthly Predictions, Exam Questions.
   */
  showData = (tab) => {
    this.setState({
      activeTab: tab,
      page: 1
    }, () => {
      this.getListApi(1);
    });
  }


  /**
   * Search by ExplanationVid.
   */
  searchByExplanationVideo = (e, val) => {
    console.log(val);
  }

  render() {
    let { page, count, all_ok, activeTab, type, mark, attempted, complexity, search, filterByVid, orderby } = this.state;
    let title = "";
    let i = page * count-count;

    switch(type) {
      case "1":
        title = "Read Aloud";
        break;
      case "2":
        title = "Repeat Sentence";
        break;
      case "3":
        title = "Describe Image";
        break;
      case "4":
        title = "Re-tell Lecture";
        break;
      case "5":
        title = "Answer Short Questions";
        break;
      case "6":
        title = "Summarize Written Text";
        break;
      case "7":
        title = "Write Essay";
        break;
      case "8":
        title = "MCQ, Single Answer";
        break;
      case "9":
        title = "MCQ, Multiple Answer";
        break;
      case "10":
        title = "Reorder Paragraph";
        break;
      case "11":
        title = "Reading Fill in the Blanks (Drag & Drop)";
        break;
      case "12":
        title = "Reading & Writing Fill in the Blanks (Drop-down)";
        break;
      case "13":
        title = "Summarize Spoken Text";
        break;
      case "14":
        title = "MCQ Single Answer";
        break;
      case "15":
        title = "MCQ Multiple Answer";
        break;
      case "16":
        title = "Listening Fill In The Blank";
        break;
      case "17":
        title = "Highlight Correct Summary";
        break;
      case "18":
        title = "Select Missing Word";
        break;
      case "19":
        title = "Highlight Incorrect Word";
        break;
      case "20":
        title = "Write From Dictation";
        break;
      default:
        // code block
    }

    return (
      <>
        {!all_ok && <ChromeCheck />}
        {all_ok &&
          <div className="siteContent readAloudPage p-0">
            <DashboardHeader title="Practice Material" />
            <div className="dashboard-content-wrapper read-aloud-wrapper qlist-header">
                <Card>
                  <CardHeader className="p-0 w-100">
                    <div className="read-aloud-header w-100">

                      {/* Filters on left side. */}
                      <div className="header-col-1">
                        <div className="header-col-content">
                          <CardTitle tag="h4">{title}</CardTitle>
                          {/* type filters */}
                          <TypeFilters
                            ALL={ALL}
                            MONTHLY_PREDICTIONS={MONTHLY_PREDICTIONS}
                            EXAM_QUESTIONS={EXAM_QUESTIONS}
                            activeTab={activeTab}
                            showData={this.showData}
                          />
                        </div>

                        {/* search bar filter */}
                        <SearchFilter
                          search={search}
                          handleSearchChange={this.handleSearchChange}
                          clickSearch={this.clickSearch}
                        />
                      </div>
                      
                      {/* Filters on right side. */}
                      <div className="header-col-2">
                        <OtherFilters
                          orderby={orderby}
                          complexity={complexity}
                          attempted={attempted}
                          mark={mark}
                          filterByVid={filterByVid}
                          changeFilter={this.changeFilter}
                          type={parseInt(this.state.type)}
                        />
                      </div>
                      {/* <Filters
                        ALL={ALL}
                        MONTHLY_PREDICTIONS={MONTHLY_PREDICTIONS}
                        EXAM_QUESTIONS={EXAM_QUESTIONS}
                        showData={this.showData}
                        activeTab={activeTab}
                        search={search}
                        handleSearchChange={this.handleSearchChange}
                        clickSearch={this.clickSearch}
                        type={parseInt(this.state.type)}
                        filterByVid={filterByVid}
                        searchByExplanationVideo={this.searchByExplanationVideo}
                      /> */}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                          <div className="table-responsive">
                            <ListTable
                              data={this.state.data}
                              type={parseInt(this.state.type)}
                              activeTab={activeTab}
                              mark={mark}
                              attempted={attempted}
                              complexity={complexity}
                              filterByVid={filterByVid}
                              search={search}
                              page={page}
                              listing={this.state.displaying_items}
                            />
                          </div>
                        </div>
                        <div className="tab-pane fade" id="monthlyprediction" role="tabpanel" aria-labelledby="monthlyprediction-tab">
                          Monthly Prediction table
                        </div>
                        <div className="tab-pane fade" id="examquestions" role="tabpanel" aria-labelledby="examquestions-tab">
                          Exam Question Table
                        </div>
                    </div>

                    {/* Pagination */}
                    <QuestionListPagination
                      total={this.state.total}
                      listing={this.state.displaying_items}
                      updateListingItems={this.updateListingItems}
                      page={page}
                      paginationChange={this.paginationChange}
                    />
                  </CardBody>

                  {/* <CardBody className="p-0">
                    <Row>
                      <Col lg={2} md={4} sm={4} className="text-center" >
                       <label>Search by Keywords</label>
                        <InputGroup style={{width: '100%'}}>
                          <Input placeholder="Search " onKeyDown={(e) => { this._handleKeyDown(e)} } name="search" autoCorrect="false" spellCheck="false" onChange={(e) => { this.changeInput(e)} } value={this.state.search} />
                          <InputGroupAddon addonType="append">
                            <InputGroupText onClick={this.clickSearch} className="btn-primary"><i className="fa fa-search" ></i></InputGroupText>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>

                      <Col lg={2} md={4} sm={4} className="text-center" >
                        <label>Practice Status</label>
                        <select onChange={this.changeInput} name="attempted" className="form-control" >
                          <option value="all">Select</option>
                          <option value="1">Attempted</option>
                          <option value="0">Not Attempted</option>
                        </select>
                      </Col>

                      <Col lg={2} md={4} sm={4} className="text-center" >
                        <label>Order By</label>
                        <select onChange={this.changeInput} name="orderby" className="form-control" >
                          <option value="desc">Newest First</option>
                          <option value="asc">Oldest First</option>
                        </select>
                      </Col>

                      <Col lg={1} md={4} sm={4} className="text-center" >
                        <label>Badge</label>
                        <select onChange={this.changeInput} name="mark" className="form-control" >
                          <option value="all">Select</option>
                          <option value="marked">All Marked</option>
                          <option value="red">Red</option>
                          <option value="green">Green</option>
                          <option value="yellow">Yellow</option>
                        </select>
                      </Col>

                      <Col lg={1} md={4} sm={4} className="text-center" >
                        <label>Complexity</label>
                        <select onChange={this.changeInput} name="complexity" className="form-control" >
                          <option value="all">Select</option>
                          <option value="0">Easy</option>
                          <option value="1">Medium</option>
                          <option value="2">Difficult</option>
                        </select>
                      </Col>

                      <Col lg={1} md={4} sm={4} className="text-center" >
                        <label>Pagination</label>
                        <select onChange={this.changeInput} name="count" className="form-control" >
                          <option value="10">10</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                          <option value="500">500</option>
                        </select>
                      </Col>

                      { (this.state.type == 11 || this.state.type == 12) &&
                        <Col lg={2} md={4} sm={4} className="text-center" >
                          <SortByExplanationVideo onChange={this.changeInput} />
                        </Col>
                      }
                   </Row>
                  </CardBody> */}
                </Card>
            </div>
          </div>
        }
      </>
    );
  }
}

export default QuestionList;
