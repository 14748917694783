import React, { memo } from "react";
import {
	Card, CardHeader, CardBody
} from "reactstrap";
import PracticeHeader from "components/PracticeMaterial/New/PracticePageHeaderComponents/PracticeHeader";
import QuestionTagSelect from "components/PracticeMaterial/New/PracticePageHeaderComponents/QuestionTagSelect";
import PracticeClockMain from "components/PracticeMaterial/New/PracticePageHeaderComponents/PracticeClockMain";
import QuestionStrategyVideo from "components/PracticeMaterial/New/PracticePageHeaderComponents/QuestionStrategyVideo";

const PracticePageHeader = memo(function PracticePageHeader({
	questionData, isAttempted, TAGS, toggle, isOpen, changeHandler,
	time, subcategoryId, title, strategyVideo
}) {


	return (
		<Card>
			<CardHeader className="p-0">
				<div className="dash-col-1 practice-page-header">
					<PracticeHeader
						questionData={questionData}
						isAttempted={isAttempted}
					/>

					<div className="read-aloud-header pmw-header">
                        <QuestionTagSelect
							TAGS={TAGS}
							questionData={questionData}
							toggle={toggle}
							isOpen={isOpen}
							changeHandler={changeHandler}
						/>

						<PracticeClockMain
							time={time}
						/>
					</div>
				</div>

				<QuestionStrategyVideo
					subcategoryId={subcategoryId}
					strategyVideo={strategyVideo}
				/>

				<div className="dash-col-3 h-100">
					<CardBody className="p-0 h-100">
						<p className="gray-text h-100">{title}</p>
					</CardBody>
				</div>
			</CardHeader>
		</Card>
	);
});
export default PracticePageHeader;
