import React, { useState, useEffect } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'reactstrap';
import API from 'api.js';

export default function MoreAiSuggestions(props) {

	const [isOpen, setIsOpen] = useState(false);
	const [data, setData] = useState("");
	const [title, setTitle] = useState("");

	const suggestedIdeas = () => {
		API({
			url: "essay/suggest/keywords",
			method: "POST",
			data: {
				question_id: props.question_id
			}
		}).then(res => {
			setIsOpen(true);
			setData(res.data.keywords);
			setTitle(res.data.title);
		});
	}

	const sampleResponse = () => {
		API({
			url: "essay/sample/response",
			method: "POST",
			data: {
				question_id: props.question_id
			}
		}).then(res => {
			setIsOpen(true);
			setData(res.data.sample_response);
			setTitle(res.data.title);
		});
	}

	return (
		<>
			<button
				className="outline-btn ml-3"
				size="sm"
				onClick={sampleResponse}
			>Sample Response</button>

			<button
				className="outline-btn ml-3"
				size="sm"
				onClick={suggestedIdeas}
			>Suggested Ideas</button>

			<Modal
				isOpen={isOpen}
				size="lg"
				className="text-translate-modal unselectable pre-line"
			>
				<ModalHeader
					className="text-translation"
					tag="div"
				>
					{title}
					<Button className="modalCloseBtn" onClick={() => { setIsOpen(false) }}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							width="24"
							height="24"
						>
							<path
								d="M18 6L6 18M6 6l12 12"
								stroke="currentColor"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</Button>
				</ModalHeader>
				<ModalBody>
					<div className="custom-box">
						<div className="translate-title gray-text">
							<span className="font-16">{data}</span>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
}