import React, { useEffect, useState, memo } from "react";
import API from "api.js";
import { base, s3base } from "api.js";
import ReactHtmlParser from 'react-html-parser';
import {
	Modal,
	ModalHeader,
	Button,
	ModalBody,
	ModalFooter,
	Table,
	// Slider
} from "reactstrap";
import { confirmAlert } from 'react-confirm-alert';
import { store } from 'store/index';
import { setAlert } from 'action/index.js';
import "assets/css/popup.css";
import TemplateDetectorModal from "components/PracticeMaterial/PopUps/TemplateDetectorModal";

const CONTENT = 0;
const FLUENCY = 1;
const PRONUNCIATION = 2;

const History = memo(function History(props) {
	const { questionData, activeTab, tabME, tabOTHERS } = props;

	const [questions, setQuestions] = useState([]);
	const [meTabLoaded, setMeTabLoaded] = useState(false);
	const [otherTabLoaded, setOtherTabLoaded] = useState(false);
	const [modal, setModal] = useState(false);
	const [qid, setQid] = useState(null);
	const [noRecords, setNoRecords] = useState(false);
	const [explanationModal, setExplanationModal] = useState(false);
	const [templateDetectorModalProps, setTemplateDetectorModalProps] = useState({});

	useEffect(() => {
		if (!meTabLoaded && activeTab === tabME) {
			loadData();
		}

		if (!otherTabLoaded && activeTab === tabOTHERS) {
			loadData();
		}
	}, [activeTab]);

	const closeModalExplanation = () => {
		setExplanationModal(false);
	};


	const loadData = () => {
		let bodyFormData = new FormData();
		bodyFormData.set('question_id', questionData.id);
		bodyFormData.set('skip', 0); // we will only load 10 records.

		if (activeTab === tabOTHERS) {
			bodyFormData.set('all', 1);
		}

		API({
			method: 'POST',
			url: 'show/history',
			data: bodyFormData
		}).then(data => {
			if(data.status === 200) {
				if (data.data.data.length === 0) setNoRecords(true);
				else setNoRecords(false);
				
				if (activeTab === tabME) {
					setMeTabLoaded(true);
				} else if (activeTab === tabOTHERS) {
					setOtherTabLoaded(true);
				}
				setQuestions(data.data.data);
			} else {
				setNoRecords(true);
			}
		});
	}

	const renderName = (user) => {
		let name = "-";
		if (user !== undefined && user.length > 0) {
			name = user[0].first_name;
		}

		return (
			<h3 className="result-user-name">{name}</h3>
		);
	}

	const renderImage = (user) => {
		let image_url = null;
		if (user !== undefined && user.length > 0) {
			image_url = base + user[0].image;
		}

		return (
			<div className="result-user-img">
				<img src={image_url} alt="Image" />
			</div>
		);
	}

	const renderAttemptedDate = (question) => {
		const month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
		
		const d = new Date(question.local_date);
		const m = month[d.getMonth()];
		const dt = d.getDate();
		const yr = d.getFullYear();
		
		let hours = d.getHours();
		hours = hours % 12;
		const ampm = hours >= 12 ? 'PM' : 'AM';
		const minutes = d.getMinutes();

		const parsed_date = `${m} ${dt}, ${yr} ${hours}:${minutes} ${ampm}`;

		return (
			<h3 className="result-user-date">{parsed_date}</h3>
		);
	}

	const renderScore = (score) => {
		const sc = Math.round(score.reduce((sum, key) => sum + parseFloat(key.score), 0));
		const out_of = score.reduce((sum, key) => sum + parseFloat(key.from), 0);
		
		return (
			<div className="result-score-wrapper">
				Score <span>{sc}/{out_of}</span>
			</div>
		);
	}

	const renderScoresOne = (question) => {
		return (
			<div className="result-info-audio">
				<div className="recorded-section h-100 w-100">
					<div className="recorded-content w-100 border-0 m-0">
						<ul className="recorded-list">
							<li>Recorded Audio</li>
						</ul>
						<div className="recorded-audio-wrapper">
							<audio
								controls
								src={`${s3base}/ptedata/ptemedia/${question.file}`}
							></audio>
						</div>
					</div>
				</div>
			</div>
		);
	}

	const renderScoresTwo = (question) => {
		return (
			<div className="result-info-audio">
				<div>
					<div className="custom-box m-0">
						<p className="gray-text">
							{ReactHtmlParser(question.answer)}
						</p>
					</div>
				</div>
			</div>
		);
	}

	const renderResponseByCategory = (question) => {
		let score_response = null;
		if ([1, 2, 3, 5].includes(question.question.subcategory_id)) {
			score_response = renderScoresOne(question);

		} else if ([11, 12, 20, 19, 16, 6, 7, 13].includes(question.question.subcategory_id)) {
			score_response = renderScoresTwo(question);

		}
		return score_response;
	}

	const resultClass = (question) => {
		let outer_class = "";
		if ([11, 12, 20, 19, 16, 6, 7, 13].includes(question.subcategory_id)) {
			outer_class = "user-result-history";
		}
		return outer_class;
	}

	const showScore = (e, q_id) => {
		setQid(q_id);
		setModal(true);
	}

	const modalToggle = () => {
		setModal(!modal);
	}

	const showHistoryFunc = () => {
		let arr = questions.map((v) => {
			if (qid === v.id) {
				return v.score;
			}
		});
		
		let arr1 = arr.filter((va) => {
			if(typeof va === "object") {
				return va;
			}
		})[0];

		let a = arr1.map( (v) => {
			return (
				`<tr>
					<td>${remarks(v.type)}</td>
					<td class="result-score-wrapper">
						<span>${v.score}/${v.from}</span>
					</td>
					<td>${v.remarks}</td>
				</tr>`
			)
		});

		if ([6, 7, 13].includes(questions[0].question.subcategory_id)) {
			let t_from = 0;
			let t_score = 0;
			for (var i = 0; i <= arr1.length-1; i++) {
    			t_from += arr1[i].from;
    			t_score += arr1[i].score;
			}
			a.push(`
				<tr>
					<td>Total</td>
					<td colspan="2">${t_score}/${t_from}</td>
				</tr>
			`);
		}
		return a;
	}

	const remarks = (type) => {
		const remarksArray = [
			"Content", "Fluency", "Pronunciation", "Score",
			"Grammar", "Form", "Vocabulary", "Spelling",
			"Linguistic range", "Structure"
		];

		return remarksArray[type];
	}

	const deleteScore = (e, q_id) => {
		confirmAlert({
			title: '',
			message: 'Are you sure you want to delete this record?',
			buttons: [
				{
					label: 'Yes',
					onClick: () => deleteScoreApi(e, q_id)
				},
				{
					label: 'No'
				}
			]
		});
	}

	const deleteScoreApi = async (e, id) => {
		let bodyFormData = {
			id,
			question_id: questionData
		};

		let res = null;
		try {
			res = await API({
				method: 'Delete',
				url: 'delete/question/responseNew',
				data: bodyFormData
			});
		} catch(error) {
			console.log("unable to delete");
		};

		if(res) {
			if (res.status === 200) {
				let data = questions.filter((val)=> {
					if(parseInt(val.id) !== parseInt(id)) {
						return val;
					}
				});
				setQuestions(data);

				store.dispatch(setAlert({
					flag: true,
					type: 1,
					message: res.data.message
				}));
			}
		}
	}

	const renderNoRecords = () => {
		return (
			<div className="no-data visibility-visible">
				No data yet.
			</div>
		);
	}

	const getAverageScore = (question) => {
		const { score } = question;
		let average_score = 0;
		let out_of = 0;
		for (let i = 0; i < score.length; i++) {
			average_score += score[i].score;
			out_of += score[i].from;
		}
		
		return {
			score: average_score / score.length,
			out_of: out_of / score.length
		};
	}

	const scoreObject = (score) => {
		return {
			score: score.score,
			out_of: score.from,
		};
	}

	const updateScorePopupData = (scores, data) => {

		if (scores.length > 0) {
			for(let i = 0; i < scores.length; i++) {
				let score = scores[i];
				
				switch (score.type) {
					case CONTENT:
						data.contentScore = scoreObject(score);
						break;
					case FLUENCY:
						data.fluencyScore = scoreObject(score);
						break;
					case PRONUNCIATION:
						data.pronunciationScore = scoreObject(score);
						break;
					default:
						break;
				}
			}
		}

		return data;
	}

	return (
		<>
			<Modal
				isOpen={modal}
				size="lg"
				className="score-popup"
			>
				<ModalHeader>
					Score with remarks{" "}
					<Button className="modalCloseBtn" onClick={modalToggle}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							width="24"
							height="24"
						>
							<path
								d="M18 6L6 18M6 6l12 12"
								stroke="currentColor"
								strokeWidth="2"
								strokeLinecap="round"
								strokeLinejoin="round"
							/>
						</svg>
					</Button>
				</ModalHeader>
				<ModalBody>
					<Table className="d-none d-md-block">
						<tbody>
							<tr>
								<th>Component</th>
								<th>Score</th>
								<th>Remarks</th>
							</tr>
							{modal &&
								showHistoryFunc().map((v) => {
									return ReactHtmlParser(v)
								})
							}
						</tbody>
					</Table>
				</ModalBody>
				<ModalFooter>
					<Button
						color="secondary"
						className="modalCloseBtn"
						onClick={modalToggle}
					>
						Close
					</Button>
				</ModalFooter>
			</Modal>
			
			{noRecords &&
				renderNoRecords()
			}

			{explanationModal && (
				<TemplateDetectorModal
					isOpen={explanationModal}
					{...templateDetectorModalProps}
					closeModal={closeModalExplanation}
				/>
			)}

			{questions.map((question, i) => {
				const type = question?.practice_question_template_detected?.type;
        		const sentence = question?.practice_question_template_detected?.sentence;
		        

				const average = getAverageScore(question);

				const averageScore = average.score;
				const averageOutOf = average.out_of;

				let score_popup_data = {
					remarks: question?.score[0].remarks,
					overallScore: {
						score: averageScore.toFixed(0),
						out_of: averageOutOf.toFixed(0)
					}
				};

				score_popup_data = updateScorePopupData(question.score, score_popup_data);
				
				const templateDetectorModalProps = {
					title: type === "repeat" || type === "common" ? sentence : "",
					template_type: type,
					common_sentence: type === "common" ? sentence : "",
					data: score_popup_data
				};

				return (
					<div
						className="result-info-outer visibility-visible"
						key={i}
					>
						<div className={`result-info-row ${resultClass(question.question)}`}>
							<div className="re-user-info w-100 w-md-50">
								<div className="result-info-wrapper">
									{renderImage(question.user)}
									<div className="result-user-info">
										{renderName(question.user)}
										{renderAttemptedDate(question)}
									</div>
								</div>
							</div>
							{renderResponseByCategory(question)}
						</div>
						<div className="d-flex align-items-center justify-content-between mt-3">
							{renderScore(question.score)}
			                <div className="right-btns">
			                	{store.getState().user.id === question.user_id &&
									<a
										href="javascript:void(0);"
										className="outline-btn"
										onClick={(e) => deleteScore(e, question.id)}
									>Delete</a>
								}

								<a
									href="javascript:void(0);"
									className="submit-btns"
									onClick={(e) => {
										if (question?.type == 3 || question?.type == 4) {
											if (type === "repeat" || type === "common") {
												setTemplateDetectorModalProps(templateDetectorModalProps);
												setExplanationModal(true);
											} else {
												showScore(e, question.id);
											}
										} else {
											showScore(e, question.id);
										}
									}}
								>View</a>
							</div>
						</div>
					</div>
				);
			})}
		</>
	);
});
export default History;