import React, { memo } from "react";
import ClockIcon from "assets/images/images_new/sidebar-icons/clock.png";

const PracticeClockMain = memo(function PracticeClockMain({
	time
}) {
	
	/**
	 * Convert seconds to hours.
	 */
	const secondsToHours = (seconds) => {
		return Math.floor(seconds / 3600);
	};


	/**
	 * Convert seconds to minutes.
	 */
	const secondsToMinutes = (seconds) => {
		return Math.floor((seconds % 3600) / 60);
	};

	/**
	 * Convert seconds to seconds.
	 */
	const secondsToSeconds = (seconds) => {
		return Math.floor((seconds % 3600) % 60);
	};

	return (
		<div className="time-spent">
			<img src={ClockIcon} alt="Badge" />

			<span>Time Spent:</span>{" "}
			{secondsToHours(time)}h :{" "}
			{secondsToMinutes(time)}m :{" "}
			{secondsToSeconds(time)}s
		</div>
	);
});
export default PracticeClockMain;