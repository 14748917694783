import React from "react";
import { NavLink } from "react-router-dom";
import MenuItemDropdown from "components/Dashboard/Sidebar/MenuItemDropdown.jsx";
import Logout from "components/Auth/Logout.jsx";

export default function LeftMenuItem(props) {
  const { item, type, url, icon, label, premiumIcon, bottomMenu } = props;

  return (
    <li className={`menuItem menuItem${item} ${type === 2 ? "dropdown" : ""} ${bottomMenu ? "bottom-menu" : ""}`}>
      {(() => {
        switch (type) {
          case 1:
            return (
              <NavLink to={`/admin/${url}`} activeClassName="menuCurrent">
                {icon && <img src={icon} alt={`${label} icon`} />}
                <span className={`menuText ${premiumIcon ? "crownIcon" : ""}`}>{label}</span>
              </NavLink>
            );
          case 2:
            return (
              <>
              {/* {icon && <img src={icon} alt={`${label} icon`} />} */}
              <MenuItemDropdown {...props} />
              </>
            );
          case 3:
            return (
          <>
              {/* {icon && <img src={icon} alt={`${label} icon`} />}  */}
              <Logout icon={icon} premiumIcon={premiumIcon} label={label} />             
          </>
			)
          default:
            return null;
        }
      })()}
    </li>
  );
}