import React, { Component } from "react";
import Slider from "react-slick";
import {
  Table
} from "reactstrap";
import API from "api.js";
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import { Link } from "react-router-dom";

import checkIcon from "assets/images/images_new/check_circle.svg";
import cancelIcon from "assets/images/images_new/cancel.svg";
import ArrowIcon from "assets/images/images_new/dropdown-icon.svg";
import AcceptCard from "assets/images/images_new/accept-card.svg";
import GoldImg from "assets/images/images_new/gold-icon.svg";
import secureIcon from "assets/images/images_new/lock-icon.svg";
import successIcon from "assets/images/images_new/success-icon.png";
import upgradeImg from "assets/images/images_new/upgrade-icon.png";
import starImg from "assets/images/images_new/star-icon.svg";


class Transaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    API({
      method: "GET",
      url: "transaction",
    }).then((data) => {
      this.setState({
        data: data.data.data
      });
    })
    .catch(() => {});
  }

  subscriptionTitle = (val) => {
    return val.details !== null
      ? val.details.package
      : val.subscription &&
        val.subscription.plan &&
        val.subscription.plan.title
  }
  
  recurringDate = (val) => {
    return (val.subscription?.status === 1 &&
      val.subscription?.is_recurring_plan === 1) ? (
        val.subscription?.expiry_date
    ) : (
      "-"
    );
  }

  expiryDate = (val) => {
    return (val.subscription?.status === 1 &&
      val.subscription?.is_recurring_plan === 0) ? (
        val.subscription?.expiry_date
    ) : (
      "-"
    );
  }

  render() {
    const sliderSettings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '100px',
      responsive: [
        {
          breakpoint: 1024, 
          settings: {
            centerPadding: '50px',
          },
        },
        {
          breakpoint: 768,
          settings: {
            centerPadding: '50px',
          },
        },
        {
          breakpoint: 480,
          settings: {
            centerPadding: '20px',
          },
        },
      ],
    };

    const { data } = this.state;

    return (
      <>
        <div className="content p-0"> 
          <DashboardHeader title="Payments" />

          <div className="subscription-record outer-wrapper">
            {data.length === 0 ? (
              <div className="table-heading visibility-visible">
                <span className="no-record-found">No subscription record found</span> <a href="#" className="upgrade-btn"><img src={starImg} alt="Image" /> Upgrade to Gold</a>
              </div>
            ) : (
              <div className="table-wrapper">
                <Table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Subscription</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Recurring Date</th>
                      <th>Expiring On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((val, key) => {
                      return (
                        <tr className="visibility-visible" key={key}>
                          <td>{key + 1}</td>
                          <td>{this.subscriptionTitle(val)}</td>
                          <td>
                            <SubscriptionAmount
                              data={val}
                            />
                          </td>
                          <td>
                            <SubscriptionStatus
                              subscription={val.subscription}
                            />
                          </td>
                          <td>{this.recurringDate(val)}</td>
                          <td>{this.expiryDate(val)}</td>
                          <td>
                            <RowAction
                              subscription={val.subscription}
                            />
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            )}
          </div>


          {/*<div className="transation-page outer-wrapper">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="transation-tab-wrapper">
                  <div className="dashboard-videos-header">
                    <h2 className="title">Choose a package</h2>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="silver-tab" data-bs-toggle="tab" data-bs-target="#silver-tab-pane" type="button" role="tab" aria-controls="silver-tab-pane" aria-selected="true">Silver</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="gold-tab" data-bs-toggle="tab" data-bs-target="#gold-tab-pane" type="button" role="tab" aria-controls="gold-tab-pane" aria-selected="false">Gold</button>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content" id="myTabContent">
                      <div className="tab-pane fade show active" id="silver-tab-pane" role="tabpanel" aria-labelledby="silver-tab" tabIndex="0">
                        <div className="package-content">
                          <h3 className="package-title">Available Features</h3>
                          <ul className="package-list">
                            <li><img src={checkIcon} alt="Image" /> Unlimited AI scoring for all question types</li>
                            <li><img src={checkIcon} alt="Image" /> Full access to them exam questions</li>
                            <li><img src={checkIcon} alt="Image" /> Access to latest templates and prediction files</li>
                            <li><img src={checkIcon} alt="Image" /> Unlimited sectional mock tests with scoring</li>
                            <li><img src={checkIcon} alt="Image" /> Unlimited full mock tests with scoring</li>
                            <li><img src={checkIcon} alt="Image" /> Unlimited comprehensive mock tests with scoring</li>
                            <li className="cencel-list"><img src={cancelIcon} alt="Image" /> Access to reading explanation videos</li>
                            <li className="cencel-list"><img src={cancelIcon} alt="Image" /> Full access to premium strategy videos</li>
                            <li className="cencel-list"><img src={cancelIcon} alt="Image" /> Full access to the detailed video course
                            </li>
                          </ul>
                        </div>
                      </div>    
                      <div className="tab-pane fade" id="gold-tab-pane" role="tabpanel" aria-labelledby="gold-tab" tabIndex="0">
                        <div className="package-content">                        
                          <h3 className="package-title">Available Features</h3>
                          <ul className="package-list">
                            <li><img src={checkIcon} alt="Image" /> Unlimited AI scoring for all question types</li>
                            <li><img src={checkIcon} alt="Image" /> Full access to them exam questions</li>
                            <li><img src={checkIcon} alt="Image" /> Access to latest templates and prediction files</li>
                            <li><img src={checkIcon} alt="Image" /> Unlimited sectional mock tests with scoring</li>
                            <li><img src={checkIcon} alt="Image" /> Unlimited full mock tests with scoring</li>
                            <li><img src={checkIcon} alt="Image" /> Unlimited comprehensive mock tests with scoring</li>
                            <li className="cencel-list"><img src={cancelIcon} alt="Image" /> Access to reading explanation videos</li>
                            <li className="cencel-list"><img src={cancelIcon} alt="Image" /> Full access to premium strategy videos</li>
                            <li className="cencel-list"><img src={cancelIcon} alt="Image" /> Full access to the detailed video course
                            </li>
                          </ul>  
                        </div>
                      </div> 
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <Slider {...sliderSettings} className="transation-package-slider">
                  <div className="slider-card bestseller">
                    <div className="slider-card-title">
                      <h3>AUD - 2 Month</h3> <span className="slider-card-badge">bestseller</span>
                    </div>
                    <div className="slider-card-content">
                      <h4><img src={checkIcon} alt="Check icon" /> 58 16% OFF</h4>
                      <h5>49* - 2 Month</h5>
                      <p>Auto renews every 2 months</p>
                    </div>
                    <div className="slider-card-btn">
                      <a href="#">Buy now <img src={ArrowIcon} alt="Arrow icon" /></a>
                    </div>
                  </div>
                  <div className="slider-card standard">
                    <div className="slider-card-title">
                      <h3>AUD - 1 Month</h3><span className="slider-card-badge">standard</span>
                    </div>
                    <div className="slider-card-content">
                      <h4><img src={checkIcon} alt="Check icon" /> 87 21% OFF</h4>
                      <h5>69* - 3 Month</h5>
                      <p>Auto renews every 3 month</p>
                    </div>
                    <div className="slider-card-btn">
                      <a href="#">Buy now <img src={ArrowIcon} alt="Arrow icon" /></a>
                    </div>
                  </div>
                  <div className="slider-card bestseller">
                    <div className="slider-card-title">
                      <h3>AUD - 2 Month</h3> <span className="slider-card-badge">bestseller</span>
                    </div>
                    <div className="slider-card-content">
                      <h4><img src={checkIcon} alt="Check icon" /> 58 16% OFF</h4>
                      <h5>49* - 2 Month</h5>
                      <p>Auto renews every 2 months</p>
                    </div>
                    <div className="slider-card-btn">
                      <a href="#">Buy now <img src={ArrowIcon} alt="Arrow icon" /></a>
                    </div>
                  </div>
                  <div className="slider-card standard">
                    <div className="slider-card-title">
                      <h3>AUD - 1 Month</h3><span className="slider-card-badge">standard</span>
                    </div>
                    <div className="slider-card-content">
                      <h4><img src={checkIcon} alt="Check icon" /> 87 21% OFF</h4>
                      <h5>69* - 3 Month</h5>
                      <p>Auto renews every 3 month</p>
                    </div>
                    <div className="slider-card-btn">
                      <a href="#">Buy now <img src={ArrowIcon} alt="Arrow icon" /></a>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <br />*/}

          {/*<div className="transation-page outer-wrapper">
              <div className="row">
                <div className="col-md-6">
                  <div className="payment-card">
                    <h2 className="title">Payment Summary</h2>
                    <p className="payment-title">Billing Plan</p>
                    <div className="billing-card">
                      <p>Billed Yearly</p>
                      <p className="price">$119</p>
                      <p className="per-month">Per month</p>
                      <img className="payment-gold-img" src={GoldImg} alt="image" />
                    </div>
                    <p className="form-payment-title">Payment Details <img src={secureIcon} alt="Image" /></p>
                    <form className="payment-card-form">
                        <div className="input-wrapper">
                          <label htmlFor="name" className="form-label">Name on card</label>
                          <input type="text" className="form-control" id="name" placeholder="John Doe" />
                        </div>
                        <div className="input-wrapper">
                          <label htmlFor="cardnumber" className="form-label">Credit Card Number</label>
                          <input type="number" className="form-control" id="cardnumber" placeholder="Enter card number" />
                        </div>
                        <div className="input-row">
                          <div className="input-wrapper">
                            <label htmlFor="cxpiration" className="form-label">Expiration</label>
                            <input type="date" className="form-control" id="cxpiration" placeholder="MM/YY" />
                          </div>
                          <div className="input-wrapper">
                            <label htmlFor="cvv" className="form-label">CVV</label>
                            <input type="number" className="form-control" id="cvv" placeholder="CVV Code" />
                          </div>
                        </div>
                        <div className="input-row">
                          <div className="input-wrapper">
                            <label htmlFor="zip" className="form-label">ZIP</label>
                            <input type="number" className="form-control" id="zip" placeholder="Enter ZIP Code" />
                          </div>
                          <div className="input-wrapper">
                            <label className="form-label">Country</label>
                          <select className="form-select" aria-label="Default select example">
                            <option selected>All</option>
                            <option value="1">India</option>
                            <option value="2">UK</option>
                            <option value="3">USA</option>
                          </select>
                          </div>
                        </div>
                        <button type="submit" className="process-payment-btn">Process Payment</button>
                        <p className="form-text">By providing your card information, you allow Language Academy to charge your card for future payments in accordance with their terms.</p>
                    </form>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="payment-card package-content">
                    <h2 className="title">Package Benefits</h2>
                    <ul className="package-list">
                      <li><img src={checkIcon} alt="Image" /> Duration 1 Month(s)</li>
                      <li><img src={checkIcon} alt="Image" /> Unlimited Al scoring for the question types.</li>
                      <li><img src={checkIcon} alt="Image" /> Full access to the exam questions.</li>
                      <li><img src={checkIcon} alt="Image" /> Access to latest templates and prediction files.</li>
                      <li><img src={checkIcon} alt="Image" /> Unlimited sectional mock tests with scoring.</li>
                      <li><img src={checkIcon} alt="Image" /> Unlimited full mock tests with scoring.</li>                  
                      <li><img src={checkIcon} alt="Image" /> Access to reading explanation videos.</li>                  
                      <li><img src={checkIcon} alt="Image" /> Full access to premium strategy videos.</li>                  
                    </ul>
                    <div className="accept-cards">
                      <p>We accept the following card</p>
                      <img src={AcceptCard} alt="Image" />
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <br />*/}

          {/*<div className="transation-page payment-successful outer-wrapper">
            <div className="successful-content">
              <img src={successIcon} alt="Image" />
              <h2>Payment successful</h2>
              <p>Transaction was successful <br /> and your subscription is now active</p>
              <div className="payment-btns">
                <a href="#" className="pay-btn dashboard-btn">Go To Dashboard</a>
                <a href="#" className="pay-btn manage-btn">Manage Plan</a>
              </div>
            </div>
          </div>
          <br />
          <br />*/}
          
          {/*<div className="upgrade-gold">
            <img className="upgrade-img" src={upgradeImg} alt="Image" />
            <h2>Amazing Features, Go Pro!</h2>
            <p>Upgrade now and unlock access to exclusive educational materials.</p>
            <a href="#" className="upgrade-btn"><img src={starImg} alt="Image" /> Upgrade to Gold</a>
          </div>
          <br />
          <br />*/}
        </div>
      </>
    );
  }
}
export default Transaction;


/**
 * Subscription Amount.
 */
const SubscriptionAmount = ({
  data
}) => {

  const currency = data.details !== null
  ? data.details.currency.toUpperCase()
  : data.subscription.plan.price_in == data.amount
  ? "INR"
  : "AUD";

  return (
    <>
      {currency} <b className="text-danger">{data.amount}</b>
    </>
  );
};


/**
 * Subscription Status.
 */
const SubscriptionStatus = ({
  subscription
}) => {

  let class_name = (subscription.status === 1) ? "success pl-3 pr-3" : "danger";
  let label = (subscription.status === 1) ? "Active" : "Inactive";

  return (
    <span className={`badge badge-${class_name}`}>{label}</span>
  );
};


/**
 * Row Action.
 */
const RowAction = ({
  subscription
}) => {

  return (
    <>
      {subscription?.status === 1 && subscription?.is_recurring_plan === 1 && (
        <Link
          to="/admin/subscription-detail"
          className="outline-btn"
        >
          Cancel Subscription
        </Link>
      )}
    </>
  );
};
