import React from "react";
import {
	Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";
import TagIcon1 from "assets/images/images_new/tag1.svg";
import TagIcon2 from "assets/images/images_new/tag2.svg";
import TagIcon3 from "assets/images/images_new/tag3.svg";
import TagIconDefault from "assets/images/images_new/tagDefault.png";

export default function QuestionTagSelect({
	TAGS, questionData, toggle, isOpen, changeHandler
}) {

	const tagIcon = (tag) => {
		const tags = {
			green: TagIcon1,
			red: TagIcon2,
			yellow: TagIcon3,
			grey: TagIconDefault,
		};
		if (typeof tag !== "string" && typeof tag !== undefined) {
			tag = tag.length > 0 ? tag[0].tag : "grey";
		}

		return tags[tag] !== undefined ? tags[tag] : TagIconDefault;
	};

	const tagLabel = (tag) => {
		const tags = {
			green: "Green",
			red: "Red",
			yellow: "Yellow",
			grey: "No Tag",
		};
		if (typeof tag !== "string" && typeof tag !== undefined) {
			tag = tag.length > 0 ? tag[0].tag : "grey";
		}
		
		return tags[tag] !== undefined ? tags[tag] : "No Tag";
	};

	return (
		<Dropdown
			isOpen={isOpen}
			toggle={toggle}
			direction="down"
		>
			<DropdownToggle tag="span" className="badge-dropdown">
				<img
					src={tagIcon(questionData.tag)}
					alt="Image"
				/>{" "}
				{tagLabel(questionData.tag)}
			</DropdownToggle>
			
			<DropdownMenu>
				{TAGS.map((tag, i) => {
					let item =
						questionData.tag.length > 0
						? questionData.tag[0].tag
						: "grey";
					
					if (item !== tag) {
						return (
							<DropdownItem
								key={i}
								onClick={() => changeHandler(tag)}
							>
								<img src={tagIcon(tag)} alt="Image" />{" "}
								{tagLabel(tag)}
							</DropdownItem>
						);
					}
				})}
			</DropdownMenu>
		</Dropdown>
	);
}