import React,{Component} from "react";

// reactstrap components
import {
  Col,
  ListGroupItem,
  ListGroup
} from "reactstrap";
import "components/custom.css";
import {SortableContainer, SortableElement, arrayMove} from 'react-sortable-hoc';
import Board, { moveCard } from '@lourenci/react-kanban';
import ArrowIcon1 from "assets/images/images_new/arrow-icon.svg";
import TimeUp from "components/PracticeMaterial/PopUps/TimeUp";
import "assets/css/reorder.css";

const IDEAL_TIME = 150; // SECONDS.

const SortableItem = SortableElement(({value}) =>
 
    <ListGroupItem>{value}</ListGroupItem>

);

const SortableList = SortableContainer(({items}) => {
  return (
    <ListGroup>`
      {items.map((value, index) => (
        <SortableItem
          key={`item-${index}`}
          index={index}
          value={value.options}
        />
      ))}
    </ListGroup>
  );
});

class Reorder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      question: "",
      questionId: "",
      reset: false,
      is_answer: false,
      items: [],
      sortedItems: [],
      board: {},
      arr: [],
      resultObj: {},
      correctFlag: true,
      idealTime: IDEAL_TIME,
      idealTimeInterval: null,
      idealTimeExceed: false
    }
  }  

  componentDidMount() {

    // initialize board
    const board = this.initializeBoard();
    const items = this.initializeItems();

    // start countdown.
    const idealTimeInterval = setInterval(this.countDownToIdealTime, 1000);
    this.setState({
      idealTimeInterval,
      board,
      items: items.items,
      sortedItems: items.sortedItems
    });
  }


  componentWillUnmount() {
    const { idealTimeInterval } = this.state;

    clearInterval(idealTimeInterval);
  }

  //
  initializeItems = () => {
    const { items } = this.state;
    const { option } = this.props.data;

    let data = {
      items: [],
      sortedItems: []
    }
    
    if (items.length === 0) {
      data.items = option;
      data.sortedItems = option.slice().sort(function(a, b) {
        return a.index - b.index
      });
    }

    return data;
  }


  // default board. if there is no option data.
  emptyBoard = () => {
    return {
      lanes: [
        {
          id: 1,
          title: 'Source',
          cards: [],
        },
        {
          id: 2,
          title: 'Target',
          cards: []
        }
      ]
    };
  }


  // initialize board with data.
  initializeBoard = () => {
    const { option } = this.props.data;

    let board = this.emptyBoard();

    if (option && option.length > 0) {
      board = {
        lanes: [
          {
            id: 1,
            title: 'Source',
            cards: option.map((item) => {
              return {
                id: item.id,
                title: '',
                description: item.options,
                content: item.options
              }
            }),
          },
          {
            id: 2,
            title: 'Target',
            cards: []
          }
        ]
      };
    }

    return board;
  }


  // start idealtime countdown.
  countDownToIdealTime = () => {
    const { idealTime, idealTimeInterval } = this.state;
    const { answer } = this.props;

    if(answer) {
      clearInterval(idealTimeInterval); // stop timer when submitted.
    } else {
      let state_obj = {};

      if (idealTime > 0) {
        state_obj.idealTime = (idealTime - 1);
      } else {
        clearInterval(idealTimeInterval);
        state_obj.idealTimeExceed = true;
      }
      this.setState(state_obj);
    }
  }

  idealTimeExceedToggle = () => {
    const { idealTimeExceed } = this.state;

    this.setState({
      idealTimeExceed: !idealTimeExceed
    });
  }

  // showAnswer = () => {

  //   if (this.props.answer != this.state.is_answer) {
  //     if (!this.props.resultMode) {
        
  //       // if(document.querySelector('div[data-rbd-draggable-id="lane-draggable-1"] .sc-Axmtr')) {
  //       if(document.querySelector('div[data-rbd-draggable-id="lane-draggable-1"] > div > div:first-child')) {
  //         document.querySelector('div[data-rbd-draggable-id="lane-draggable-1"] > div > div:first-child').innerHTML ='<span>Correct Order</span>';
  //         document.querySelector('div[data-rbd-draggable-id="lane-draggable-2"] > div > div:first-child').innerHTML ='<span>Your Response</span>';
  //       }
  //     }
      
  //     this.setState({
  //       is_answer: true
  //     }, () => {
  //       if (!this.props.resultMode) {
  //         // ye sara commented code uncomment krna hai. results section bnane ke bad.
  //         // let elemArr = document.querySelectorAll('.list-group-item1');
  //         // for (var i = 0; i < elemArr.length; i++) {
  //         //   elemArr[i].classList.add('cross');
  //         // }
  //         // if (this.state.resultObj.answer) {
  //         //   let correct = this.state.resultObj.correct;
  //         //   let answer = this.state.resultObj.answer;
  //         //   for (var i = 0; i < answer.length-1; i++) {
  //         //     for (var j = 0; j < correct.length-1; j++) {
  //         //       if (correct.length > i+1) {
  //         //         if (answer[i]+'-'+answer[i+1] == correct[j]+'-'+correct[j+1] ) {
  //         //           elemArr[i].classList.remove('cross');
  //         //         }
  //         //       }
  //         //     }
  //         //   }
  //         // }
  //       }
  //     });
  //   }
  // }
  
  magicFunction = (arr, old_index, new_index) => { 

    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;

  }

  cardDragEnd = (card, source, destination) => {
    if (this.state.is_answer) {
      return false;
    }

    this.props.showResult();
    
    let board = {};
    let temp = this.state.board;
    
    if (source.fromLaneId !== destination.toLaneId) {
      temp.lanes[--source.fromLaneId].cards.splice(source.fromPosition, 1);
      temp.lanes[--destination.toLaneId].cards.splice(destination.toPosition, 0, card);
      
      board = {
        lanes: [
          {
            id: 1,
            title: 'Source',
            cards: temp.lanes[0].cards,
          },
          {
            id: 2,
            title: 'Target',
            cards: temp.lanes[1].cards
          }
        ]
      };
    } else {
      temp.lanes[(source.fromLaneId-1)].cards = this.magicFunction(temp.lanes[(source.fromLaneId-1)].cards,source.fromPosition, destination.toPosition);
      board = {
        lanes: [
          {
            id: 1,
            title: 'Source',
            cards: temp.lanes[0].cards,
          },
          {
            id: 2,
            title: 'Target',
            cards: temp.lanes[1].cards
          }
        ]
      };
    }

    const data = {
      answer: board.lanes[1].cards.map((val)=>{ return val.id; }),
      correct: this.state.sortedItems.map((val)=>{ return val.id; })
    }
    
    this.props.submit(data, true);

    this.setState({
      board,
      resultObj: data
    });
  };

  updateCorrect = () =>{
    if (this.state.sortedItems.length !==0) {
      this.props.submit({'answer':[],'correct':this.state.sortedItems.sort(function(a, b){return a.index - b.index}).map((val)=>{ return val.id;})});
      this.setState({correctFlag:false,resultObj:{'answer':[],'correct':this.state.sortedItems.sort(function(a, b){return a.index - b.index}).map((val)=>{ return val.id;})}})  
    }
    
  } 

  stopTest = () =>{
    this.props.nextQuestion(this.state.resultObj);
  }

  static getDerivedStateFromProps(props, state) {

    let temp = [];
    if (props.data) {
      if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
        const { option } = props.data;
        temp = props.data.option.slice();
        let board = {
          lanes: [
            {
              id: 1,
              title: 'Source',
              cards: option.map((item) => {
                return {
                  id: item.id,
                  title: '',
                  description: item.options,
                  content: item.options
                }
              }),
            },
            {
              id: 2,
              title: 'Target',
              cards: []
            }
          ]
        };
                  
        if (document.querySelectorAll('div[data-rbd-draggable-id="lane-draggable-1"] > div > div:first-child').length > 0) {
          document.querySelector('div[data-rbd-draggable-id="lane-draggable-1"] > div > div:first-child').innerHTML ='<span>Source</span>';  
        }
        
        return {
          correctFlag: true,
          secondCount: 1200,
          questionId: props.data.id,
          reset: true,
          items: props.data.option,
          board: board,
          sortedItems: temp.sort(function(a, b){
            return a.index - b.index
          })
        };
      } else {
        if (!state.questionId) {
          return {
            correctFlag: true,
            questionId: props.data.id
          };
        }
      }
    }

    if (props.answer) {
      const {sortedItems } = state;
      let board = state.board;
      
      board.lanes[0] = {
        id: 1,
        title: 'Correct Order',
        cards: [
          ...sortedItems.map((item) => {
            return {
              id: item.id,
              title: '',
              description: item.options,
              content: item.options
            };
          })
        ]
      }


      // update Lane Headings. Not able to update in updating state.
      if(document.querySelector('div[data-rbd-draggable-id="lane-draggable-1"] > div > div:first-child')) {
        document.querySelector('div[data-rbd-draggable-id="lane-draggable-1"] > div > div:first-child').innerHTML ='<span>Correct Order</span>';
        document.querySelector('div[data-rbd-draggable-id="lane-draggable-2"] > div > div:first-child').innerHTML ='<span>Your Response</span>';
      }

      // mark correct pair.
      let elemArr = document.querySelectorAll('div[data-rbd-draggable-id="lane-draggable-2"] .source-target-list');
      const { correct, answer } = state.resultObj;
      for (var i = 0; i < elemArr.length; i++) {
        elemArr[i].classList.add('cross');
        elemArr[i].classList.add('active');
      }

      if (state.resultObj.answer) {
        for (var i = 0; i < answer.length-1; i++) {
          for (var j = 0; j < correct.length-1; j++) {
            if (correct.length > i+1) {
              if (answer[i]+'-'+answer[i+1] == correct[j]+'-'+correct[j+1] ) {
                elemArr[i].classList.remove('cross');
              }
            }
          }
        }
      }
      // correct pairing end.

      return {
        board: board
      }
    }

    return null;
  }

  render() {
    
    if (this.props.restart) {
      this.props.restartQue();
    }

    if (this.props.stopRecording) {
      this.stopTest();      
    }
    
    if (this.props.data) {
      if (this.state.correctFlag) {
        this.updateCorrect();  
      }
    }

    if (this.state.reset) {
      this.setState({reset:false,is_answer:false});
    }

    const { board, idealTimeExceed } = this.state;
    const { data, resultMode, answer, showResponse } = this.props;

    return (
      <div>
        {data && !resultMode &&
          <>
            <div className="reorder-board source-target-wrapper visibility-visible">
              {JSON.stringify(this.state.board) !== '{}' && (
                <Board
                  disableCardDrag={answer ? true : false}
                  disableLaneDrag
                  onCardDragEnd={this.cardDragEnd}
                  renderCard={({ content }, { removeCard, dragging }) => (
                    <div className="source-target-list">
                      <p>{content}</p>
                      {!answer &&
                        <img src={ArrowIcon1} alt="Image" />
                      }
                    </div>
                  )}
                >
                  {board}
                </Board>
              )}
            </div>

            <TimeUp
              isOpen={idealTimeExceed}
              toggle={this.idealTimeExceedToggle}
              title="Important !!!"
              message="You spent 2 minutes and 30 seconds. This is the ideal time to attempt these questions. Click Submit Now to submit response or Close to ignore."
              buttonLabel="Submit Now"
              showResponse={showResponse}
            />
          </>
        }
      </div>
    );

    // return (
    //   <>
    //   <Col xs={12}>
    //     <h4 className="infoText"></h4>
    //   </Col>
    //   <Col xs={12}><h5>{question}</h5></Col>
    //   <Col xs={12}>
    //     <h3 className="question">{this.props.data && this.props.data.question_mcq}</h3>
    //   </Col>

    //   <Col lg={12}>&nbsp;</Col>
    //   <Col lg={12}>
    //     {this.props.data && !this.props.resultMode &&
    //       <div className="reorder-board">
    //         <Board disableLaneDrag onCardDragEnd={this.cardDragEnd}>
    //           {board}
    //         </Board>
    //       </div>
    //     }
    //   </Col>
    //   <Col lg={12}>&nbsp;</Col>

    //   <Col lg={12}>
    //     {this.state.is_answer && !this.state.resultMode && <h3>Result: </h3>}
    //     {this.state.is_answer &&
    //       <div class="col-lg-6">
    //         <ul class="connectList">
    //           {this.state.board.lanes && this.state.board.lanes[1].cards.map((v,k)=>{
    //               return <li class="list-group-item1">{v.description}</li>
    //           })}
    //         </ul>
    //       </div>
    //     }
    //     {this.props.resultMode && <SortableList items={this.state.sortedItems}  />}
    //   </Col>
    //   </>
    // );
  }
}
export default Reorder;