import React, { useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Row, Col } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import API from "../api";
import { setNotifications } from "action";
import Notification from "components/Notification/Notification";
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import NotificationIcon from "../assets/images/images_new/notification.png"

function Notifications() {
  const [clicked, setClicked] = useState(null);
  const notifications = useSelector((state) => state.notifications);
  const dispatch = useDispatch();

  const handleClear = async (id) => {
    setClicked(id);
    let response = await API({
      url: "markNotificationAsRead",
      method: "POST",
      data: { id },
    });

    if (response.status === 200) {
      dispatch(setNotifications(response.data));
    } else {
      setClicked(null);
    }
  };

  return (
    <div className="content p-0">
      <DashboardHeader title="Notifications" />
      
      <div className="transation-page payment-successful outer-wrapper">
        <div className="successful-content">
          <img src={NotificationIcon} alt="No Notification" />
          <h2>No Notification</h2>
          <p>your updates and alerts would appear here</p>
          <div className="payment-btns">
            <a href="#" className="pay-btn dashboard-btn">Go To Dashboard</a>
            <a href="#" className="pay-btn manage-btn">Practice Material</a>
          </div>
        </div>
      </div>
      
      <Row>
        <Col xs={12}>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Notifications</CardTitle>
            </CardHeader>
            <CardBody>
              {notifications.map((notification) => {
                return (
                  <Notification
                    key={notification.id}
                    notification={notification}
                    clicked={clicked}
                    handleClear={handleClear}
                  />
                );
              })}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Notifications;
