import React from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	Button
} from "reactstrap";

export default function TimeUp({
	isOpen, toggle, showResponse,
	title, message, buttonLabel
}) {

	const submit = () => {
		toggle();
		showResponse();
	}

	return (
		<Modal
			isOpen={isOpen}
			className="text-translate-modal"
        >
			<ModalHeader
				className="text-translation"
				tag="div"
			>
				{title}
				<Button className="modalCloseBtn" onClick={toggle}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						width="24"
						height="24"
					>
						<path
							d="M18 6L6 18M6 6l12 12"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</Button>
			</ModalHeader>
			<ModalBody>
				<div className="row">
					<h2 className="translate-title">
						{message} <br /><br />
					</h2>

					<div className="text-center">
						<button className="outline-btn" onClick={submit}>{buttonLabel}</button>
					</div>
				</div>
			</ModalBody>
        </Modal>
	);
};