import React,{Component} from "react";
import {
  Col,
  Progress,
  Button,
  Input
} from "reactstrap";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import Dictaphone from "./Dictaphone.jsx";
import {base,s3base} from "api.js";
import NoMicrophone from "views/NoMicrophone.jsx";
import Sound from 'react-sound';
import getBlobDuration from 'get-blob-duration';
import MicRecorder from 'mic-recorder-to-mp3';
import "components/custom.css";
import PlaybackSpeedBtns from "components/PracticeMaterial/PlaybackSpeedBtns.jsx";
import SelectVoiceDropDown from "components/PracticeMaterial/SelectVoiceDropDown.jsx";


let Mp3Recorder = new MicRecorder({ bitRate: 128 });

class RepeatSentece extends Component {
  constructor(props) {
    super(props);
    this.state = {
      volume: 50,
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount:3,
      secondCount2:0,
      recordCount:0,
      recordId:1,
      intervalId:0,
      intervalId2:0,
      questionId:"",
      reset:false,
      soundStatus:true,
      soundPer:0,
      volume:90,
      trans:'',
      correctFlag:true,
      beepStatus:false,
      file:'',
      duration:0,
      playbackSpeed: 1,
      soundKey: 0,
      secondCount2Bckup: 0,
      question_audio: null,
      defaultSelectedVoice: null,
      micPermitted: null,
      browserAllowed: null,
      playStatus: Sound.status.STOPPED,
      
    };
  }

  changeRange = (e) => {
    this.setState({ volume: e.target.value });
    e.target.style.setProperty('--progress', `${(e.target.value / 100) * 100}%`);
  };


  componentDidMount() {
    // check browser. Only Chrome only.
    let chrome = /Chrome/.test(navigator.userAgent) && !/Edg/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    
    // mic access.
    navigator.mediaDevices.getUserMedia({ audio: true })
    .then(() => {
      // Permission Granted
      this.setState({
        isBlocked: false,
        micPermitted: true,
        browserAllowed: chrome
      }, () => {
        if (chrome) { // check if browser is chrome only. Not even any other variant of chromium browser i.e. edge, brave etc.
          this.startProcess();
        }
      });
    }).catch(() => {
      // Permission Denied
      this.setState({ isBlocked: true, micPermitted: false, browserAllowed: chrome });
    });

    // console.log(window.location.pathname.includes('practice'))
  }

  startProcess = () => {
    if (!this.props.answer) {

      // select audio.
      let question_audio = null;
      let defaultSelectedVoice = null;
      if ('question_audios' in this.props.data && this.props.data.question_audios.length > 0) {
        let x = parseInt(Math.random() * 6);

        const { question_audios } = this.props.data;
        question_audio = `${s3base}/${question_audios[x].value}`;
        defaultSelectedVoice = question_audios[x].label;
      } else {
        const { media_link } = this.props.data;
        question_audio = `${s3base}${media_link}`;
      }

      // var intervalId = setInterval(this.timer, 1000);
      this.setState({
        // intervalId,
        question_audio,
        defaultSelectedVoice
      }, () => {
        this.props.updateQuestionAudioUrlInPractice(this.state.question_audio);
      });
    }
  }

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
        clearInterval(state.intervalId);
  			clearInterval(state.intervalId2);
  			clearInterval(state.recordId);

        let question_audio = null;
        let defaultSelectedVoice = null;
        if ('question_audios' in props.data && props.data.question_audios.length > 0) {
          let x = parseInt(Math.random() * 6);

          const { question_audios } = props.data;
          question_audio = `${s3base}/${question_audios[x].value}`;
          defaultSelectedVoice = question_audios[x].label;
        } else {
          const { media_link } = props.data;
          question_audio = `${s3base}${media_link}`;
        }

  			return {
          correctFlag:true,
          recordCount:0,
          soundPer:0,
          isRecording:false,
          secondCount:3,
          secondCount2:0,
          questionId: props.data.id,
          blobURL:'',
          trans:'',
          duration:0,
          reset:true,
          soundStatus:true,
          intervalId2:0,
          question_audio,
          defaultSelectedVoice
        };
  		}else{
  			if (!state.questionId) {
  				return {correctFlag:true,questionId: props.data.id};
  			}
  		}
  	}


  }


  start = () => {
    //this.setState({ beepStatus:true});
    SpeechRecognition.startListening({ continuous: true, language: 'en-GB' });
    Mp3Recorder = new MicRecorder({ bitRate: 128 });
    Mp3Recorder.start()
        .then(() => {
          this.setState({ isRecording: true,blobURL:'',beepStatus:false});
        }).catch((e) => console.error(e));
  };

  handleBeepFinishedPlaying = () =>{
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      /*Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true,blobURL:'',beepStatus:false});
        }).catch((e) => console.error(e));*/
    }
  }

  stop = () => {
    if (!this.state.isBlocked && this.state.isRecording) {
      Mp3Recorder
        .stop()
        .getMp3()
        .then(async ([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob);
          let duration = await getBlobDuration(blobURL);
          let file = new File([blob], 'noname3.mp3', { type: blob.type });
          this.setState({ blobURL, isRecording: false,recordCount:100,file:file,duration:duration });
          SpeechRecognition.stopListening();
          this.props.submit({'data': file,'audio_text':this.state.trans, 'duration':duration, blob_url: blobURL },true);
          this.props.showResult();
        }).catch((e) => console.log(e));
    }
  };

  stopTest = async () => {
    if (!this.state.isBlocked && this.state.isRecording) {
      Mp3Recorder
        .stop()
        .getMp3()
        .then(async ([buffer, blob]) => {
          const blobURL = URL.createObjectURL(blob)
          let duration = await getBlobDuration(blobURL);
          //this.setState({ blobURL, isRecording: false,recordCount:100 });
          SpeechRecognition.stopListening();
          let file = new File([blob], 'noname3.mp3', { type: blob.type });
          this.props.nextQuestion({'data': file,'audio_text':this.state.trans,duration:duration });
          //this.props.submit({'data':file },true)
          //this.props.showResult();
        }).catch((e) => console.log(e));
    }else{
      this.props.nextQuestion({'data': this.state.file,'audio_text':this.state.trans, duration: this.state.duration });
    }
  };

  updateCorrect = () =>{
     this.props.submit({'data':'',duration:this.state.duration});
     this.setState({correctFlag:false})
  }

  setTrans = (a)=>{
    if (this.state.trans != a) {
      this.setState({trans:a})
    }

  }

	timer = () => {
		if (this.state.secondCount === 0) {
			clearInterval(this.state.intervalId);
      this.setState({
        playStatus: Sound.status.PLAYING
      })
		} else {
      this.setState({ secondCount: this.state.secondCount - 1 });
    }

	}

  timer1 = () => {
    if (this.state.secondCount2 === 0) {
      clearInterval(this.state.intervalId2);
      this.start();
      var recordId = setInterval(this.timerRecord, 1000);
      this.setState({'soundStatus':false,recordId: recordId});
    }else{
      if(this.state.secondCount2Bckup === 0) {
        this.setState({secondCount2Bckup: this.state.secondCount2});
      }
      this.setState({secondCount2: this.state.secondCount2 -1 });
    }

  }

  skip = () => {
    clearInterval(this.state.intervalId);
    this.setState({
      secondCount: 0,
      playStatus: Sound.status.PLAYING
    });
  };

  handleSongFinishedPlaying = () => {
    //var intervalId = setInterval(this.timer1, 1000);
    //this.setState({'soundStatus':false})
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      this.setState({
        soundStatus: false,
        playStatus: Sound.status.STOPPED
      });
      this.props.soundChecked();
     /* Mp3Recorder
        .start()
        .then(() => {
          this.setState({ isRecording: true,blobURL:'',soundStatus:false});
        }).catch((e) => console.error(e));*/
    }
    //this.start();
    //var recordId = setInterval(this.timerRecord, 1000);
    //this.setState({'soundStatus':false,recordId: recordId});
  }

  handleSongPlaying = (obj) => {
    this.setState({soundPer:obj.position*100/obj.duration});
  }

  loadingSound = (obj) => {
    let d = obj.duration/1000-(0.3);
    // if (obj.duration !== 0 && this.state.intervalId2 === 0){
    //   var intervalId = setInterval(this.timer1, 1000);
    //   this.setState({
  	// 	  soundStatus: true,
  	// 	  intervalId2: intervalId,
  	// 	  secondCount2: Math.ceil(d),
    //     secondCount2Bckup: Math.ceil(d)
  	//   });
    // }
  }

	timerRecord = () => {
		if (this.state.recordCount === 8) {
			clearInterval(this.state.recordId);
			this.stop();
		}
		this.setState({ recordCount: this.state.recordCount + 1 });
	}

  changeRange = (e) => {
      this.setState({volume: e.target.value});
  }

	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}

  changePlaybackSpeed = (speed) => {

    // Do not change anything once recording has started.
    const { secondCount2 } = this.state;
    
    if (secondCount2 > 0) {
      // added during new design implementation. 
      clearInterval(this.state.intervalId2);

      this.setState((prevState) => ({
        soundKey: prevState.soundKey + 1,
        playbackSpeed: speed,
        secondCount2: prevState.secondCount2Bckup,
        intervalId2: 0 // added during new design implementation.
      }));
    }
  }


  changeAudio = (selected) => {

    const { secondCount2 } = this.state;

    // Don't do anything if recording has started.
    if (secondCount2 > 0) {
      clearInterval(this.state.intervalId2);
      
      this.setState({
        intervalId2: 0
      }, () => {
        
        const { question_audios } = this.props.data;
        const selected_audio = question_audios.filter((audio, i) => {
          return audio.label === selected.label;
        });

        this.setState({
          question_audio: `${s3base}/${selected_audio[0].value}`
        });
      });
    }
  }

  playingQuestionSectionLabel = () => {

    const { isRecording, secondCount, soundStatus, blobURL } = this.state;
    let status = '';

    if (!isRecording) {
      if (secondCount !== 0 && soundStatus === true) {
        status = `Beginning in ${this.state.secondCount} seconds`;
      } else if (secondCount === 0 && blobURL === '') {
        status = 'Playing question audio...';
      } else {
        status = 'Question audio finished.'; // just any case.
      }
    } else {
      status = 'Question audio finished.';
    }
    return status;
  }

  showSkipAudioWaitButton = () => {
    let show_button = false;
    const { secondCount, blobURL, isRecording } = this.state;
    
    if (secondCount !== 0 && blobURL === '' && !isRecording) {
      show_button = true;
    }

    return show_button;
  }

  recordingLabel = () => {
    const { secondCount, secondCount2, blobURL, isRecording } = this.state;
    
    let label = '';
    if (secondCount2 !== 0) {
      label = `Recording starts in ${secondCount2} seconds...`;
    } else if (blobURL === '' && isRecording) {
      label = 'Recording audio...';
    } else if (blobURL !== '' && !isRecording) {
      label = 'Recording Finished.';
    }

    return label;
  }

  showStopRecordingButton = () => {
    let button = false;
    
    const { testMode } = this.props;
    const { isRecording } = this.state;
    
    if (!testMode && isRecording) {
      button = true;
    }

    return button;
  }

  showRecordedAudio = () => {

    const { blobURL } = this.state;
    const { testMode } = this.props;

    let recorded_audio = false;
    if (blobURL && !testMode) {
      recorded_audio = true;
    }

    return recorded_audio;
  }

  timer2 = () => {
    const { secondCount2, intervalId2 } = this.state;
    let state_obj = {};

    if (secondCount2 === 0) {
      clearInterval(intervalId2);
      this.start();

      const recordId = setInterval(this.timerRecord, 1000);
      state_obj = {
        soundStatus: false,
        recordId: recordId
      };

    } else if (secondCount2 > 0) {
      state_obj = {
        secondCount2: secondCount2 - 1
      };

      if (this.state.secondCount2Bckup === 0) {
        state_obj.secondCount2Bckup = secondCount2;
        // this.setState({secondCount2Bckup: this.state.secondCount2});
      }
    }

    this.setState(state_obj);
  }

  soundLoaded = (obj) => {
    const { secondCount } = this.state;
    // get duration of loaded audio.
    let duration = Math.ceil(obj.duration / 1000 - (0.3));

    let intervalId = setInterval(this.timer, 1000); // start audio play wait timer.
    let intervalId2 = setInterval(this.timer2, 1000); // start recording wait timer.
    
    let secondCount2 = (duration + 1) + secondCount; // +1 adding extra second. // +3 in audio duration.

    this.setState({
      intervalId,
      intervalId2,
      secondCount2,
      secondCount2Bckup: secondCount2,
      soundStatus: true
    });
  }


  render() {    

  	let question = "";
    if (this.props.restart) {
      this.props.restartQue();
    }
    if (this.props.stopRecording) {
      this.stopTest();
    }
  	if (this.props.data) {
      if (this.state.correctFlag) {
        this.updateCorrect();
      }
  		question = this.props.data.question;
  	}
  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		  this.setState({intervalId: intervalId,reset:false});
  	}
    if (this.state.isBlocked) {
      return (<NoMicrophone />);
    }

    const { currentQuestion, total_questions } = this.props;
    const { micPermitted, browserAllowed } = this.state;

    return (
      <>
        {micPermitted && browserAllowed ? (
          <>
            <Dictaphone
              setTrans={this.setTrans}
              status={this.state.reset || this.state.isRecording}
            />

            {this.state.question_audio !== null &&
              <Sound
                url={this.state.question_audio}
                autoLoad={true}
                playStatus={this.state.playStatus}
                onLoading={this.loadingSound}
                onLoad={this.soundLoaded}
                onPlaying={this.handleSongPlaying}
                volume={this.state.volume}
                onFinishedPlaying={this.handleSongFinishedPlaying}
                playbackRate={this.state.playbackSpeed}
                key={this.state.soundKey}
              />
            }

            <div className="pmw-tabs-section d-flex align-items-start pmw-outer-wrapper recorded-wrapper challenge-content-wrapper visibility-visible">
              <div className="recorded-section h-100 width-35">
                <div className="recorded-content">
                  <div className="row">
                    <div className="col-9">
                      <ul className="recorded-list">
                        <li>Status:</li>
                        <li>{this.playingQuestionSectionLabel()}</li>
                      </ul>
                    </div>
                    <div className="col-3">
                      {/*<span className="volume-label">Volume</span>*/}
                    </div>
                  </div>
                  <div className="recorded-audio-wrapper">
                    <div className="row">
                      <div className="recorded-audio-outer">
                        <Progress value={(this.state.soundPer)} striped />                                    
                        
                        <div className="volume-control-wrapper">
                          <span className="volume-icon">
                            <i class="fa fa-volume-up" aria-hidden="true"></i>
                          </span>
                          <div className="re-volume-wrapper">
                            <input
                              type="range"
                              className="volume-control"
                              min="0"
                              max="100"
                              step="1"
                              value={this.state.volume}
                              onInput={this.changeRange}
                              style={{
                                "--progress": `${(this.state.volume / 100) * 100}%`,
                              }}
                            /> 
                          </div>                        
                        </div>   
                      </div>
                    </div>

                    {/*<div className="challenge-content-dropdown clearfix">*/}
                    <div className="row">
                      <div className="col-11">
                        <div className="mt-1 clearfix">
                          {this.state.defaultSelectedVoice !== null &&
                            <div className="pull-left mr-2">
                              <SelectVoiceDropDown
                                voices={this.props.voices}
                                selected={this.state.defaultSelectedVoice}
                                changeAudio={this.changeAudio}
                              />
                            </div>
                          }
                          <div className="pull-left">
                            <PlaybackSpeedBtns
                              changePlaybackSpeed={this.changePlaybackSpeed}
                              playbackSpeed={this.state.playbackSpeed}
                            />
                          </div>

                          {this.showSkipAudioWaitButton() &&
                            <div className="btn-outer skip-btn-wrapper pull-right">
                              <a
                                href="#"
                                className="outline-btn skip-btn-new text-center"
                                onClick={this.skip}
                              >Start Audio</a>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="recorded-section h-100 width-35">
                <div className="row">
                  <div className="col-9">
                    <div className="recorded-content">
                      <ul className="recorded-list">
                        <li>Status:</li>
                        <li>{this.recordingLabel()}</li>
                      </ul>
                      <div className="recorded-audio-wrapper">
                        <Progress value={(this.state.recordCount*12.5)} striped />
                        {this.showStopRecordingButton() &&
                          <div className="btn-outer">
                            <a
                              href="javascript:void(0)"
                              className="outline-btn skip-btn-new text-center mt-2 visibility-visible"
                              onClick={this.stop}
                            >Stop Recording</a>
                          </div>
                        }
                        {this.showRecordedAudio() &&
                          <audio
                            controls
                            src={this.state.blobURL}
                            className="mt-2 visibility-visible"
                          ></audio>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="pmw-tabs h-100">
            {micPermitted === false &&
              <div className="alert alert-danger">No Microphone permissions granted. Please grant microphone permissions and reload the page.</div>
            }

            {browserAllowed === false &&
              <div className="alert alert-danger">The browser you are using is not supported. Please use Google Chrome.</div>
            }
          </div>
        )}
      </>
    );

    // return (
    //   <>
    //   <Dictaphone setTrans={this.setTrans} status={this.state.reset || this.state.isRecording} />
    //   <Col xs={12}>
    //   	<h4 className="infoText"></h4>
    //   </Col>
    //   <Col xs={12}>
    //   	<div className="col-lg-12 p-0 text-center">
    //     { !this.props.resultMode && <div> <div className="currentStatus">
    //         <div>Current Status:{this.state.soundStatus == true && this.state.secondCount !== 0 && !this.state.isRecording && ' Begining in '+this.state.secondCount+ ' seconds' }
    //         {this.state.secondCount == 0 && this.state.blobURL === '' && !this.state.isRecording && ' Playing' }

    //         <br></br>
    //         Volume
    //          <Input placeholder="Volume" type="range" min="0" defaultValue={this.state.volume} step="1" onInput={(e) => this.changeRange(e)} max="100"  />
    //          <br></br>
    //         <Progress color="info" value={(this.state.soundPer)} />
    //         {!this.props.testMode && (
    //           <div className="d-flex justify-content-between">
    //             <div className='stop-btn'>
    //               {
    //                 this.state.secondCount !== 0 &&
    //                 this.state.blobURL === '' &&
    //                 !this.state.isRecording &&
    //                   <Button color="success" onClick={this.skip} size="sm">Skip</Button>
    //               }
    //             </div>

    //             <div className="stop-btn clearfix">
    //               <div className="select-voice pull-left">
    //                 {this.state.defaultSelectedVoice !== null &&
    //                   <SelectVoiceDropDown
    //                     voices={this.props.voices}
    //                     selected={this.state.defaultSelectedVoice}
    //                     changeAudio={this.changeAudio}
    //                   />
    //                 }
    //               </div>
    //               <div className="select-speed pull-right">
    //                 <PlaybackSpeedBtns
    //                   changePlaybackSpeed={this.changePlaybackSpeed}
    //                   playbackSpeed={this.state.playbackSpeed}
    //                 />
    //               </div>
    //             </div>

    //           </div>
    //         )}
    //         </div>

    //       </div>
		//   <div className="audio-box">
    //     <h4>Recorded Answer</h4>
    //     <p><strong>Current status:</strong></p>
    //     <p> { this.state.blobURL === '' && this.state.isRecording?"Recording started": "" } </p>
    //     <p> { this.state.secondCount == 0 && this.state.secondCount2 !== 0 && 'Recording starts in '+this.state.secondCount2+ ' seconds'  }</p>

    //     <hr></hr>
    //     {/*this.state.isRecording && "Recording Time : "+this.state.recordCount*/}
    //     {!this.props.testMode && this.state.isRecording && <div className='stop-btn'><Button color="success" onClick={this.stop} size="sm">Stop</Button></div>}
    //     <hr></hr>
    //     <Progress color="info" value={(this.state.recordCount*12.5)} />
    //     <div className="audo-line"><span className="progress-bar-fill-Read intro"></span></div>
    //   </div> </div>}
    //   <Col xs={12}>
    //     {!this.props.resultMode && this.props.data && this.state.soundStatus && this.state.secondCount === 0 &&
    //     <Sound
    //       url={this.state.question_audio}
    //       playStatus={Sound.status.PLAYING}
    //       onPlaying={this.handleSongPlaying}
    //       volume={this.state.volume}
    //       onLoading={this.loadingSound}
    //       onFinishedPlaying={this.handleSongFinishedPlaying}
    //       playbackRate={this.state.playbackSpeed}
    //       key={this.state.soundKey}
    //     />}
    //   </Col>
		//   <Col xs={12}>
		//   	{ this.state.blobURL && !this.props.testMode && <audio src={this.state.blobURL} controls="controls" />}
		//   </Col>
		// </div>
    //   </Col>
    //   <Col xs={12}><h5>{question}</h5></Col>
    //   {!this.props.resultMode && this.state.beepStatus && <Sound
    //               url={s3base + "/ptedata/ptemedia/beep.mp3"}
    //               playStatus={Sound.status.PLAYING}
    //               onFinishedPlaying={this.handleBeepFinishedPlaying}
    //             />}
    //   </>
    // );
  }
}
export default RepeatSentece;
