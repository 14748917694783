import React,{Component} from "react";
import {
  Col
} from "reactstrap";
import "components/custom.css";
import {arrayMove} from 'react-sortable-hoc';
import reactStringReplace from 'react-string-replace';
import ReactHtmlParser from 'react-html-parser';
import WordDefinitionModal from 'components/PracticeMaterial/PopUps/WordDefinitionModal';
import ModalPopup from "components/PopUps/Modal";
import FillInTheBlankExplanation from "components/PracticeMaterial/PopUps/FillInTheBlankExplanation";
import API from "api.js";
import TimeUp from "components/PracticeMaterial/PopUps/TimeUp";


const IDEAL_TIME = 150; // SECONDS.
class ReadingBlank extends Component {

  constructor(props) {
    super(props);
    this.state = {
      question:"",
      questionId:"",
      reset:false,
      correctFlag:true,
      resultObj:{},
      answerData:{
        answer: [],
        correct: [],
         selectedAnswer:""
      },
      wordDefinitionModalIsOpen: false,
      modalDestroy: true,
      selectedWord: "",
      isModalOpen:false,
      answer_explaination_key:"",
      explanationModal: false,
      idealTime: IDEAL_TIME,
      idealTimeInterval: null,
      idealTimeExceed: false
    };
    this.questionPara = "";
  }

  componentDidMount = () => {

    // start countdown.
    const idealTimeInterval = setInterval(this.countDownToIdealTime, 1000);
    this.setState({
      idealTimeInterval
    });
  }
  componentWillUnmount = () => {}

  countDownToIdealTime = () => {
    const { idealTime, idealTimeInterval } = this.state;
    const { answer } = this.props;

    if(answer) {
      clearInterval(idealTimeInterval); // stop timer when submitted.
    } else {
      let state_obj = {};

      if (idealTime > 0) {
        state_obj.idealTime = (idealTime - 1);
      } else {
        clearInterval(idealTimeInterval);
        state_obj.idealTimeExceed = true;
      }
      this.setState(state_obj);
    }
  }
  
  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState({
      items: arrayMove(this.state.items, oldIndex, newIndex),
    });
  };

  allowDrop(event) {
    event.preventDefault();
  }

  stopTest = () =>{
    this.props.nextQuestion(this.state.resultObj);
  }

  drop1(event) {

    const { answer } = this.props;

    this.props.showResult();
    event.preventDefault();
    
    // all correct responses.
    const correct_key_data = this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; });
    
    let data = event.dataTransfer.getData("Text");
    let no = event.dataTransfer.getData("no");
    let item_text = data.split(/item-(.*)/s)[1];

    let temp = event.target.innerHTML;
    let tempData = this.state.answerData.answer.slice();

    if(temp === "") {
      // in case where user left blank empty.

      event.target.innerHTML = document.getElementById(data).innerHTML;
      // event.target.innerHTML = item_text;
      document.getElementById(data).innerHTML = temp;
      let key = document.getElementById(event.target.id).getAttribute("no");
      let tempData = this.state.answerData.answer.slice();
      tempData[key] = event.target.innerHTML;

      this.props.submit({
        answer: this.gatherAnswer(),
        correct: correct_key_data
      }, true);

    } else {
      // case where user blank is not empty.

      let tempData = this.state.answerData.answer.slice();
      event.target.innerHTML = document.getElementById(data).innerHTML;
      document.getElementById(data).innerHTML = temp;
      let key = document.getElementById(event.target.id).getAttribute("no");

      tempData[key] = event.target.innerHTML;

      this.props.submit({
        answer: this.gatherAnswer(),
        correct: correct_key_data
      }, true);
    }

    let prepared_data = {
      answer: this.gatherAnswer(),
      correct: correct_key_data
    };

    this.setState({
      resultObj: prepared_data,
      answerData: prepared_data
    });
  }

  gatherAnswer = () => {
    const container = document.querySelectorAll('.response')[0];
    const blanks = container.querySelectorAll(".span1");

    let values = [];

    // for compatibility purpose: with older method.
    for (let k = 0; k < (blanks.length * 2); k++) {
      if (k%2 === 0) {
        values[k] = undefined;
      } else {
        values[k] = blanks[parseInt(k/2)].innerHTML;
      }
    }
    return values;
    // return container.innerHTML.replace(/<[^>]*>?/gm, 'x_x').split('x_x').map(function(i,x){ if(x%2 !=0) return i; })
  }

  updateCorrect = () => {
    this.props.submit({'answer':[],'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; })});
    this.setState({correctFlag:false,resultObj:{'answer':[],'correct':this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; })}})
  }

  drop(event) {
    event.preventDefault();
    var data = event.dataTransfer.getData("Text");
    var no = event.dataTransfer.getData("no");
    var temp = event.target.innerHTML;
    event.target.innerHTML = document.getElementById(data).innerHTML;
    document.getElementById(data).innerHTML = temp;
  }

  showAnswer = () => {
    if (!this.props.resultMode) {
      let elem = document.querySelectorAll('.checkedSign');
      for (var i = 0; i <= elem.length-1; i++) {
        let id = elem[i].dataset.id;

        if (elem[i].dataset.text.trim() == document.getElementById('item-'+id).textContent.trim()) {
          elem[i].innerHTML= '<i class="fa fa-check" style="font-size:32px;color:green"></i>';
        }else{
          elem[i].innerHTML = `
          <i class="fa fa-times" style="font-size:32px;color:red"></i>
        `;
        }
      }
    }
  }

  dragStart(event) {
    event.dataTransfer.setData("Text", event.target.id);
  }
  
  onDrop(data) {
    console.log(data);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data) {
      if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
        document.querySelectorAll('.span1').forEach((t) => { t.innerHTML="" } );
        document.querySelectorAll('.span2').forEach((t) => { t.style.display="inline-block" } );
        document.querySelectorAll('.span2').forEach((t) => { t.innerHTML=t.getAttribute('txt') } );
        return {correctFlag:true,questionId: props.data.id,answerData:{'answer':[],'correct':[]},optionList:props.data.option};
      }else{
        if (!state.questionId) {
          return {correctFlag:true,questionId: props.data.id,optionList:props.data.option};
        }
      }
    }
  }

  openCloseWordDefinitionModal = (status, word="") => {
    this.setState({
      wordDefinitionModalIsOpen: status
    }, () => {
      if (!status) {
        setTimeout(() => {
          this.setState({
            modalDestroy: true,
            selectedWord: ""
          });
        }, 500);
      } else {
        this.setState({
          modalDestroy: false,
          selectedWord: word
        });
      }
    });
  }

  wordClickHandle = (e, word) => {
    e.preventDefault();
    this.openCloseWordDefinitionModal(true, word);
  }
  
  toggleModal = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  renderQuestionParagraph = () => {
    let html = [];
    this.questionPara.map((item, i) => {
      if (typeof item === "string") {
        item.split(" ").map((word, i) => {
          html.push(
            reactStringReplace(word, word, (match, i, o) => (
              <span
                key={i}
                className="question-para-word speach-text"
                onClick={(e) => this.wordClickHandle(e, word)}
              >{word}</span>
            ))
          );
          html.push(" ");
        });
      } else {
        html.push(item);
      }
    });

    return html.map((item, i) => {
      return (
        <React.Fragment key={i}>
          {item}
        </React.Fragment>
      )
    });
  }

  toggleModalExplanation = () => {
    this.setState({
      explanationModal: !this.state.explanationModal
    });
  }

  answerStatusClass = (item, correct) => {
    const { answer } = this.props;

    let class_name = "";
    if (answer) {
      const { answer } = this.state.answerData;
      const user_selected = answer[item] ? answer[item].trim() : answer[item];

      if (user_selected === "" || user_selected === undefined) {
        class_name = "empty cursor-pointer clearfix"; // left blank by user.
      } else if (user_selected === correct.trim()) {
        class_name = "correct"; // correct response.
      } else {
        class_name = "wrong cursor-pointer clearfix"; // wrong repsonse.
      }
    }

    return class_name;
  }

  blankClickHnadler = (e, correct, item) => {
    const { answer } = this.props;

    if (answer) {
      const { answer } = this.state.answerData;
      const user_selected = answer[item] ? answer[item].trim() : answer[item];

      if (user_selected !== correct.trim()) {
        this.setState({
          answer_explaination_key: correct
        }, () => {
          this.toggleModalExplanation();
        });
      }
    }
  }

  idealTimeExceedToggle = () => {
    const { idealTimeExceed } = this.state;

    this.setState({
      idealTimeExceed: !idealTimeExceed
    });
  }

  fillBlanks = (item) => {
    const { answerData: { answer, correct } } = this.state;

    let response = "";
    
    if (answer[item] === "") {
      response = correct[Math.round(item/2)-1];
      response = ReactHtmlParser(`${response} <span class="pull-right">&nbsp;?</span>`);
    
    } else {
      response = answer[item];
      if (correct[Math.round(item/2)-1] !== answer[item]) {
        response = ReactHtmlParser(`${answer[item]} <span class="pull-right">&nbsp;?</span>`);
      }

    }

    return response;
  }

  showCorrectAlongWithWrong = (item, correct) => {
    let html = "";

    if (this.props.answer) {
      const { answer } = this.state.answerData;
      const user_selected = answer[item] ? answer[item].trim() : answer[item];

      if (user_selected === "" || user_selected === undefined) {
        // do nothing. empty
      } else if (user_selected === correct.trim()) {
        // do nothing. correct
      } else {
        // wrong response
        html = (
          <span className="wrong-answer-correction">
            <i className="fa fa-long-arrow-right"></i>
            <span className="span11 correct-inverse text-center">{correct.trim()}</span>
          </span>
        );
      }
    }

    return html;
  }
  
  render() {
    const { isModalOpen, modalContent, idealTimeExceed } = this.state;
    const { answer, showResponse } = this.props;

    let question ="";
    let optionList =[];
    let correct =[];
    if (this.props.restart) { 
      this.props.restartQue();
    }
    if (this.props.answer) {
      this.showAnswer();
    }
    if (this.props.stopRecording) {
      this.stopTest();
    }
    
    if (this.props.data) {
      if (this.state.correctFlag) {
        this.updateCorrect();
      }
      
      question = this.props.data.question;
      correct = question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter(function(i,x){ if(x%2 !=0) return i; });
      this.questionPara = reactStringReplace(question.replace(/<[^>]*>?/gm, 'x_x').replace(/x_x(.*?)x_x/g,''), /(__add_blank__)/g, (match, i, o) => (
        <>
          {!this.props.answer ? (
            <span
              className={`span1 ${this.answerStatusClass(i, correct[(i-1)/2])}`}
              onDrop={this.drop1.bind(this)}
              draggable={answer ? "false" : "true"}
              onDragStart={this.dragStart}
              no={i}
              id={'item-'+i}
              onDragOver={this.allowDrop}
              onClick={(e) => this.blankClickHnadler(e, correct[(i-1)/2], i)}
            ></span>
          ) : (
            <>
              <span
                className={`span1 ${this.answerStatusClass(i, correct[(i-1)/2])}`}
                onClick={(e) => this.blankClickHnadler(e, correct[(i-1)/2], i)}
              >{this.fillBlanks(i)}</span>
              {this.showCorrectAlongWithWrong(i, correct[(i-1)/2])}
            </>
          )}
        </>
      ));
      optionList = this.props.data.option;
    }

    if (this.state.reset) {
      document.querySelectorAll('.span1').forEach((t) => { t.innerHTML="" } );
    }

    return (
      <div className="visibility-visible">
        {!this.props.resultMode && !this.props.testMode &&
          <Col xs={12}>
            <div className="row shadowing">
              <div className="word-definition">
                {!this.state.modalDestroy &&
                  <WordDefinitionModal
                    isOpen={this.state.wordDefinitionModalIsOpen}
                    toggle={this.openCloseWordDefinitionModal}
                    word={this.state.selectedWord}
                    languages={this.props.languages}
                  />
                }
              </div>
            </div>
          </Col>
        }

        {this.state.explanationModal &&
          <FillInTheBlankExplanation
            isOpen={this.state.explanationModal}
            title={`${this.state.answer_explaination_key.charAt(0).toUpperCase()}${this.state.answer_explaination_key.slice(1)}`}
            // data={this.props.option_explaination?this.props.option_explaination[this.state.answer_explaination_key.toLowerCase()]:'Fetching Definitions...'}
            data={this.props.option_explaination}
            keyName={this.state.answer_explaination_key}
            closeModal={this.toggleModalExplanation}
          />
        }

        <TimeUp
          isOpen={idealTimeExceed}
          toggle={this.idealTimeExceedToggle}
          title="Important !!!"
          message="You spent 2 minutes and 30 seconds. This is the ideal time to attempt these questions. Click Submit Now to submit response or Close to ignore."
          buttonLabel="Submit Now"
          showResponse={showResponse}
        />


        <div className="custom-box">
          <div className="ice-storm-form response question-text">
            {this.renderQuestionParagraph()}
          </div>
        </div>
        <div className="custom-box">
          <ul className="ice-storm-list">
            {this.props.data && !this.props.resultMode && this.state.optionList.map((item, i) => {
              return (
                <li key={i}>
                  <span
                    draggable={answer ? "false" : "true"}
                    key={i}
                    no="0"
                    onDrop={this.drop.bind(this)}
                    onDragOver={this.allowDrop}
                    onDragStart={this.dragStart}
                    className="span2"
                    txt={item.options.trim()}
                    id={`item-${item.options.trim()}`}
                  >
                    {item.options.trim()}
                  </span>
                </li>
              )}
            )}
          </ul>
        </div>
      </div>
    );

    // return (
    //   <>
    //     <Col xs={12}>
    //       <h4 className="infoText"></h4>
    //     </Col>
    //     <Col xs={12}>
    //       <h5 className="response">
    //         {this.props.data && !this.props.resultMode && this.renderQuestionParagraph()}
    //       </h5>
    //     </Col>
    //     <Col xs={12}>{this.props.data && this.props.resultMode && <h4>Correct answer</h4>}<h5>{this.props.data && this.props.resultMode && ReactHtmlParser(question.replace(/<span[^>]*>?/g, '<b class="correct">').replace(/<[^>]*span>?/g, '</b>&nbsp;').replace(/__add_blank__/g, '')) }</h5></Col>
    //     <Col xs={12}>{this.props.data && this.props.resultMode && <h4>Your response</h4>}<h5>{this.props.data && this.props.resultMode && ReactHtmlParser(question.replace(/<span[^>]*>?/g, function(v,k){ return '<b class="selected-option">';}).replace(/<[^>]*span>?/g, '</b>&nbsp;').replace(/__add_blank__/g, '')) }</h5></Col>
    //     <Col xs={12}>
    //       <h3 className="question">{this.props.data && this.props.data.question_mcq}</h3>
    //     </Col>
    //     <Col lg={12} className="anwrsOptions">
    //       {this.props.data && !this.props.resultMode && this.state.optionList.map((item, i) => {
    //         return (
    //           <span
    //             draggable="true"
    //             key={i}
    //             no="0"
    //             onDrop={this.drop.bind(this)}
    //             onDragOver={this.allowDrop}
    //             onDragStart={this.dragStart}
    //             className="span2"
    //             txt={item.options.trim()}
    //             id={'item-'+item.options.trim()}
    //           >{item.options.trim()}</span>
    //         )}
    //       )}
    //     </Col>
    //     <Col lg={12}>&nbsp;</Col>

    //     {!this.props.resultMode && !this.props.testMode &&
    //       <Col xs={12}>
    //         <div className="row shadowing">
    //           <div className="word-definition">
    //             {!this.state.modalDestroy &&
    //               <WordDefinitionModal
    //                 isOpen={this.state.wordDefinitionModalIsOpen}
    //                 toggle={this.openCloseWordDefinitionModal}
    //                 word={this.state.selectedWord}
    //                 languages={this.props.languages}
    //               />
    //             }
    //           </div>
    //         </div>
    //       </Col>
    //     }
    //     {isModalOpen && (
    //       <ModalPopup
    //         status={isModalOpen}
    //         toggle={this.toggleModal}
    //         showCloseBtn={true}
    //         render={(toggle) => <span>
    //               <h2 className="modal-header modal-title">{
    //               `${this.state.answer_explaination_key.charAt(0).toUpperCase()}${this.state.answer_explaination_key.slice(1)}`
    //               }</h2>
    //           <p className="mb-2 definition-text">
    //             {this.state.option_explaination?this.state.option_explaination[this.state.answer_explaination_key.toLowerCase()]:'Fetching Definitions...'}
    //             </p>
    //         </span>}
    //       />
    //     )}

    //     {this.state.explanationModal &&
    //       <FillInTheBlankExplanation
    //         isOpen={this.state.explanationModal}
    //         title={`${this.state.answer_explaination_key.charAt(0).toUpperCase()}${this.state.answer_explaination_key.slice(1)}`}
    //         // data={this.props.option_explaination?this.props.option_explaination[this.state.answer_explaination_key.toLowerCase()]:'Fetching Definitions...'}
    //         data={this.props.option_explaination}
    //         keyName={this.state.answer_explaination_key}
    //         closeModal={this.toggleModalExplanation}
    //       />
    //     }
      
    //   </>
    // );
  }
}

export default ReadingBlank;
