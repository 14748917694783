import React,{Component} from "react";

// reactstrap components
import {
  Col,
  Progress,
  Label,
  Button,
  Input
} from "reactstrap";
import {base,s3base} from "api.js";
import { Checkbox } from '@material-ui/core';
import Sound from 'react-sound';
// core components
import "components/custom.css";
import ReactHtmlParser from 'react-html-parser';
import reactStringReplace from 'react-string-replace';
import PlaybackSpeedBtns from "components/PracticeMaterial/PlaybackSpeedBtns";
import SelectVoiceDropDown from "components/PracticeMaterial/SelectVoiceDropDown.jsx";

class ListeningBlank extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount:10,
      recordCount:0,
      recordId:1,
      intervalId:0,
      questionId:"",
      reset:false,
      soundStatus:true,
      selectedValue:'',
      soundPer:0,
      volume:90,
      correctFlag:true,
      resultObj:{},
      stopped: false,
      playbackSpeed: 1,
      soundKey: 0,
      question_audio: null,
      defaultSelectedVoice: null
    };
  }


  adjustWidth = (input) => {
    if (!input) return;  
    const minWidth = 100;
    const tempSpan = document.createElement("span");
    tempSpan.style.visibility = "hidden";
    tempSpan.style.position = "absolute";
    tempSpan.style.whiteSpace = "pre";
    tempSpan.style.font = window.getComputedStyle(input).font;
    tempSpan.textContent = input.value || input.placeholder;
    
    document.body.appendChild(tempSpan);
    const newWidth = tempSpan.offsetWidth + 25;
    input.style.width = `${Math.max(newWidth, minWidth)}px`;
  
    document.body.removeChild(tempSpan);
  };
  


  componentDidMount() {
    if (this.inputRef) {
      this.adjustWidth(this.inputRef);
    }
    if (!this.props.answer) {
      // select audio.
      let question_audio = null;
      let defaultSelectedVoice = null;

      if ('question_audios' in this.props.data && this.props.data.question_audios.length > 0) {
        let x = parseInt(Math.random() * 6);

        const { question_audios } = this.props.data;
        question_audio = `${s3base}/${question_audios[x].value}`;
        defaultSelectedVoice = question_audios[x].label;
      } else {
        const { media_link } = this.props.data;
        question_audio = `${s3base}${media_link}`;
      }

      var intervalId = setInterval(this.timer, 1000);
      this.setState({
        intervalId,
        question_audio,
        defaultSelectedVoice
      }, () => {
        this.props.updateQuestionAudioUrlInPractice(this.state.question_audio);
      });
    }
  }
  componentDidUpdate() {
    if (this.inputRef) {
      this.adjustWidth(this.inputRef);
    }
  }

  stopTest = () =>{
    this.props.nextQuestion(this.state.resultObj);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data) {
      if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
        clearInterval(state.intervalId);
        clearInterval(state.recordId);

        let question_audio = null;
        let defaultSelectedVoice = null;
        if ('question_audios' in props.data && props.data.question_audios.length > 0) {
          let x = parseInt(Math.random() * 6);

          const { question_audios } = props.data;
          question_audio = `${s3base}/${question_audios[x].value}`;
          defaultSelectedVoice = question_audios[x].label;
        } else {
          const { media_link } = props.data;
          question_audio = `${s3base}${media_link}`;
        }

        [...document.querySelectorAll('.blankBox')].map((q) => { q.value=""; });
        return {
          correctFlag: true,
          recordCount: 0,
          soundPer: 0,
          isRecording: false,
          secondCount: 10,
          questionId: props.data.id,
          blobURL: '',
          reset: true,
          soundStatus: true,
          question_audio,
          defaultSelectedVoice
        };
      }else{
        if (!state.questionId) {
          return {correctFlag:true,questionId: props.data.id};
        }
      }

      }
  }

  timer = () => {
    if (this.state.secondCount === 1) {
      clearInterval(this.state.intervalId);

    }
    this.setState({ secondCount: this.state.secondCount -1 });
  }

  handleSongFinishedPlaying = () => {
    this.setState({'soundStatus':false});
    this.props.soundChecked();

  }



  timerRecord = () => {
    if (this.state.recordCount === 40) {
      clearInterval(this.state.recordId);
      this.stop();
    }
    this.setState({ recordCount: this.state.recordCount + 1 });
  }

  handleChange = (event) => {
    this.props.showResult();
    let arr = [];
    [...document.querySelectorAll('.blankBox')].map((q) => { arr.push(q.value); });
    let correct = this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter((v,k) => { if (k%2 == 1) { return v.trim(); } });
    this.props.submit({'answer':arr,'correct':correct},true);
    this.setState({resultObj:{'answer':arr,'correct':correct}})
  };

  updateCorrect = () =>{
    let correct = this.props.data.question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter((v,k) => { if (k%2 == 1) { return v.trim(); } });
    this.props.submit({'answer':[],'correct':correct});
    this.setState({correctFlag:false,resultObj:{'answer':[],'correct':correct}});
  }

  showAnswer = () => {
      if (!this.props.resultMode) {
        let elem = document.querySelectorAll('.checkedSign');
        for (var i = 0; i <= elem.length-1; i++) {
          let id = elem[i].dataset.id;

          if (elem[i].dataset.text.trim() == document.getElementById(id).value.trim()) {
            elem[i].innerHTML= '<i class="fa fa-check" style="font-size:32px;color:green"></i>';
          }else{
            elem[i].innerHTML = '<i class="fa fa-times" style="font-size:32px;color:red"></i>';

          }
        }
      }

  }

  componentWillUnmount = () => {
    clearInterval(this.state.intervalId);
  }

  handleSongPlaying = (obj) => {
    this.setState({soundPer:obj.position*100/obj.duration});
  }

  changeRange = (e) => {
      this.setState({volume: e.target.value});
  }

  skip = () => {
    clearInterval(this.state.intervalId);
    this.setState({secondCount:0});
  };

  changePlaybackSpeed = (speed) => {
    this.setState((prevState) => ({
      soundKey: prevState.soundKey + 1
    }));
    this.setState({playbackSpeed: speed});
  }


  changeAudio = (selected) => {
    clearInterval(this.state.intervalId2);
    
    this.setState({
      intervalId2: 0
    }, () => {
      
      const { question_audios } = this.props.data;
      const selected_audio = question_audios.filter((audio, i) => {
        return audio.label === selected.label;
      });

      this.setState({
        question_audio: `${s3base}/${selected_audio[0].value}`
      });
    });
  }

  playingQuestionSectionLabel = () => {
    const { isRecording, secondCount, soundStatus, blobURL } = this.state;
    let status = '';

    if (!isRecording) {
      if (secondCount !== 0 && soundStatus === true) {
        status = `Beginning in ${this.state.secondCount} seconds`;
      } else if (secondCount === 0 && soundStatus) {
        status = 'Playing question audio...';
      } else {
        status = 'Question audio finished.'; // just any case.
      }
    } else {
      status = 'Question audio finished.';
    }
    return status;
  }

  showSkipAudioWaitButton = () => {
    const { secondCount, blobURL, isRecording } = this.state;
    const { testMode } = this.props;

    let button = false;
    if (!testMode && secondCount !== 0 && blobURL === '' && !isRecording) {
      button = true;
    }

    return button;
  }

  playAudio = () => {
    const { data, resultMode } = this.props;
    const { soundStatus, secondCount } = this.state;

    let audio = false;
    if (data && !resultMode && soundStatus && secondCount === 0) {
      audio = true;
    }

    return audio;
  }

  renderAnswer = (response_index) => {
    const { answer, correct } = this.state.resultObj;
    const i = (response_index - 1) / 2;
    const correct_answer = correct[i];
    const user_answer = answer[i];

    let fill_text = "";
    if (user_answer === "") {
      fill_text = correct_answer;
    
    } else if (user_answer === correct_answer) {
      fill_text = user_answer;
    
    } else { // this is extran condition in case we want to render question mark with wrong answer.
      fill_text = user_answer;
    
    }

    return fill_text;
  }

  answerStatusClass = (response_index) => {

    let class_name = "";
    if (this.props.answer) {
      const { answer, correct } = this.state.resultObj;
      
      const i = (response_index - 1) / 2;
      const correct_answer = correct[i].trim();
      const user_answer = answer[i];

      // const user_selected = answer[item] && answer[item].trim();

      if (user_answer === "") {
        class_name = "empty cursor-pointer"; // left blank by user.
      } else if (user_answer.trim() === correct_answer.trim()) {
        class_name = "correct"; // correct response.
      } else {
        class_name = "wrong q-mark cursor-pointer"; // wrong repsonse.
      }
    }

    return class_name;
  }


  showCorrectAlongWithWrong = (index) => {
    let html = "";

    if (this.props.answer) {
      const { answer, correct } = this.state.resultObj;
      const i = (index - 1) / 2;
      const correct_answer = correct[i].trim();
      const user_answer = answer[i];

      if (user_answer !== "" && user_answer.trim() !== correct_answer.trim()) {
        html = (
          <span className="wrong-answer-correction">
            <i className="fa fa-long-arrow-right"></i>
            <span className="span11 correct-inverse text-center">{correct_answer}</span>
          </span>
        );
      }
    }

    return html;
  }

  render() {
    let question ="";
    let correct ="";
    if (this.props.restart) {
      this.props.restartQue();
    }

    if (this.props.answer) {
      this.showAnswer();
    }

    if (this.props.stopRecording) {
      this.stopTest();
    }


    /**
    * IMPORTANT
    * 1. in case of timeout secondCount condition will work.
    * 2. in case of next button clicked by user ELSE IF condition will work.
    */
    // console.log('secondcount::', this.props.secondCount);
    // if (!this.state.stopped && this.props.secondCount === 1) {
    //   console.log('secondcount == 1');
    //   this.setState({ stopped: true }, () => {
    //     console.log('Stopping test by secondCount');
    //     this.stopTest();
    //   });
    // } else if (!this.state.stopped && this.props.stopRecording) {
    //   console.log('stopRecording');
    //   this.setState({ stopped: true }, () => {
    //     console.log('Stopping test by stopRecording');
    //     this.stopTest();
    //   });
    // }
    /** ----------------------------------------------------------------- */

    if (this.props.data) {
      question = this.props.data.question;
      correct = question.replace(/<[^>]*>?/gm, 'x_x').split('x_x').filter((v,k) => { if (k%2 == 1) { return v.trim(); } });
      if (this.state.correctFlag) {
        this.updateCorrect();
      }
    }
    if (this.state.reset) {
      var intervalId = setInterval(this.timer, 1000);
      this.setState({intervalId: intervalId,reset:false});
    }

    const { data, resultMode, answer } = this.props;


    return (
      <>

        {this.playAudio() &&
          <Sound
            url={this.state.question_audio}
            playStatus={answer ? Sound.status.STOPPED : Sound.status.PLAYING}
            onFinishedPlaying={this.handleSongFinishedPlaying}
            onPlaying={this.handleSongPlaying}
            volume={this.state.volume}
            playbackRate={this.state.playbackSpeed}
            key={this.state.soundKey}
          />
        }

        <div className="pmw-tabs-section d-flex align-items-start pmw-outer-wrapper recorded-wrapper challenge-content-wrapper visibility-visible">
          <div className="recorded-section h-100 width-25"></div>
          <div className="recorded-section h-100 width-50">
            <div className="recorded-content">
              <div className="row">
                <div className="col-8">
                  <ul className="recorded-list">
                    <li>Status:</li>
                    <li>{this.playingQuestionSectionLabel()}</li>
                  </ul>
                </div>

                <div className="col-4">
                  {/*<span className="volume-label">Volume</span>*/}
                </div>
              </div>
              
              <div className="recorded-audio-wrapper">
                <div className="mt-3 clearfix">
                  <div className="row">
                    <div className="col-8">
                      <Progress value={(this.state.soundPer)} striped />
                    </div>
                    <div className="col-4">
                      <div className="volume-control-wrapper">
                        <span className="volume-icon">
                          <i className="fa fa-volume-up" aria-hidden="true"></i>
                        </span>
                        <div className="re-volume-wrapper">
                          <input
                            type="range"
                            placeholder="Volume"
                            className="volume-control"
                            min="0"
                            max="100"
                            step="1"
                            defaultValue={this.state.volume}
                            onInput={this.changeRange}
                            style={{
                              "--progress": `${(this.state.volume / 100) * 100}%`,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-8">

                      {this.state.defaultSelectedVoice !== null &&
                        <div className="mt-2 mr-2 pull-left visibility-visible">
                          <SelectVoiceDropDown
                            voices={this.props.voices}
                            selected={this.state.defaultSelectedVoice}
                            changeAudio={this.changeAudio}
                          />
                        </div>
                      }
                      <div className="mt-2 pull-left visibility-visible">
                        <PlaybackSpeedBtns
                          changePlaybackSpeed={this.changePlaybackSpeed}
                          playbackSpeed={this.state.playbackSpeed}
                        />
                      </div>

                      {this.showSkipAudioWaitButton() &&
                        <div className="btn-outer pull-right visibility-visible">
                          <a
                            href="javascript:void(0)"
                            className="outline-btn skip-btn-new text-center"
                            onClick={this.skip}
                          >Start Audio</a>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="custom-box">
          <div className="ice-storm-form">
            {data && !resultMode && reactStringReplace(question.replace(/<[^>]*>?/gm, 'x_x').replace(/x_x(.*?)x_x/g,''), /(__add_blank__)/g, (match, i, o) => (
              <React.Fragment key={i}>
                {!answer ? (
                  <input
                      type="text"
                      id={i}
                      spellCheck="false"
                      autoComplete="off"
                      onChange={(e) => {
                        this.handleChange(e);
                        this.adjustWidth(e.target);
                      }}
                      className="blankBox form-control auto-expand-input"
                      ref={(el) => (this.inputRef = el)}
                  />
                ) : (
                  <>
                    <div
                      className={`span11 text-center ${this.answerStatusClass(i)}`}
                    >
                      {this.renderAnswer(i)}
                    </div>
                    {this.showCorrectAlongWithWrong(i)}
                  </>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </>
    );

    // return (
    //   <>
    //     <Col xs={12}>
    //       <h4 className="infoText">
    //       </h4>
    //     </Col>
    //     {!this.props.resultMode &&
    //       <Col xs={12}>
    //         <div className="col-lg-12 text-center">
    //           <div className="currentStatus">
    //             <div>Current Status:{this.state.soundStatus == true && this.state.secondCount !== 0 && !this.state.isRecording && ' Begining in '+this.state.secondCount+ ' seconds'}
    //               {this.state.secondCount == 0 && this.state.blobURL === '' && !this.state.isRecording && ' Playing'}

    //               <br></br>
    //               Volume
    //               <Input placeholder="Volume" type="range" min="0" defaultValue={this.state.volume} step="1" onInput={(e) => this.changeRange(e)} max="100"  />
    //               <br></br>
                  
    //               <Progress color="info" value={(this.state.soundPer)} />
    //               {!this.props.testMode && (
    //                 <div className="d-flex justify-content-between">
    //                   <div className='stop-btn'>
    //                     {this.state.secondCount!==0 && this.state.blobURL === '' && !this.state.isRecording &&
    //                       <Button color="success" onClick={this.skip} size="sm">Skip</Button>
    //                     }
    //                   </div>
    //                   <div className="stop-btn clearfix">
    //                     <div className="select-voice pull-left">
    //                       {this.state.defaultSelectedVoice !== null &&
    //                         <SelectVoiceDropDown
    //                           voices={this.props.voices}
    //                           selected={this.state.defaultSelectedVoice}
    //                           changeAudio={this.changeAudio}
    //                         />
    //                       }
    //                     </div>
    //                     <div className="select-speed pull-right">
    //                       <PlaybackSpeedBtns
    //                         changePlaybackSpeed={this.changePlaybackSpeed}
    //                         playbackSpeed={this.state.playbackSpeed}
    //                       />
    //                     </div>
    //                   </div>
    //                 </div>
    //               )}
    //             </div>
    //           </div>
    //           <Col xs={12}>
    //             {this.props.data && !this.props.resultMode && this.state.soundStatus && this.state.secondCount === 0 &&
    //               <Sound
    //                 url={this.state.question_audio}
    //                 playStatus={Sound.status.PLAYING}
    //                 onFinishedPlaying={this.handleSongFinishedPlaying}
    //                 onPlaying={this.handleSongPlaying}
    //                 volume={this.state.volume}
    //                 playbackRate={this.state.playbackSpeed}
    //                 key={this.state.soundKey}
    //               />
    //             }
    //           </Col>
    //           <Col xs={12}>
    //             <>
    //               {this.state.blobURL &&
    //                 <audio src={this.state.blobURL} controls="controls" />
    //               }
    //             </>
    //           </Col>
    //         </div>
    //       </Col>
    //     }

    //     <Col xs={12}>
    //       <h5>{this.props.data && !this.props.resultMode && reactStringReplace(question.replace(/<[^>]*>?/gm, 'x_x').replace(/x_x(.*?)x_x/g,''), /(__add_blank__)/g, (match, i, o) => (<>
    //           <input type="text" id={i} spellCheck="false" autocomplete="off" onChange={(e) => { this.handleChange(e)} } className="blankBox" />{this.props.answer && <span><span className="checkedSign" style={{display: (this.props.answer)?"inline-block":"none"}} data-id={i} data-text={correct[(i-1)/2]} ></span><span className="correct" >{correct[(i-1)/2]}</span></span>}</>
    //         ))}
    //       </h5>
    //     </Col>

    //     <Col xs={12}>&nbsp;</Col>
    //     <Col xs={12}>&nbsp;</Col>
    //     <Col xs={12}>
    //       {this.props.data && this.props.resultMode &&
    //         <h4>Correct answer</h4>
    //       }
    //       <h5>{this.props.data && this.props.resultMode && ReactHtmlParser(question.replace(/<span[^>]*>?/g, '<b class="correct">').replace(/<[^>]*span>?/g, '</b>&nbsp;').replace(/__add_blank__/g, '')) }</h5>
    //     </Col>
    //     <Col xs={12}>
    //       {this.props.data && this.props.resultMode &&
    //         <h4>Your response</h4>
    //       }
    //       <h5>{this.props.data && this.props.resultMode && ReactHtmlParser(question.replace(/<span[^>]*>?/g, '<b class="selected-option">').replace(/<[^>]*span>?/g, '</b>&nbsp;').replace(/__add_blank__/g, '')) }</h5>
    //     </Col>
    //   </>
    // );
  }
}

export default ListeningBlank;
