import React, { useState, useEffect } from 'react';

export default function ListeningResult(props) {
	const [result, setResult] = useState(null);
	const ShowResultMarkers = [14];

	useEffect(() => {
		const { resultData } = props;
		setResult(resultData);
	}, []);

	const scoreClass = (score) => {
		let class_name = "color-danger";

		if (score > 30 && score <= 60) {
			class_name = "color-warning";
		} else if (score > 60) {
			class_name = "color-success";
		}

		return class_name;
	}

	return (
		<>
			{result !== null &&
				<>
					<div className='result-score-row' style={{ display: "block", justifyItems: "center" }}>
						<div className='score-content'>
							<div className='score-circle-content'>
								<div className='score-circle'></div>
								<div className='score-circle-text'>
									<p>Score</p>
									<h2>{result.score.score}/{result.score.out_of}</h2>
								</div>
							</div>

							<ul className="score-list">
								<li>
									{props.moreDetails}
								</li>
							</ul>
						</div>
					</div>

					<div className='gab-card result-content'>
						<ul className="result-markers result-category listening" style={{ justifyContent: "center" }}>
							<li className="listening-result-good">
								<span></span>Correct Answer
							</li>
							<li className="listening-result-bad">
								<span></span>Wrong Answer
							</li>
							<li className="listening-result-not-selected">
								<span></span>Not selected but correct answer
							</li>
						</ul>
					</div>
				</>
			}
		</>
	);

	return (
		<>
			{result !== null &&
				<div className="pmw-outer-wrapper">
					<ul className="result-list">
						{Object.keys(result).map((item, i) => {
							return (
								<React.Fragment key={i}>
									{item !== 'html' &&
										<li key={i}>
											<h4 className="result-output">
												{/*<span className={result[item].score === 0 ? 'text-danger' : (result[item].score > 0 && result[item].score < result[item].out_of ? 'text-orange' : 'text-success')}>{result[item].score}/{result[item].out_of}</span>*/}
												<span className={scoreClass(result[item].score)}>{result[item].score}/{result[item].out_of}</span>
											</h4>
											<h3>{result[item].label}</h3>
										</li>
									}
								</React.Fragment>
							)
						})}
					</ul>

					{ShowResultMarkers.includes(props.subcategoryId) &&
						<ul className="result-markers result-category">
							<li className="good">
								<span></span>Correct Answer
							</li>
							<li className="not-selected">
								<span></span>Not selected but correct answer
							</li>
							<li className="bad">
								<span></span>Wrong Answer
							</li>
						</ul>
					}
				</div>
			}
		</>	
	);
}