import React, { useState, useEffect } from 'react';

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,

  Card,
  CardTitle,
  CardText,
  Button
} from 'reactstrap';
import { Link } from "react-router-dom";
import API from "api.js";
import ListAndBuy from "components/Plans/CCAvenue/ListAndBuy";
import GoldImg from "assets/images/images_new/gold-icon.svg";
import secureIcon from "assets/images/images_new/lock-icon.svg";

const CARD = 'CARD';
const UPI = 'UPI';
export default function SelectPaymentMethod({ stripeForm, country, isGold, selectedMethod, plan }) {
	const [activeTab, setActiveTab] = useState(CARD);
	const [plans, setPlans] = useState([]);

	const tabToggle = (tab) => {
		if(activeTab !== tab) setActiveTab(tab);
	}

	const loadUPIPlans = () => {
		let url = `getCcavPackages?countryId=${country}`;
		if (process.env.REACT_APP_ENV === 'production') {
			url = `https://ipbackend22.languageacademy.com.au/api/v2/getCcavPackages?countryId=${country}`
		}

		API({
			url,
			method: "GET"
		}).then(({ data }) => {
			const selected_plans = [];
			
			data.plans.map((plan) => {
				if (plan.is_gold === isGold) {
					selected_plans.push(plan);
				}
			});
			setPlans(selected_plans);
		}).catch(err => console.log("err:", err));
	}


	/**
	 * Use this for CCAVENUE plans.
	 * Comment this block when using RazorPay Plans.
	 */
	useEffect(() => {
		if (isGold !== undefined) {
			loadUPIPlans();
		}
	}, [isGold]);


	useEffect(() => {
		selectedMethod(activeTab);
	}, [activeTab]);


	return (
		<div className="transation-page outer-wrapper">
			<div className="row">
				<div className="col-md-6">
					<div className="payment-card">
						<h2 className="title">Payment Summary</h2>
						<p className="payment-title">Billing Plan</p>
						
						<div className="billing-card">
              <p>Billed Yearly</p>
              <p className="price">$119</p>
              <p className="per-month">Per month</p>
              <img className="payment-gold-img" src={GoldImg} alt="image" />
            </div>

            <p className="form-payment-title">Payment Details <img src={secureIcon} alt="Image" /></p>

            <form className="payment-card-form">
            	<div class="input-wrapper">
                <label for="name" class="form-label">Name on card</label>
                <input type="text" class="form-control" id="name" placeholder="John Doe" />
              </div>

              <div class="input-wrapper">
                <label for="cardnumber" class="form-label">Credit Card Number</label>
                <input type="number" class="form-control" id="cardnumber" placeholder="Enter card number" />
              </div>

              <div className="input-row">
                <div class="input-wrapper">
                  <label for="cxpiration" class="form-label">Expiration</label>
                  <input type="date" class="form-control" id="cxpiration" placeholder="MM/YY" />
                </div>
                <div class="input-wrapper">
                  <label for="cvv" class="form-label">CVV</label>
                  <input type="number" class="form-control" id="cvv" placeholder="CVV Code" />
                </div>
              </div>

              <div className="input-row">
                <div class="input-wrapper">
                  <label for="zip" class="form-label">ZIP</label>
                  <input type="number" class="form-control" id="zip" placeholder="Enter ZIP Code" />
                </div>
                <div class="input-wrapper">
                  <label class="form-label">Country</label>
                <select class="form-select" aria-label="Default select example">
                  <option selected>All</option>
                  <option value="1">India</option>
                  <option value="2">UK</option>
                  <option value="3">USA</option>
                </select>
                </div>
              </div>

              <button type="submit" class="process-payment-btn">Process Payment</button>
              <p className="form-text">By providing your card information, you allow Language Academy to charge your card for future payments in accordance with their terms.</p>

            </form>
					</div>
				</div>
			</div>
		</div>
	);

	// return (
	// 	<div className="panel panel-default">
	// 		{/*<h2 className="paymentFormTitle">Select a payment method</h2>*/}

	// 		<div className="">
	// 			{country === "IN" &&
	// 				<Nav tabs>
	// 					<NavItem>
	// 						<NavLink
	// 							className={`btn btn-primary btn-lg ${activeTab === CARD ? "active" : ""}`}
	// 							onClick={() => { tabToggle(CARD) }}
	// 						>Credit/Debit Card</NavLink>
	// 					</NavItem>
	// 					<NavItem>
	// 						<NavLink
	// 							className={`btn btn-primary btn-lg ${activeTab === UPI ? "active" : ""}`}
	// 							onClick={() => { tabToggle(UPI) }}
	// 						>Pay with UPI</NavLink>
	// 					</NavItem>
	// 				</Nav>
	// 			}
	// 			<TabContent activeTab={activeTab}>
	// 				<TabPane tabId={CARD}>
	// 					<Row className="mt-3">
	// 						<Col sm="12">
	// 							<Card body>
	// 								{stripeForm()}
	// 							</Card>
	// 						</Col>
	// 					</Row>
	// 				</TabPane>
	// 				<TabPane tabId={UPI}>
	// 					<Row className="mt-3">
	// 						<Col sm="12">
	// 							<Card body className="payCardsOuter">
	// 								<ListAndBuy 
	// 									plans={plans}
	// 									plan={plan}
	// 									isGold={isGold}
	// 								/>

	// 								{/*Comment Below code When using RazorPay*/}
	// 								{/* <Row>
	// 									<Col sm="6">
	// 										<div className="text-danger pull-left mb-0" style={{fontSize: "12px"}}>* Prices inclusive of all taxes.</div>
	// 										<br /><div className="text-danger" style={{fontSize: "12px", marginTop: "-5px"}}>* One-time Purchase.</div>
	// 									</Col>
	// 									<Col sm="6">
	// 										<p className="pull-right" style={{fontSize: "12px"}}>
	// 											<Link
	// 												to="/admin/ccav/plans"
	// 												className="text-info"
	// 											>Explore all UPI Plans</Link>
	// 										</p>
	// 									</Col>
	// 								</Row> */}
	// 								{/*Till here.*/}
									
	// 							</Card>
	// 						</Col>
	// 					</Row>
	// 				</TabPane>
	// 			</TabContent>
	// 		</div>
	// 	</div>
	// );
}