import React, { useState, useEffect } from "react";

export default function LaLoader(props) {
	const { show } = props;

	const [showLoader, setShowLoader] = useState(true);

	useEffect(() => {
		if (showLoader !== show) {
			setShowLoader(!showLoader);
		}
	}, [show]);


	const blurModals = (items, op) => {
		if (op === 'add') for(let i=0; i<items.length; i++) items[i].classList.add('blur');
		else if (op === 'remove') for(let i=0; i<items.length; i++) items[i].classList.remove('blur');
	}


	useEffect(() => {

		// blur portal class.
		const elements = document.getElementsByClassName('portal');
		const modals = document.getElementsByClassName('modal');
		if (showLoader) {
			// modals.length > 0 ? blurModals(modals, 'add') : console.log('no matched elements found.');
			// elements.length > 0 ? elements[0].classList.add('blur') : console.log('no matched elements found.');
		} else {
			// modals.length > 0 ? blurModals(modals, 'remove') : console.log('no matched elements found.');
			// elements.length > 0 ? elements[0].classList.remove('blur') : console.log('no matched elements found.');
		}
	}, [showLoader]);

	return (
		<>
			{showLoader &&
				<div className="loaderWrap">
					<div className="la-loader">
						<img src="/images/new_dash/Ai-loader.gif" alt="loader" />
					</div>
				</div>
			}
		</>
	);
}