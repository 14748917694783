import React, { useState, useEffect } from 'react';
import {
	Button
} from 'reactstrap';
import ScoreIcon from "assets/images/images_new/pie-chart-icon.svg";

export default function WritingResult(props) {
	const [result, setResult] = useState(null);
	const [buttons, setButtons] = useState(false);
	const [isEssay, setIsEssay] = useState(false);
	const NOT_ALLOWED = ['overall'];
	
	const validKeys = [
		"content",
		"form",
		"grammar", // Write Essay Only.
		"grammer", // Summarize Written Text only.
		"vocabulary",
		"linguistic",
		"structure",
		"spelling"
	];

	useEffect(() => {
		const { resultData, buttonsOnly, essay } = props;
		
		setResult(resultData);
		setButtons(buttonsOnly);
		setIsEssay(essay);
	}, []);


	const errorMessage = () => {
		if (result.content.score === 0) {
			return (
				<div className="gab-card result-content mb-3">
					<div className="error-message alert-danger">
						{result.feedback.content}
					</div>
				</div>
			);
		} else if (result.form.score === 0) {
			return (
				<div className="gab-card result-content mb-3">
					<div className="error-message alert-danger">
						{result.feedback.form}
					</div>
				</div>
			);
		}
	}

	const scoreClass = (score) => {
		let class_name = "color-danger";

		if (score > 30 && score <= 60) {
			class_name = "color-warning";
		} else if (score > 60) {
			class_name = "color-success";
		}

		return class_name;
	}

	return (
		<>
			{result !== null &&
				<>
					<div className='result-score-row'>
						<div className='score-content'>
							<div className='score-circle-content'>
								<div className='score-circle'></div>
								<div className='score-circle-text'>
									<p>{props.subcategoryId !== 20 ? "Overall" : ""} Score</p>
									{props.subcategoryId !== 20 ? (
										<h2>{result.overall.score}/{result.overall.out_of}</h2>
									) : (
										<h2>{result.score.score}/{result.score.out_of}</h2>
									)}
								</div>
							</div>

							<ul className="score-list">
								{Object.keys(result).map((item, i) => {
									if (validKeys.includes(item)) {
										return (
											<li key={i}>
												{result[item].label}: <span><img src={ScoreIcon} alt="Image" /> {result[item].score}/{result[item].out_of}</span>
											</li>
										)
									}
								})}
							</ul>
						</div>
					</div>

					
					{/* Show error message only. If any. Only Essay */}
					{isEssay && errorMessage()}


					<div className='gab-card result-content mb-3'>
						<div className='gab-text size-adjust'>
							{props.newHtml !== null &&
								<>
									{props.renderResponseHtml(props.newHtml)}
								</>
							}

							{props.newResult !== null && props.newResult !== undefined && 'html' in props.newResult &&
								props.renderResponseHtmlOldStyle(props.newResult.html)
							}
						</div>

						<div className='result-view-remarks clearfix'>
							{props.subcategoryId === 7 &&
								<>
									<div class="result-popup-btn m-0 p-0 pmw-outer-wrapper pull-left">
										<div class="left-btns">
											<button type="button" class="outline-btn">Total Score: {result.overall.score}</button>
										</div>
									</div>

									<div class="result-popup-btn m-0 p-0 pmw-outer-wrapper pull-left">
										<div class="left-btns ml-2">
											<button type="button" class="outline-btn">{result.english_pattern} English</button>
										</div>
									</div>

									<div class="result-popup-btn m-0 p-0 pmw-outer-wrapper pull-left">
										<div class="left-btns ml-2">
											<button type="button" class="outline-btn">Word Count: {result.word_count}</button>
										</div>
									</div>
								</>
							}

							<div className="pull-right">
								{props.moreDetails}
							</div>
						</div>
					</div>

					{props.aiSuggestions}
				</>
			}
		</>
	);
}