import React, { useState, useEffect } from 'react';

export default function OtherFilters({
	changeFilter,
	orderby, complexity, attempted, mark, filterByVid,
	type
}) {	

	const orderByLabel = (value) => {
		const order_by = value === "desc" ? "Newest First" : "Oldest First";
		return order_by;
	}

	
	const complexityLabel = (value) => {
		let label = "";

		switch(value) {
			case "all":
				label = "Select";
				break;
			case "0":
				label = "Easy";
				break;
			case "1":
				label = "Medium";
				break;
			case "2":
				label = "Difficult";
				break;
			default:
				// Do nothing.
				break;
		}
		return label;
	}


	const practiceStatusLabel = (value) => {
		let label = "";

		switch(value) {
			case "all":
				label = "Select";
				break;
			case "0":
				label = "Not Attempted";
				break;
			case "1":
				label = "Attempted";
				break;
			default:
				// Do nothing.
				break;
		}
		return label;
	}


	const badgeLabel = (value) => {
		let label = "";

		switch(value) {
			case "all":
				label = "Select";
				break;
			case "marked":
				label = "All Marked";
				break;
			case "red":
				label = "Red";
				break;
			case "green":
				label = "Green";
				break;
			case "yellow":
				label = "Yellow";
				break;
			default:
				// Do nothing.
				break;
		}
		return label;
	}


	const filterByVideoLabel = (value) => {
		let label = "";

		switch (value) {
			case "all":
				label = "None";
				break;
			case "yes":
				label = "With Explanation Videos";
				break;
			case "no":
				label = "Without Explanation Videos";
				break;
			default:
				// Do nothing.
				break;
		};
		return label;
	}

	return (
		<div className="qlist-filters">
			
			{/* Filter 1. Order By */}
			<div className="filters-wrapper">
				<div className="dropdown filters-content filter-1">
					<span className="filter-badge">Order by</span>
					<div className="position-relative">
						<button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
							{orderByLabel(orderby)}
						</button>
						<ul className="dropdown-menu">
							{["desc", "asc"].map((item, i) => {
								return (
									<ListItem
										name="orderby"
										item={item}
										stateValue={orderby}
										clickHandler={changeFilter}
										label={orderByLabel(item)}
										key={i}
									/>
								)
							})}
						</ul>
					</div>
				</div>
			</div>

			{/* Filter 2. Complexity */}
			<div className="filters-wrapper">
				<div className="dropdown filters-content filter-2 select-filter">
					<span className="filter-badge">Complexity</span>
					<div className="position-relative">
						<button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
							{complexityLabel(complexity)}
						</button>
						<ul className="dropdown-menu">
							<ListItem
								name="complexity"
								item="all"
								stateValue={complexity}
								clickHandler={changeFilter}
								label="Select"
							/>
							{["Easy", "Medium", "Difficult"].map((item, i) => {
								return (
									<ListItem
										name="complexity"
										item={i.toString()}
										stateValue={complexity}
										clickHandler={changeFilter}
										label={complexityLabel(i.toString())}
										key={i}
									/>
								)
							})}
						</ul>
					</div>
				</div>
			</div>

			{/* Filter 3. Practice Status */}
			<div className="filters-wrapper">
				<div className="dropdown filters-content filter-3 select-filter">
					<span className="filter-badge">Practice Status</span>
					<div className="position-relative">
						<button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
							{practiceStatusLabel(attempted)}
						</button>
						<ul className="dropdown-menu">
							<ListItem
								name="attempted"
								item="all"
								stateValue={attempted}
								clickHandler={changeFilter}
								label="Select"
							/>
							{["Not Attempted", "Attempted"].map((item, i) => {
								return (
									<ListItem
										name="attempted"
										item={i.toString()}
										stateValue={attempted}
										clickHandler={changeFilter}
										label={practiceStatusLabel(i.toString())}
										key={i}
									/>
								)
							})}
						</ul>
					</div>
				</div>
			</div>

			{/* Filter 4. Badge */}
			<div className="filters-wrapper">
				<div className="dropdown filters-content filter-4">
					<span className="filter-badge">Badge</span>
					<div className="position-relative">
						<button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
							{badgeLabel(mark)}
						</button>
						<ul className="dropdown-menu">
							<ListItem
								name="mark"
								item="all"
								stateValue={mark}
								clickHandler={changeFilter}
								label="Select"
							/>
							{["marked", "red", "green", "yellow"].map((item, i) => {
								return (
									<ListItem
										name="mark"
										item={item}
										stateValue={mark}
										clickHandler={changeFilter}
										label={badgeLabel(item)}
										key={i}
									/>
								)
							})}
						</ul>
					</div>
				</div>
			</div>

			{/* Filter 5. Question with/without Explanation Video */}
			{(type === 11 || type === 12) &&
				<div className="filters-wrapper">
					<div className="dropdown filters-content filter-5">
						<span className="filter-badge">Filter By</span>
						<div className="position-relative">
							<button className="dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
								{filterByVideoLabel(filterByVid)}
							</button>
							<ul className="dropdown-menu">
								{["all", "yes", "no"].map((item, i) => {
									return (
										<ListItem
											name="filterByVid"
											item={item}
											stateValue={filterByVid}
											clickHandler={changeFilter}
											label={filterByVideoLabel(item)}
											key={i}
										/>
									)
								})}
							</ul>
						</div>
					</div>
				</div>
			}
		</div>
	);
};


const ListItem = ({ item, stateValue, name, label, clickHandler }) => {

	const active_class = item === stateValue ? "active-filter" : "";

	const filterClick = (e) => {
		e.preventDefault();
		
		if (item !== stateValue) {
			clickHandler(name, item);
		}
	}
	
	return (
		<li
			className={`dropdown-item ${active_class}`}
			onClick={filterClick}
		>{label}</li>
	);
}