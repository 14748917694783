import React from "react";
import {
	Modal,
	ModalHeader,
	ModalBody,
	Button
} from "reactstrap";
import ReactPlayer from "react-player";

export default function QuestionExplanationVideo({
	isOpen, toggle, videoUrl
}) {

	return (
		<Modal
			isOpen={isOpen}
			className="text-translate-modal videopopup"
			size="lg"
		>
			<ModalHeader
				className="text-translation"
				tag="div"
			>
				Explanation Video
				<Button className="modalCloseBtn" onClick={toggle}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						width="24"
						height="24"
					>
						<path
							d="M18 6L6 18M6 6l12 12"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</Button>
			</ModalHeader>
			<ModalBody>
				<ReactPlayer url={videoUrl} controls={true} />
			</ModalBody>
		</Modal>
	);
}