import React from 'react';
import {
	Modal,
	ModalHeader,
	ModalBody,
	Button
} from 'reactstrap';
import Result from "components/Question/Result/Result";
import multiLing from "assets/images/images_new/audio-icon.png";
import ScoreIcon from "assets/images/images_new/pie-chart-icon.svg";
import TemplateDetectorModal from "components/PracticeMaterial/PopUps/TemplateDetectorModal";

export default function ResultPopup({
	isAnswer, resultData,
	speakingResult, writingResult, readingResult, listeningResult,
	fail, newResult, newHtml, subcategoryId, renderResponse, correctAnswer,
	option_explaination, historyData, toggleResultPopup,
	question_id, question_title, recorded_file,
	
	template_issue, template_type, common_sentence, score, explanationModal,
	templateDetectorModalProps, closeModalExplanation
}) {

	const checkTemplateDetected = () => {
		return template_type ? true : false;
	}

	return (
		<>
			{checkTemplateDetected() ? (
				<TemplateDetectorModal
                  isOpen={true}
                  {...templateDetectorModalProps}
                  closeModal={toggleResultPopup}
                />
			) : (
				<Modal isOpen={true} size="xl" className="text-translate-modal result-popup-section">
					<ModalHeader className="text-translation" tag="div">
						Score Info
						<Button className="modalCloseBtn" onClick={toggleResultPopup}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								width="24"
								height="24"
							>
								<path
									d="M18 6L6 18M6 6l12 12"
									stroke="currentColor"
									strokeWidth="2"
									strokeLinecap="round"
									strokeLinejoin="round"
								/>
							</svg>
						</Button>
					</ModalHeader>
					<ModalBody>
						<h2 className='result-sub-title'>{question_title}</h2>
						<Result
							question_id={question_id}
							isAnswer={isAnswer}
							resultData={resultData}
							speakingResult={speakingResult}
							writingResult={writingResult}
							readingResult={readingResult}
							listeningResult={listeningResult}
							fail={fail}
							newResult={newResult}
							newHtml={newHtml}
							subcategoryId={subcategoryId}
							renderResponse={renderResponse}
							correctAnswer={correctAnswer}
							option_explaination={option_explaination}
							historyData={historyData}
							isPopup={true}
							recorded_file={recorded_file}
						/>
					</ModalBody>
				</Modal>
			)}
		</>
	);
}