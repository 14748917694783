import React, { useState, useEffect, memo } from "react";
import API from "api.js";
import { Link } from "react-router-dom";
import ArrowImg from "assets/images/images_new/practice-arrow.svg";
import calendarImg from "assets/images/images_new/calendar-icon.svg";
import ClockImg from "assets/images/images_new/clock-icon.svg";
import SelectOption from "components/PracticeMaterial/SelectOption";

import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	Button
} from "reactstrap";
import AvailableBands from "data/availableBands.js";

const Stats = memo(function Stats({
	mockData, practiceData
}) {

	const [desiredScore, setDesiredScore] = useState("0");
	const [practiceDays, setPracticeDays] = useState("0");
	const [examDate, setExamDate] = useState("0");

	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(prevState => !prevState);

	const [examIsOpen, setExamIsOpen] = useState(false);
	const toggleExam = () => setExamIsOpen(prevState => !prevState);
	

	const saveDesiredScore = (e, score) => {
		API({
			url: "updateTargetScore",
			method: "POST",
			data: { target_score: score }
		}).then(res => {
			toggle();
			setDesiredScore(score);
		}).catch(err => {
			console.log('err', err);
		});
	}

	const saveExamDate = (e, selected_date) => {
		API({
			url: "updateExamDate",
			method: "POST",
			data: { exam_date: selected_date }
		}).then(res => {
			const { days_left } = res.data;
			toggleExam();
			setExamDate(days_left);
		}).catch(err => {
			console.log('err', err);
		});
	}

	useEffect(() => {
		if (mockData) {
			// selectObject(mockData.desired_score);
			setDesiredScore(mockData.desired_score);
			setExamDate(mockData.days_left);
		}
	}, [mockData]);

	useEffect(() => {
		if (practiceData) {
			setPracticeDays(practiceData.days)
		}
	}, [practiceData]);

	return (
		<>
			<div className="col-md-6 col-xl-7 pr-md-0">
				<div className="dashboard-card">
					<h2 className="title">My Stats</h2>
					<div className="stats-row">
						<div className="stats-cards">
							<StatCard
								data={mockData}
								class_name="total-mocks"
								labelOne="Total Mocks"
								labelTwo="Practiced"
							/>
							
							<StatCardOther
								count={desiredScore}
								class_name="desired-score"
								labelOne="Desired"
								labelTwo="Score"
								toggle={toggle}
								isPopup={true}
							/>

							<StatCard
								data={practiceData}
								class_name="questions-practiced"
								labelOne="Questions"
								labelTwo="Practiced"
							/>

							<StatCardOther
								count={practiceDays}
								class_name="days-practiced"
								labelOne="Days"
								labelTwo="Practiced"
							/>

							{/* <div className="stats-btns border-0 p-0 m-0">
								<Link to="practice-detail" className="stats-btn btn-block">
									<img src={calendarImg} alt="Image" /> See History
								</Link>
								<Link to="daily-feedback" className="stats-btn btn-block">
									<img src={ClockImg} alt="Image" /> Daily Feedback
								</Link>
							</div> */}

							{/* <div className="exam-date-card m-0 w-100">
								<div className="exam-date-content border-0 p-0">
									<StatCardOther
										count={examDate}
										class_name="days-practiced"
										labelOne="Exam"
										labelTwo="Date"
										toggle={toggleExam}
										allowCountText={true}
										isPopup={true}
									/>
								</div>
							</div> */}
							
							{/* <StatCard
								data={practiceData}
								class_name="questions-practiced"
								labelOne="Questions"
								labelTwo="Practiced"
							/>

							<StatCardOther
								count={practiceDays}
								class_name="days-practiced"
								labelOne="Days"
								labelTwo="Practiced"
							/> */}
						</div>
						<div className="exam-date-card">
							<div className="exam-date-content">
								<StatCardOther
									count={examDate}
									class_name="days-practiced"
									labelOne="Exam"
									labelTwo="Date"
									toggle={toggleExam}
									allowCountText={true}
									isPopup={true}
								/>
							</div>
							<div className="stats-btns">
								<Link to="practice-detail" className="stats-btn btn-block">
									<img src={calendarImg} alt="Image" /> See History
								</Link>
								<Link to="daily-feedback" className="stats-btn btn-block">
									<img src={ClockImg} alt="Image" /> Daily Feedback
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

			{isOpen &&
				<ScorePopup
					isOpen={isOpen}
					toggle={toggle}
					submit={saveDesiredScore}
					desiredScore={desiredScore}
				/>
			}

			{examIsOpen &&
				<ExamPopup
					isOpen={examIsOpen}
					toggle={toggleExam}
					submit={saveExamDate}
				/>
			}
		</>
	);
});
export default Stats;

const StatCard = ({
	data, labelOne, labelTwo, class_name
}) => {

	const [practiced, setPracticed] = useState(0);

	useEffect(() => {
		if (data) {
			setPracticed(data.count);
		}
	}, [data]);

	return (
		<div className={`stats-card ${class_name}`}>
			<div className="count">{practiced}</div>
			<p>
				{labelOne}
				<br />
				{labelTwo}
			</p>
		</div>
	);
};


const StatCardOther = ({
	labelOne, labelTwo, class_name, dataKey,
	allowCountText, toggle, count, isPopup
}) => {

	const pointer_class = isPopup ? "cursor-pointer" : "";

	const popupHandler = () => {
		if (isPopup) toggle();
	}

	return (
		// <div className={`stats-card ${class_name} ${pointer_class}`} onClick={popupHandler}>
		<div className={`stats-card ${class_name} `} onClick={popupHandler}>
			<div className="d-flex align-items-center">
				<div className="count">
					{count} {allowCountText && <span>Days Left</span>}
				</div>
				<p>
					{labelOne}
					<br />
					{labelTwo}
				</p>
			</div>
			{isPopup &&
				<div className="arrow-icon">
					<img
						src={ArrowImg}
						alt="Image"
					/>
				</div>
			}
		</div>
	);
};

const ExamPopup = ({
	isOpen, toggle, submit
}) => {

	const [selectedDate, setSelectedDate] = useState("");

	return (
		<Modal
			isOpen={isOpen}
			className="text-translate-modal"
		>
			<ModalHeader
				className="text-translation"
				tag="div"
			>
				Set Exam Date
				<Button className="modalCloseBtn" onClick={() => toggle()}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						width="24"
						height="24"
					>
						<path
							d="M18 6L6 18M6 6l12 12"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</Button>
			</ModalHeader>
			<ModalBody>
				<div className="row">
					<h2 className="translate-title">Select Exam Date</h2>
					<input
						type="date"
						value={selectedDate}
						min={new Date().toISOString().split("T")[0]}
						onChange={(e) => setSelectedDate(e.target.value)}
					/>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					className="close-button"
					onClick={(e) => submit(e, selectedDate)}
				>
					Save Score
				</Button>
			</ModalFooter>
		</Modal>
	);
};

const ScorePopup = ({
	isOpen, toggle, submit, desiredScore
}) => {

	const [bands, setBands] = useState([]);
	const [selected, setSelected] = useState({
		value: 0,
		label: "Select"
	});

	const changeSelected = (selectedValue) => {
		selectObject(selectedValue);
	}

	const selectObject = (value) => {
		let item = AvailableBands.filter((band, i) => {
			return parseInt(band.value) === parseInt(value);
		});

		if (item.length > 0) {
			setSelected(item[0]);
		}
	}

	useEffect(() => {
		setBands(AvailableBands);
		selectObject(desiredScore);
	}, []);

	return (
		<Modal
			isOpen={isOpen}
			className="text-translate-modal"
		>
			<ModalHeader
				className="text-translation"
				tag="div"
			>
				Set Desired Score
				<Button className="modalCloseBtn" onClick={() => toggle()}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						width="24"
						height="24"
					>
						<path
							d="M18 6L6 18M6 6l12 12"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</Button>
			</ModalHeader>
			<ModalBody>
				<div className="row">
					<h2 className="translate-title">Select Desired Score</h2>
					{selected &&
						<SelectOption
							isClearable={false}
							selected={selected}
							data={bands.filter((band, i) => {
								return band.value !== selected.value
							})}
							changeEvent={changeSelected}
						/>
					}
				</div>
			</ModalBody>
			<ModalFooter>
				<Button
					className="close-button"
					onClick={(e) => submit(e, selected.value)}
				>
					Save Score
				</Button>
			</ModalFooter>
		</Modal>
	);
}