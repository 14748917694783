import React from "react";
import Sound from 'react-sound';

export default function QuestionAudioAlternative({
	question_audio, play, updateAudioFinishedFromTranscript
}) {

	const audioFinished = () => {
		updateAudioFinishedFromTranscript(false);
	}

	const isPlaying = () => {
		return play ? Sound.status.PLAYING : Sound.status.STOPPED;
	}

	return (
		<Sound
			url={question_audio}
			autoLoad={true}
			autoPlay={false}
			playStatus={isPlaying()}
			onFinishedPlaying={audioFinished}
		/>
	);
}