import React from "react";
import { NavLink } from "react-router-dom";
import LeftMenuItem from "components/Dashboard/Sidebar/LeftMenuItem.jsx";
import logo from "../../../assets/images/images_new/logo.svg";
import SidebarClose from "components/Sidebar/SidebarClose";
import menuIcon1 from "../../../assets/images/images_new/sidebar-icons/01.svg";
import menuIcon2 from "../../../assets/images/images_new/sidebar-icons/02.svg";
import menuIcon3 from "../../../assets/images/images_new/sidebar-icons/03.svg";
import menuIcon4 from "../../../assets/images/images_new/sidebar-icons/04.svg";
import menuIcon5 from "../../../assets/images/images_new/sidebar-icons/05.svg";
import menuIcon6 from "../../../assets/images/images_new/sidebar-icons/06.svg";
import menuIcon8 from "../../../assets/images/images_new/sidebar-icons/08.svg";
import menuIcon9 from "../../../assets/images/images_new/sidebar-icons/09.svg";
import menuIcon10 from "../../../assets/images/images_new/sidebar-icons/10.svg";
import menuIcon11 from "../../../assets/images/images_new/sidebar-icons/11.svg";
import menuIcon12 from "../../../assets/images/images_new/sidebar-icons/12.svg";
import menuIcon13 from "../../../assets/images/images_new/sidebar-icons/13.svg";
import menuIcon14 from "../../../assets/images/images_new/sidebar-icons/notification-icon.svg";

export default function Menu(props) {
  const menuItems = [
    {
      type: 1,
      url: "dashboard",
      label: "Dashboard",
      icon: menuIcon1,
      item: "1",
    },
    {
      type: 1,
      url: "test-material",
      label: "Practice Material",
      icon: menuIcon2,
      item: "2",
    },
    {
      type: 1,
      url: "monthly-prediction",
      label: "Monthly Prediction",
      icon: menuIcon3,
      item: "4",
    },
    {
      type: 1,
      url: "template",
      label: "Templates",
      icon: menuIcon4,
      item: "3",
    },
    {
      type: 1,
      url: "prediction-files",
      label: "Prediction Files",
      icon: menuIcon5,
      item: "5",
    },
    {
      type: 2,
      label: "Mock Tests",
      icon: menuIcon6,
      item: "5",
      submenu: [
        { url: "mock", label: "Take Test" },
        { url: "pending", label: "Pending Test" },
        { url: "mock-result", label: "Test Result" },
      ],
    },
	  {
      type: 2,
      label: "Extensive Mock Tests",
      icon: menuIcon6,
      item: "15",
      submenu: [
        { url: "mocks/new-sectional-tests", label: "Take Test" },
        { url: "pending/new-sectional-tests", label: "Pending Test" },
        { url: "results", label: "Test Result" },
      ],
    },
    {
      type: 1,
      url: "videos",
      label: "Secret Strategy Videos",
      icon: menuIcon8,
      premiumIcon: true,
      item: "6",
    },
    {
      type: 2,
      label: "Daily Feedback",
      icon: menuIcon9,
      item: "7",
      submenu: [
        { url: "daily-task", label: "Daily Task" },
        { url: "success-plan", label: "Success Plan" },
        { url: "daily-feedback", label: "Daily Feedback" },
      ],
    },
    {
      type: 1,
      url: "session",
      label: "Live Session",
      icon: menuIcon10,
      item: "8",
    },
    {
      type: 1,
      url: "notifications",
      label: "Notifications",
      icon: menuIcon14,
      item: "9",
    },
    {
      type: 1,
      url: "help",
      label: "Need Help",
      icon: menuIcon12,
      item: "10",
    },
    {
      type: 1,
      url: "transaction",
      // label: "Your Subscriptions",
      label: "Subscriptions",
      icon: menuIcon11,
      item: "9",
      bottomMenu: true,
    },
    {
      type: 1,
      url: "profile",
      label: "My Profile",
      icon: menuIcon10,
      item: "10",
    },
    {
      type: 3,
      url: "javascript:void(0);",
      label: "Log Out",
      icon: menuIcon13,
      item: "11",
      click: "logout",
    },
  ];
  return (
    <>
      <div className="sidebar-logo-wrapper">
        <div className="logo-wrapper">
          <a href="/">
            <img src={logo} alt="Language Academy Logo" />
            <span>Language Academy</span>
          </a>
        </div>
        <SidebarClose />
      </div>

      <div className="row">
        <div className="col">
          <nav className="nav-menu">
            <ul className="m-0 p-0 d-flex flex-column">
              {menuItems.map((menu, key) => (
                <LeftMenuItem key={key} {...menu} />
              ))}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}