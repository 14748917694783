import React, { useState, memo, useEffect } from "react";
import API from "api.js";

import SpeakingZoneIcon from "assets/images/images_new/microphone-icon.svg";
import WritingZoneIcon from "assets/images/images_new/writing-icon.svg";
import ReadingZoneIcon from "assets/images/images_new/reading-icon.svg";
import ListeningZoneIcon from "assets/images/images_new/listening-icon.svg";
import RightArrow from "assets/images/images_new/right-arrow.svg";

const StatCategories = memo(function StatCategories({
	practiceData, showAverageScore
}) {

	//  Speaking = 1
	//  Writing = 2
	//  Reading = 3
	//  Listening = 4
	return (
		<div className="col-md-7 pl-md-0">
			<div className="progress-section">
				<Section
					data={practiceData}
					title="Speaking"
					icon={SpeakingZoneIcon}
					class_name="speaking"
					category={1}
					showAverageScore={showAverageScore}
				/>

				<Section
					data={practiceData}
					title="Writing"
					icon={WritingZoneIcon}
					class_name="writing"
					category={2}
					showAverageScore={showAverageScore}
				/>

				<Section
					data={practiceData}
					title="Reading"
					icon={ReadingZoneIcon}
					class_name="reading"
					category={3}
					showAverageScore={showAverageScore}
				/>

				<Section
					data={practiceData}
					title="Listening"
					icon={ListeningZoneIcon}
					class_name="listening"
					category={4}
					showAverageScore={showAverageScore}
				/>
			</div>
		</div>
	);
});


const Section = ({
	data, title, icon, class_name, category, showAverageScore
}) => {

	const [percentage, setPercentage] = useState(0);
	const [completed, setCompleted] = useState(0);
	const [total, setTotal] = useState(0);
	const [averageScore, setAverageScore] = useState(null);

	useEffect(() => {
		if (data) {
			switch(category) {
				case 1:
					setData(data.speaking);
					break;
				case 2:
					setData(data.writing);
					break;
				case 3:
					setData(data.reading);
					break;
				case 4:
					setData(data.listening);
					break;
				default:
					break;
			}
		}
	}, [data]);

	const setData = (d) => {
		setCompleted(d.done);
		setTotal(d.total);
		setPercentage(parseFloat((d.done / d.total) * 100).toFixed(2));
	}

	const fetchAverageScore = (category) => {
		API({
			method: "GET",
			url: `get/average/score?category=${category}`
		}).then(res => {
			setAverageScore(res.data.data);
		}).catch(err => console.log(err));
	}

	return (
		<div className={`progress-card ${class_name}`}>
			<h4>
				<img src={icon} alt={title} /> {title}
			</h4>
			<div className="progress-container">
				<div className="progress-title">
					{showAverageScore === 1 &&
						<>
							<a
								className="progress-percentage link-style"
								href={`/admin/progress/${category}?mock=0`}
							>
								View details
								<img src={RightArrow} alt="Image" />
							</a>
						</>
					}

					<div className="progress-text">
						{completed} <span className="color">of {total}</span>
					</div>
					{/*<div className="progress-percentage">{percentage}%</div>*/}
				</div>
				{/*<div style={{ height: "8px" }}>
					<div
						className="progress-fill"
						style={{ width: "0%" }}
					></div>
				</div>*/}
				<div className="progress-bar">
					<div
						className="progress-fill"
						style={{ width: `${(percentage)}%` }}
					></div>
				</div>
			</div>
		</div>
	);
};

export default StatCategories;