import React, { Component } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Row,
  Col,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  CardText,
  NavLink as NavLink1,
} from "reactstrap";
import ChromeCheck from "views/ChromeCheckGlobal.jsx";
import API from "api.js";
import { Link, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
// core components
import PanelHeader from "components/PanelHeader/PanelHeader.jsx";

import { thead, tbody } from "variables/general";
import TopNavPills from "components/SectionalTest/TopNavPills";
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import ArrowIcon from "../assets/images/images_new/sidebar-icons/06.svg";

class MockPending extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      activeTab: 0,
      all_ok: true,
      all_ok1: true,
    };
  }

  componentDidMount() {

    let isChrome =
      /Chrome/.test(navigator.userAgent) &&
      /Google Inc/.test(navigator.vendor) &&
      !/Chromium/.test(navigator.userAgent);
    let all_ok1 = isChrome ? true : false;
    if (navigator.getUserMedia === undefined) {
      this.setState({ all_ok: false, all_ok1: false });
    } else {
      navigator.getUserMedia(
        { audio: true },
        () => {
          console.log("Permission Granted");
          this.setState({ all_ok1: all_ok1 });
        },
        () => {
          this.setState({ all_ok1: false });
          console.log("Permission Denied");
        }
      );
    }

    let new_format = 0;
    if (this.props.location.pathname === "/admin/pending/new-sectional-tests") {
      new_format = 1;
    }

    this.getMocks(new_format);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      if (
        this.props.location.pathname === "/admin/pending/new-sectional-tests"
      ) {
        this.getMocks(1);
      } else if (this.props.location.pathname === "/admin/pending") {
        this.getMocks(0);
      }
    }
  }

  getMocks = (new_format) => {
    API({
      method: "GET",
      url: "pending/mock?new_format=" + new_format,
    })
      .then((data) => {
        let tab = 1;
        // this.props.location.pathname === "/admin/pending/new-sectional-tests" &&
        //   (tab = 2);
        this.toggle(tab);
        this.setState({ data: data.data });
      })
      .catch(() => {});
  };

  toggle = (tab) => {
    let all_ok = true;
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab }, function () {
        if ([1, 5].includes(this.state.activeTab)) {
          all_ok = this.state.all_ok1;
        } else {
          all_ok = true;
        }
        this.setState({ all_ok: all_ok });
      });
    }
  };

  redirectToMock = (id, event) => {
    event.preventDefault();
    const index = event.target.attributes["data-id"].value;
    const { mock } = this.state.data[index];
    const { new_format } = mock;
    this.props.history.push({
      pathname: `/admin/${new_format ? "sectionalTest" : "test"}`,
      state: {
        id,        
        pending: 1,
      },
    });
  };

  redirectToFullMock = (id, event) => {
    event.preventDefault();
    this.props.history.push({
      pathname: `/admin/fulltest`,
      state: {
        id,
        pending: 1,
      },
    });
  };

  render() {
    return (
      <>
        <Helmet htmlAttributes>
          <title>Top PTE Training Institute</title>
        </Helmet>
        {true && (
          <div className="content tabs-content p-0 mock-test">
            <DashboardHeader title="Pending Mock Test" />
            <Row className="outer-wrapper">
              <Col xs={12}>
                <Card className="border-0 card-wrapper">
                  <div className="dailytask-header">
                    <div className="dailytask-title">Pending Mock Test</div>
                    <div>
                      {this.props.location.pathname !==
                        "/admin/pending/new-sectional-tests" && (
                        <Nav
                          className="nav-pills-primary nav-pills-icons justify-content-center"
                          pills
                          role="tablist"
                          tabs
                        >
                          <NavItem>
                            <NavLink1
                              className={
                                this.state.activeTab === 1 ? "active" : ""
                              }
                              onClick={() => {
                                this.toggle(1);
                              }}
                            >
                              Speaking
                            </NavLink1>
                          </NavItem>
                          <NavItem>
                            <NavLink1
                              className={
                                this.state.activeTab === 2 ? "active" : ""
                              }
                              onClick={() => {
                                this.toggle(2);
                              }}
                            >
                              Writing
                            </NavLink1>
                          </NavItem>
                          <NavItem>
                            <NavLink1
                              className={
                                this.state.activeTab === 3 ? "active" : ""
                              }
                              onClick={() => {
                                this.toggle(3);
                              }}
                            >
                              Reading
                            </NavLink1>
                          </NavItem>
                          <NavItem>
                            <NavLink1
                              className={
                                this.state.activeTab === 4 ? "active" : ""
                              }
                              onClick={() => {
                                this.toggle(4);
                              }}
                            >
                              Listening
                            </NavLink1>
                          </NavItem>
                          <NavItem>
                            <NavLink1
                              className={
                                this.state.activeTab === 5 ? "active" : ""
                              }
                              onClick={() => {
                                this.toggle(5);
                              }}
                            >
                              Full Mock Test
                            </NavLink1>
                          </NavItem>
                        </Nav>
                      )}
                      {this.props.location.pathname ===
                        "/admin/pending/new-sectional-tests" && (
                        <TopNavPills
                          activeTab={this.state.activeTab}
                          toggle={this.toggle}
                        />
                      )}
                     </div>
                  </div>
                  <CardBody className="p-0">
                    {!this.state.all_ok && (
                      <div className="mt-5">
                        <ChromeCheck />
                      </div>
                    )}
                    {this.state.all_ok && (
                      <div className="table-wrapper">
                        <Table>
                          <thead className="text-primary">
                            <tr>
                            <th className="table-counting">#</th>
                              <th className="">Title</th>
                              <th className="">Description</th>
                              <th className="">Time</th>
                              <th className="">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.map((val, key) => {
                              if (
                                val?.mock?.category?.id == this.state.activeTab
                              ) {
                                return (
                                  <tr>
                                    <td>{key + 1}</td> 
                                    <td className="">{val?.mock?.title}</td>
                                    <td className="">{val?.description}</td>
                                    <td className="">{val?.mock?.time}</td>
                                    <td className="">
                                      {val.mock.type === 0 || val.mock.id ? (
                                        <>
                                          {val.mock.category.id !== 5 && (
                                            <a
                                              href="#"
                                              data-id={key}
                                              onClick={this.redirectToMock.bind(
                                                this,
                                                val.mock.id
                                              )}
                                              className="nav-link"
                                              activeClassName="active"
                                            >                                              
                                              <div className="view-btn" data-id={key}><img className='view-btn-icon' src={ArrowIcon} alt="Resume Test" />Resume Test</div>
                                            </a>
                                          )}
                                          {val.mock.category.id === 5 && (
                                            <a
                                              href="#"
                                              onClick={this.redirectToFullMock.bind(
                                                this,
                                                val.mock.id
                                              )}
                                              className="nav-link view-btn"
                                              activeClassName="active"
                                            >
                                             <img className='view-btn-icon' src={ArrowIcon} alt="Resume Test" />Resume Test
                                            </a>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <Link to="/admin/plans">
                                            Subscribe to mock plan
                                          </Link>
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                );
                              }
                            })}
                          </tbody>
                        </Table>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        )}
      </>
    );
  }
}

export default MockPending;
