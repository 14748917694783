import { setAlert } from "action/index";
import PlanTab from "components/Plans/PlanTabNew";
import useHttp from "hooks/useHttpRequest";
import React from "react";
import {
  ButtonGroup,
  Button
} from 'reactstrap';
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getCountryForTimezone } from "countries-and-timezones";
import { Link } from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import SidebarClose from "components/Sidebar/SidebarClose";

import Slider from "react-slick";
import DashboardHeader from "components/Dashboard/DashboardHeader/DashboardHeader";
import checkIcon from "assets/images/images_new/check_circle.svg";
import cancelIcon from "assets/images/images_new/cancel.svg";
import ArrowIcon from "assets/images/images_new/dropdown-icon.svg";
import Features from "components/Plans/Features.js";

// added by gk. 28-02-2024
import { useLocation } from 'react-router-dom';
// -----------------------

const GOLD = "GOLD";
const SILVER = "SILVER";
const TYPES = [GOLD, SILVER];

const Plans = (props) => {
  const { response, hasError, request } = useHttp();
  const [plans, setPlans] = useState([]);
  const dispatch = useDispatch();

  const [planDuration, setPlanDuration] = useState(1);
  const [country, setCountry] = useState("AU");
  const [selectedPlan, setSelectedPlan] = useState(GOLD);
  const [planPrices, setPlanPrices] = useState([]);
  const [showSidebarCollapse, setShowSidebarCollapse] = useState(false);

  // added by gk. 28-02-2024
  const useQuery= () => {
    return new URLSearchParams(useLocation().search);
  }
  let queryParams = useQuery();
  // -----------------------

  useEffect(() => {
    const country = getCountryForTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone);
    let countryId = "AU";
    if(country && country.id) {
      countryId = country.id;
    }
    setCountry(countryId);

    if (process.env.REACT_APP_ENV === 'production') {
      request(`https://ipbackend22.languageacademy.com.au/api/v2/getPackages?countryId=${countryId}`);
    } else {
      request(`getPackages?countryId=${countryId}`);
    }
    document.getElementsByTagName("body")[0].classList.add("adminPlansPage");

    if (window.location.pathname === "/admin/plans") setShowSidebarCollapse(true);

    return () => {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("adminPlansPage");
    };
  }, []);

  useEffect(() => {
    if (response) {
      const { data } = response;
      const plans = data.sort((a, b) => a.price - b.price);

      // added by gk. 28-02-2024
      let showPlans = [];
      if (queryParams.get('plan') !== null) {
        plans.map((item) => {
          if (item.is_gold === 1) {
            showPlans.push(item);
          }
        })
      } else {
        showPlans = plans;
      }
      // gk code end.----------

      setPlans(showPlans);
    }
  }, [response]);

  useEffect(() => {
    if (hasError) {
      dispatchAlert(2, "Soemthing went wrong");
      return;
    }
  }, [hasError]);

  const dispatchAlert = (type, message) => {
    dispatch(
      setAlert({
        flag: true,
        type,
        message,
      })
    );
  };

  const durationButtons = () => {
    return [1, 2, 3];
  }

  const planDurationButtons = (duration) => {
    setPlanDuration(duration);
  }

  const selectPlan = (plan) => {
    setSelectedPlan(plan);
  }


  useEffect(() => {
    if (plans.length > 0) {
      const isGold = selectedPlan === GOLD ? 1 : 0;
      const prices = [];
      let one_month_price = 0;
      plans.map((plan, i) => {
        if (plan.duration === 1) one_month_price = plan.price;
        if (plan.is_gold === isGold && plan.price > 0) {
          plan.special_class = plan.duration === 1 ? "skyBluePlan" : (plan.duration === 2 ? "bluePlan" : "");
          plan.base_price = plan.duration > 1 ? (one_month_price * plan.duration) : "&nbsp;";

          const listed_price = (one_month_price * plan.duration);
          plan.discount_inr = (((listed_price - plan.price)/listed_price) * 100).toFixed(0);
          
          prices.push(plan);
        }
      });
      prices.sort((a, b) => (a.duration < b.duration ? -1 : 1));
      setPlanPrices(prices);
    }
  }, [selectedPlan, plans]);


  const priceSpeciality = (duration) => {
    const specialities = ["", "", "Best Seller", "Most Savings"];
    if (duration > 0 && duration < 4) return specialities[duration];
    return "";
  }

  

  const packageTypeHandler = () => {
    if (selectedPlan === GOLD) {
      setSelectedPlan(SILVER);
    } else {
      setSelectedPlan(GOLD);
    }
  }

  return (
    <div className="content p-0">
      <DashboardHeader title="Plans" />

      <div className="transation-page outer-wrapper">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="transation-tab-wrapper">
              
              <div className="dashboard-videos-header">
                <h2 className="title">Choose a package</h2>
                
                <PlanTypes
                  packageTypeHandler={packageTypeHandler}
                />
              </div>

              <PlanFeatures />
            </div>
          </div>

          <PlanPriceCards
            planPrices={planPrices}
            priceSpeciality={priceSpeciality}    
          />
        </div>
      </div>
    </div>
  );
};


const PlanPriceCards = ({
  planPrices, priceSpeciality
}) => {

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '100px',
    responsive: [
      {
        breakpoint: 1024, 
        settings: {
          centerPadding: '50px',
        },
      },
      {
        breakpoint: 768,
        settings: {
          centerPadding: '50px',
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerPadding: '20px',
        },
      },
    ],
  };

  return (
    <div className="col-md-6">
      <Slider {...sliderSettings} className="transation-package-slider">
        {planPrices.map((plan, i) => {
          return (
            <div className="slider-card bestseller" key={i}>
              <PlanDetails
                currency={plan.currency}
                duration={plan.duration}
                priceSpeciality={priceSpeciality}
              />

              <MonthlyPrice
                plan={plan}
              />

              <div className="slider-card-btn">
                <Link to={`/admin/sub/payment/form/${plan.id}`}>
                  Buy Now <img src={ArrowIcon} alt="Arrow icon" />
                </Link>
              </div>
            </div>
          )
        })}
      </Slider>
    </div>
  );
};


const PlanDetails = ({
  currency, duration, priceSpeciality
}) => {

  const planCurrency = () => {
    return currency !== "AUD" ? "INR" : currency;
  }

  const planDurationFormatted = () => {
    return `${duration} Month${duration > 1 ? "s" : ""}`;
  }

  return (
    <div className="slider-card-title">
      <h3>{planCurrency()} - ({planDurationFormatted()})</h3> <span className="slider-card-badge">{priceSpeciality(duration)}</span>
    </div>
  );
};


const PlanTypes = ({ packageTypeHandler }) => {

  const buttonId = (item) => {
    return `${item.toLowerCase()}-tab`;
  }

  const isActive = (item) => {
    return item === GOLD ? "active" : "";
  }

  const loweredCase = (text) => {
    return text.toLowerCase();
  }

  return (
    <ul className="nav nav-tabs" id="myTab" role="tablist">
      {TYPES.map((item, i) => {
        return (
          <li className="nav-item" role="presentation" key={i}>
            <button
              className={`nav-link ${isActive(item)}`}
              id={buttonId(item)}
              data-bs-toggle="tab"
              data-bs-target={`#${loweredCase(item)}-tab-pane`}
              type="button"
              role="tab"
              aria-controls={`${loweredCase(item)}-tab-pane`}
              aria-selected={item === GOLD ? "true" : "false"}
              onClick={packageTypeHandler}
            >{item}</button>
          </li>
        )
      })}
    </ul>
  );
};


const PlanFeatures = () => {

  const loweredCase = (item) => {
    return item.toLowerCase();
  }

  const iconImage = (item, feature) => {
    let icon = checkIcon;
    if (item === SILVER) {
      icon = feature.for_all ? checkIcon : cancelIcon;
    }

    return icon;
  }

  const liClass = (item, feature) => {
    let class_name = "";
    if (item === SILVER) {
      class_name = feature.for_all ? "" : "cencel-list";
    }

    return class_name;
  }

  return (
    <div className="tab-content" id="myTabContent">
      {TYPES.map((item, i) => {
        return (
          <div
            className={`tab-pane fade ${item === GOLD ? "show active" : ""}`}
            id={`${loweredCase(item)}-tab-pane`}
            role="tabpanel"
            aria-labelledby={`${loweredCase(item)}-tab`}
            tabIndex="0"
            key={i}
          >
            <div className="package-content">
              <h3 className="package-title">Available Features</h3>

              <ul className="package-list">
                {Features.map((feature, j) => {
                  return (
                    <li className={liClass(item, feature)} key={j}>
                      <img src={iconImage(item, feature)} alt="Image" /> {feature.text}
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        )
      })}
    </div>
  );
}


const MonthlyPrice = ({ plan }) => {

  // For new design.
  return (
    <div className="slider-card-content">
      <h4><img src={checkIcon} alt="Check icon" /> {ReactHtmlParser(plan.base_price)} <RenderDiscount plan={plan} /></h4>
      <h5>{plan.price}* - {plan.duration} Month{plan.duration > 1 ? "s" : ""}</h5>
      <p>Auto renews every {plan.duration} month{plan.duration > 1 ? "s" : ""}</p>
      {plan.duration > 1 &&
        <p className="message-note">(until cancelled)</p>
      }
    </div>
  );

  // // For old design.
  // return (
  //   <>
  //     <span className="base_price">
  //       <b className="base_price_a">{ReactHtmlParser(plan.base_price)}</b> <RenderDiscount plan={plan} />
  //     </span>
  //     {plan.price}* <i className="plan_duration">({plan.duration} Month{plan.duration > 1 ? "s" : ""})</i>
  //   </>
  // );
};


const RenderDiscount = ({ plan }) => {
  return (
    <>
      {plan.discount_inr > 0 ? (
        `${plan.discount_inr}% OFF`
      ) : ""}
    </>
  );
};

export default Plans;