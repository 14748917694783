import React from 'react';

export default function TypeFilters({
	ALL, MONTHLY_PREDICTIONS, EXAM_QUESTIONS,
	activeTab, showData, onlyMonthlyPrediction
}) {

	return (
		<div className="tabs-content">
			<ul className="nav nav-tabs" id="myTab" role="tablist">
				{onlyMonthlyPrediction === undefined &&
					<li className="nav-item" role="presentation">
						<button
							className={`nav-link ${activeTab === ALL && "active"}`}
							type="button"
							onClick={() => showData(ALL)}
						>All</button>
					</li>
				}
				<li className="nav-item" role="presentation">
					<button
						className={`nav-link ${activeTab === MONTHLY_PREDICTIONS && "active"}`}
						type="button"
						onClick={() => showData(MONTHLY_PREDICTIONS)}
					>Monthly Prediction</button>
				</li>
				{onlyMonthlyPrediction === undefined &&
					<li className="nav-item" role="presentation">
						<button
							className={`nav-link ${activeTab === EXAM_QUESTIONS && "active"}`}
							type="button"
							onClick={() => showData(EXAM_QUESTIONS)}
						>Exam Questions</button>
					</li>
				}
			</ul>
		</div>
	);
}