import React, { useState } from 'react';
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter
} from 'reactstrap';

export default function TemplateDetectionModal({
  isOpen,
  closeModal,
  title,
  data,
  template_type,
  common_sentence,
  children
}) {
	const [modal, setModal] = useState(isOpen);

	const closeModalInternal = () => {
		setModal(false);
		closeModal(); 
	};
  const popupText = () => {
    let message = "";

    if (template_type === "repeat") {
      message = (
        <>
          Our AI system has detected use of template in your response. You have repeated the phrase “<span className="detected-phrase">{title}</span>” more than 3 times which is banned in PTE.
        </>
      );
    } else {
      message = (
        <>
          Our AI system has detected use of template in your response. You have used the phrase “<span className="detected-phrase">{title}</span>” which is banned in PTE.
        </>
      );
    }

    return message;
  }

	return (
    <Modal
      isOpen={modal}
      size="lg"
      backdropClassName="shadow-backdrop"
      className="practice-shadowing-modal text-translate-modal word-definition-modal pre-line template-detector"
    >
      <ModalHeader className="word-definition-heading" tag="div">
        <div className="d-flex align-items-center gap-2 w-100">
          <i className="fa fa-exclamation-triangle warning-icon" aria-hidden="true"></i>
          <h4 className="mb-2 text-light fw-bold">
            TEMPLATE DETECTED
          </h4>
          <Button className="modalCloseBtn ms-auto" onClick={() => closeModalInternal()}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <path
                d="M18 6L6 18M6 6l12 12"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Button>
        </div>
      </ModalHeader>

      <ModalBody>
        <div className="text-translation">
          
          <div className="fw-bold">
            <p className="template-detected-error">
              {popupText()}
            </p>
          </div>

          <hr />

          <p className="text-dark template-constraint">{data.remarks}</p>

          <div className="d-flex justify-content-center align-items-center gap-4 mt-4 text-dark">
            {/* Circle - Overall Score */}
            <div className="score-circle">
              <span className="score-text">
                {data.overallScore.score}/{data.overallScore.out_of}
              </span>
              <span className="score-label">Overall</span>
            </div>

            {/* Score Breakdown */}
            <div className="score-box">
              <p>Content - {data.contentScore.score}/{data.contentScore.out_of}</p>
              <p>Fluency - {data.fluencyScore.score}/{data.fluencyScore.out_of}</p>
              <p>Pronunciation - {data.pronunciationScore.score}/{data.pronunciationScore.out_of}</p>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
