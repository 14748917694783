import React from "react";
import {
	Modal, ModalHeader, ModalBody,
	Button
} from "reactstrap";


export default function Tested({
	isOpen, toggle, testedYes
}) {

	return (
		<Modal isOpen={isOpen} className="text-translate-modal">
			<ModalHeader
				className="text-translation"
				tag="div"
			>
				Do you have seen this question in exam?

				<Button className="modalCloseBtn" onClick={toggle}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						width="24"
						height="24"
					>
						<path
							d="M18 6L6 18M6 6l12 12"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</Button>
			</ModalHeader>
			
			<ModalBody>
				<div className="row text-center">
					<div className="col-6">
						<button
							type="button"
							className="outline-btn"
							onClick={testedYes}
						>
							Yes, I've
						</button>
					</div>
					
					<div className="col-6">
						<button
							type="button"
							className="outline-btn "
							onClick={toggle}
						>
							No, I haven’t
						</button>
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
}