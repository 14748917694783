import React from "react";
import {
	Modal, ModalHeader, ModalBody,
	Button, Input
} from "reactstrap";


export default function Tested({
	isOpen, toggle, submit, changeHandler, reportText
}) {

	return (
		<Modal isOpen={isOpen} className="text-translate-modal">
			<ModalHeader
				className="text-translation"
				tag="div"
			>
				Report the problem in this question.

				<Button className="modalCloseBtn" onClick={toggle}>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						width="24"
						height="24"
					>
						<path
							d="M18 6L6 18M6 6l12 12"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</Button>
			</ModalHeader>
			
			<ModalBody>
				<div className="custom-box">
					<div className="editor-wrapper">
						<div className="editor__input">
							<Input
								type="textarea"
								value={reportText}
								name="reportText"
								onChange={changeHandler}
							/>
						</div>
					</div>
				</div>

				<div className="text-center">
					<button
						type="button"
						className="outline-btn"
						onClick={submit}
					>
						Submit Report
					</button>
				</div>
			</ModalBody>
		</Modal>
	);
}