import React,{Component} from "react";
import {
  Col,
  Progress,
  FormGroup,
  Label,
  Input,
  Button
} from "reactstrap";
import { base, s3base } from "api.js";
import Sound from 'react-sound';
import "components/custom.css";
import PlaybackSpeedBtns from "components/PracticeMaterial/PlaybackSpeedBtns";
import SelectVoiceDropDown from "components/PracticeMaterial/SelectVoiceDropDown.jsx";
import TagIcon1 from "assets/images/images_new/tag1.svg";
import TimeUp from "components/PracticeMaterial/PopUps/TimeUp";


const IDEAL_TIME = 60; // SECONDS.

class Dictation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
      question:"",
      secondCount:10,
      recordCount:0,
      recordId:1,
      intervalId:0,
      questionId:"",
      reset:false,
      soundStatus:true,
      content:'',
      soundPer:0,
      volume:90,
      correctFlag:true,
      resultObj:{},
      playbackSpeed: 1,
      soundKey: 0,
      question_audio: null,
      defaultSelectedVoice: null,
      playStatus: Sound.status.STOPPED,
      inputRows: 1,
      scrollHeight: 0,
      idealTime: IDEAL_TIME,
      idealTimeInterval: null,
      idealTimeExceed: false
    };
  }

  componentDidMount() {
    if (!this.props.answer) {
      
      // select audio.
      let question_audio = null;
      let defaultSelectedVoice = null;
      if ('question_audios' in this.props.data && this.props.data.question_audios.length > 0) {
        let x = parseInt(Math.random() * 6);

        const { question_audios } = this.props.data;
        question_audio = `${s3base}/${question_audios[x].value}`;
        defaultSelectedVoice = question_audios[x].label;
      } else {
        const { media_link } = this.props.data;
        question_audio = `${s3base}${media_link}`;
      }

      // start countdown.
      const idealTimeInterval = setInterval(this.countDownToIdealTime, 1000);

      var intervalId = setInterval(this.timer, 1000);
      this.setState({
        intervalId,
        question_audio,
        defaultSelectedVoice,
        idealTimeInterval
      }, () => {
        this.props.updateQuestionAudioUrlInPractice(this.state.question_audio);
      });
    }

  	// var intervalId = setInterval(this.timer, 1000);
    // this.setState({intervalId: intervalId});
  }

  stopTest = () =>{
    this.props.nextQuestion(this.state.resultObj);
  }

  static getDerivedStateFromProps(props, state) {
  	if (props.data) {
  		if ((state.questionId && state.questionId !== props.data.id) || props.restart) {
  			clearInterval(state.intervalId);
  			clearInterval(state.recordId);

        let question_audio = null;
        let defaultSelectedVoice = null;
        if ('question_audios' in props.data && props.data.question_audios.length > 0) {
          let x = parseInt(Math.random() * 6);

          const { question_audios } = props.data;
          question_audio = `${s3base}/${question_audios[x].value}`;
          defaultSelectedVoice = question_audios[x].label;
        } else {
          const { media_link } = props.data;
          question_audio = `${s3base}${media_link}`;
        }

  			return {
          correctFlag: true,
          recordCount: 0,
          soundPer: 0,
          isRecording: false,
          secondCount: 10,
          questionId: props.data.id,
          blobURL: '',
          reset: true,
          soundStatus: true,
          content: '',
          question_audio,
          defaultSelectedVoice
        };
  		} else {
  			if (!state.questionId) {
  				return {
            correctFlag: true,
            questionId: props.data.id
          };
  			}
  		}
  	}
  }


	timer = () => {
		if (this.state.secondCount === 1) {
			clearInterval(this.state.intervalId);

      this.setState({
        playStatus: Sound.status.PLAYING,
        secondCount: 0
      });
		} else {
  		this.setState({
        secondCount: (this.state.secondCount - 1)
      });
    }
	}

  handleSongPlaying = (obj) => {
    this.setState({soundPer:obj.position*100/obj.duration});
  }

  changeRange = (e) => {
    this.setState({
      volume: parseInt(e.target.value)
    });
  }

  handleSongFinishedPlaying = () => {
    console.log("stopped.");
    this.setState({
      soundStatus: false,
      playStatus: Sound.status.STOPPED
    });
    this.props.soundChecked();
    this.props.showResult();
  }

  copyToClipboard = () => {
    document.execCommand("copy");
  };

  cutToClipboard = () => {
    document.execCommand("cut");
  };

  pasteToClipboard = async () => {
    alert("Press Ctrl+v for paste text");

  };

  changePlaybackSpeed = (speed) => {
    // this.setState((prevState) => ({
    //   soundKey: prevState.soundKey + 1
    // }));
    // this.setState({playbackSpeed: speed});

    this.setState((prevState) => ({
      soundKey: prevState.soundKey + 1,
      playbackSpeed: speed
    }));
  }

  handleTextareaSize = (e) => {

    const { inputRows, scrollHeight } = this.state;

    if ((scrollHeight < e.target.scrollHeight)) {
      if (inputRows < 7) {
        e.target.style.height = "0px";
        e.target.style.height = (25 + e.target.scrollHeight)+"px";

        this.setState({
          scrollHeight: e.target.scrollHeight,
          inputRows: inputRows + 1
        });
      }
    }
    else if (scrollHeight === e.target.scrollHeight) {
      e.target.style.height = scrollHeight+"px";
      e.target.style.height = (25 - e.target.scrollHeight)+"px";
      // this.setState({
      //   scrollHeight: e.target.scrollHeight
      // });
    }
  }

  chaneText = (e) =>{
    let { name, value } = e.target;
    this.props.submit({'answer':value,'correct':''},true);
    this.setState({ [name] : value, resultObj: {'answer':value,'correct':''} });
  };

  updateCorrect = () =>{
    this.props.submit({'answer':'','correct':''});
    this.setState({correctFlag:false,resultObj: {'answer':'','correct':''}});
  }

	timerRecord = () => {
		if (this.state.recordCount === 40) {
			clearInterval(this.state.recordId);
			this.stop();
		}
		this.setState({ recordCount: this.state.recordCount + 1 });
	}

  skip = () => {
    clearInterval(this.state.intervalId);
    this.setState({
      secondCount: 0,
      playStatus: Sound.status.PLAYING
    });
  };

	componentWillUnmount = () => {
		clearInterval(this.state.intervalId);
	}

  changeAudio = (selected) => {

    const { question_audios } = this.props.data;
    const selected_audio = question_audios.filter((audio, i) => {
      return audio.label === selected.label;
    });

    this.setState({
      question_audio: `${s3base}/${selected_audio[0].value}`,
      playStatus: Sound.status.PLAYING
    });
  }

  onLoad = (obj) => {
    // console.log(obj)
  }

  playingQuestionSectionLabel = () => {
    const { isRecording, secondCount, soundStatus, blobURL } = this.state;
    let status = '';

    if (!isRecording) {
      if (secondCount !== 0 && soundStatus === true) {
        status = `Beginning in ${this.state.secondCount} seconds`;
      } else if (secondCount === 0 && soundStatus) {
        status = 'Playing question audio...';
      } else {
        status = 'Question audio finished.'; // just any case.
      }
    } else {
      status = 'Question audio finished.';
    }
    return status;
  }

  showSkipAudioWaitButton = () => {
    const { secondCount, blobURL, isRecording } = this.state;
    const { testMode } = this.props;

    let button = false;
    if (!testMode && secondCount !== 0 && blobURL === '' && !isRecording) {
      button = true;
    }

    return button;
  }

  playAudio = () => {
    const { data, resultMode } = this.props;
    const { soundStatus, secondCount } = this.state;

    let audio = false;
    if (data && !resultMode && soundStatus && secondCount === 0) {
      audio = true;
    }

    return audio;
  }

  wordCount = () => {
    const { content } = this.state;

    return content === "" ? 0 : content.trim().split(/\s+/).length;
  }

  countDownToIdealTime = () => {
    const { idealTime, idealTimeInterval } = this.state;
    const { answer, showResponse } = this.props;

    if(answer) {
      clearInterval(idealTimeInterval); // stop timer when submitted.
    } else {
      let state_obj = {};

      if (idealTime > 0) {
        state_obj.idealTime = (idealTime - 1);
      } else {
        clearInterval(idealTimeInterval);
        state_obj.idealTimeExceed = true;
      }
      this.setState(state_obj);
    }
  }

  idealTimeExceedToggle = () => {
    const { idealTimeExceed } = this.state;

    this.setState({
      idealTimeExceed: !idealTimeExceed
    });
  }

  render() {
  	let question = "";
    if (this.props.restart) {
      this.props.restartQue();
    }
    if (this.props.stopRecording) {
      this.stopTest();
    }
  	if (this.props.data) {
  		question = this.props.data.question;
      if (this.state.correctFlag) {
        this.updateCorrect();
      }
  	}
  	if (this.state.reset) {
  		var intervalId = setInterval(this.timer, 1000);
		  this.setState({intervalId: intervalId,reset:false});
  	}

    const { data, resultMode, answer, showResponse } = this.props;
    const { volume, playbackSpeed, soundKey, question_audio, playStatus, idealTimeExceed } = this.state;

    return (
      <>
        {this.playAudio() &&
          <Sound
            autoLoad={true}
            url={question_audio}
            playStatus={playStatus}
            onPlaying={this.handleSongPlaying}
            volume={volume}
            onFinishedPlaying={this.handleSongFinishedPlaying}
            playbackRate={playbackSpeed}
            key={soundKey}
            onLoading={this.onLoad}
          />
        }

        <div className="pmw-tabs-section d-flex align-items-start pmw-outer-wrapper recorded-wrapper challenge-content-wrapper visibility-visible">
          <div className="recorded-section h-100 width-25"></div>
          <div className="recorded-section h-100 width-50">
            <div className="recorded-content">
              <div className="row">
                <div className="col-8">
                  <ul className="recorded-list">
                    <li>Status:</li>
                    <li>{this.playingQuestionSectionLabel()}</li>
                  </ul>
                </div>

                <div className="col-4">
                  {/*<span className="volume-label">Volume</span>*/}
                </div>
              </div>
              <div className="recorded-audio-wrapper">
                <div className="mt-3 clearfix">

                  <div className="row">
                    <div className="col-8">
                      <Progress value={(this.state.soundPer)}striped />
                    </div>
                    <div className="col-4">
                      <div className="volume-control-wrapper">
                        <span className="volume-icon">
                          <i class="fa fa-volume-up" aria-hidden="true"></i>
                        </span>
                        <div className="re-volume-wrapper">
                          <input
                            type="range"
                            placeholder="Volume"
                            className="volume-control"
                            min="0"
                            max="100"
                            step="1"
                            defaultValue={this.state.volume}
                            onInput={this.changeRange}
                            style={{
                              "--progress": `${(this.state.volume / 100) * 100}%`,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-8">
                      {this.state.defaultSelectedVoice !== null &&
                        <div className="mt-2 mr-2 pull-left visibility-visible">
                          <SelectVoiceDropDown
                            voices={this.props.voices}
                            selected={this.state.defaultSelectedVoice}
                            changeAudio={this.changeAudio}
                          />
                        </div>
                      }

                      <div className="mt-2 pull-left">
                        <PlaybackSpeedBtns
                          changePlaybackSpeed={this.changePlaybackSpeed}
                          playbackSpeed={this.state.playbackSpeed}
                        />
                      </div>

                      {this.showSkipAudioWaitButton() &&
                        <div className="btn-outer pull-right visibility-visible">
                          <a
                            href="javascript:void(0)"
                            className="outline-btn skip-btn-new text-center"
                            onClick={this.skip}
                          >Start Audio</a>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="editor-wrapper-section custom-box">
          <div className="editor-wrapper">
            <div className="editor__toolbar">
              <button className="toolbar__button"></button>
            </div>
            <div className="editor__input">
              {!answer ? (
                <Input
                  type="textarea"
                  className="writing-textarea"
                  onCopy={this.copyFunc}
                  autoCorrect="false"
                  spellCheck="false"
                  name="content"
                  onChange={(e) => { this.chaneText(e)}}
                  onKeyUp={this.handleTextareaSize}
                  value={this.state.content}
                  placeholder="Write your response here"
                />
              ) : (
                <div style={{
                  whiteSpace: 'pre-line'
                }}>{this.state.content}</div>
              )}
            </div>
          </div>

          <div className="mt-3 clearfix">
            <div className="word-count pull-right">
              <span>Total word count:</span> {this.wordCount()}
            </div>
          </div>
        </div>


        <TimeUp
          isOpen={idealTimeExceed}
          toggle={this.idealTimeExceedToggle}
          title="Important !!!"
          message="You spent 1 minute. This is the ideal time to attempt these questions. Click Submit Now to submit response or Close to ignore."
          buttonLabel="Submit Now"
          showResponse={showResponse}
        />
      </>
    );

    // return (
    //   <>
    //     <Col xs={12}>
    //     	<h4 className="infoText"></h4>
    //       {this.props.data && !this.props.resultMode && this.state.soundStatus && this.state.secondCount === 0 &&
    //         <Sound
    //           autoLoad={true}
    //           url={this.state.question_audio}
    //           // url={s3base + this.props.data.media_link}
    //           playStatus={this.state.playStatus}
    //           onPlaying={this.handleSongPlaying}
    //           volume={this.state.volume}
    //           onFinishedPlaying={this.handleSongFinishedPlaying}
    //           playbackRate={this.state.playbackSpeed}
    //           key={this.state.soundKey}
    //           onLoading={this.onLoad}
    //         />
    //       }
    //     </Col>

    //     {!this.props.resultMode && 
    //       <Col xs={12}>
    //       	<div className="col-lg-12 text-center">
    //     		  <div className="currentStatus">
    //             <div>Current Status:{this.state.soundStatus == true && this.state.secondCount !== 0 && !this.state.isRecording && ' Begining in '+this.state.secondCount+ ' seconds' }
    //               {this.state.secondCount == 0 && this.state.soundStatus && ' Playing' }
    //               <br></br>
                  
    //               Volume
    //               <Input
    //                 placeholder="Volume"
    //                 type="range"
    //                 defaultValue={this.state.volume}
    //                 step={1}
    //                 onInput={(e) => this.changeRange(e)}
    //                 min={0}
    //                 max={100}
    //               />
    //               <br></br>
                
    //               <Progress color="info" value={(this.state.soundPer)} />
    //               {!this.props.testMode && (
    //                 <div className="d-flex justify-content-between">
    //                   <div className='stop-btn'>
    //                     {
    //                       this.state.secondCount !== 0 &&
    //                       this.state.blobURL === '' &&
    //                       !this.state.isRecording &&
    //                         <Button color="success" onClick={this.skip} size="sm">Skip</Button>
    //                     }
    //                   </div>

    //                   <div className="stop-btn clearfix">
    //                     <div className="select-voice pull-left">
    //                       {this.state.defaultSelectedVoice !== null &&
    //                         <SelectVoiceDropDown
    //                           voices={this.props.voices}
    //                           selected={this.state.defaultSelectedVoice}
    //                           changeAudio={this.changeAudio}
    //                         />
    //                       }
    //                     </div>
    //                     <div className="select-speed pull-right">
    //                       <PlaybackSpeedBtns
    //                         changePlaybackSpeed={this.changePlaybackSpeed}
    //                         playbackSpeed={this.state.playbackSpeed}
    //                       />
    //                     </div>
    //                   </div>

    //                 </div>
    //               )}
    //             </div>
    //           </div>

    //           <Col xs={12}></Col>
        		  
    //           <Col xs={12}>
    //     		  	{this.state.blobURL &&
    //               <audio
    //                 src={this.state.blobURL}
    //                 controls="controls"
    //               />
    //             }
    //     		  </Col>
    //     		</div>
    //       </Col>
    //     }

    //     <Col xs={12}><h5>{question}</h5></Col>
        
    //     {!this.props.resultMode &&
    //       <Col xs={12}>
    //         <FormGroup>
    //           <Input
    //             type="textarea"
    //             name="content"
    //             autoCorrect="false"
    //             spellCheck="false"
    //             id="textBox"
    //             onChange={(e) => { this.chaneText(e)}}
    //             value={this.state.content}
    //           />
    //         </FormGroup>
    //         <div className="bottomContent">
    //           <p className="wordCount">Total word count: {this.state.content.split(' ').filter(function(n) { return n != '' }).length}</p>
    //           <div className="copyPaste">
    //             <a href="javascript:void(0)" onClick={this.cutToClipboard} className="btn">Cut</a>
    //             <a href="javascript:void(0)" onClick={this.copyToClipboard} className="btn">Copy</a>
    //             <a href="javascript:void(0)" onClick={this.pasteToClipboard} className="btn">Paste</a>
    //           </div>
    //         </div>
    //       </Col>
    //     }
        
    //     <Col xs={12}>
    //       <h5>{this.props.data && this.props.answer &&  this.props.data.audio_script }</h5>
    //     </Col>
    //   </>
    // );
  }
}
export default Dictation;
